import { Box, Button, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { IoCloseCircleOutline } from 'react-icons/io5'
import '../../../Assets/style/style.scss'
import { useDispatch, useSelector } from "react-redux";
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import Astrisks from "../../../Components/Common/astrisk";
import { useTranslation } from "react-i18next";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";
import { editBank, getBanks } from "../../../Components/Redux/Slice/Banks/banks";
import Overlay from "../../../Components/Common/overlay";
import { useEffect } from "react";

const EditBanks = ({ open, close, data }) => {
    const { t, i18n } = useTranslation()
    const form = useForm()
    const { register, handleSubmit, formState, reset } = form
    const { errors } = formState
    const dispatch = useDispatch()
    const banks = useSelector(state => state.banks)
    const id = data?.id

    useEffect(() => {
        if (open && id) {
            reset({
                name: data?.name,
                nameAr: data?.nameAr,
            })
        }
    }, [data, open, reset, id])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 850,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 2,
        borderRadius: 5,
    };

    const handleCloseModal = () => {
        close()
        reset()
    }

    let page = 1,
        size = 10;

    const submitHandler = async (data) => {

        try {

            await dispatch(editBank({ data, id })).then(() => {
                reset()
                dispatch(getBanks({ page, size }))
                dispatch(resetSearch());
                dispatch(resetSort({ sort: true }))
                dispatch(resetPage())
                handleCloseModal()
            })
            close();
        } catch (error) {
            // console.log(error);
        }

    }



    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="add-offer-modal-title"
                aria-describedby="add-offer-modal-description"
            >
                <Box
                    sx={style}
                    className='w-[95%] sm:w-[80%] md:w-[70%] lg:w-[60%] max-w-[990px]'
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                >

                    <div className="flex justify-between items-center">
                        <h2 className="text-[#333333] text-lg font-bold mb-3 capitalize">{t('banks.editBank')}</h2>

                        <div>
                            <Button color="error" className="text-2xl -mt-2" onClick={handleCloseModal}><IoCloseCircleOutline /></Button>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(submitHandler)} noValidate className="menu-modal-form-wrapper mt-5 ">
                        <div className="input-wrapper max-h-[600px] overflow-y-auto basis-full flex flex-wrap justify-between items-start  [&>div]:w-full [&>div]:basis-full [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[#333333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:py-4 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            <div className="">
                                <label>{t('banks.bankName')} <Astrisks /></label>
                                <input type="text" id="name"
                                    {...register('name', {
                                        required: {
                                            value: true,
                                            message: t('common.required')
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9&\s]+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                        },
                                        minLength: {
                                            value: 3,
                                            message: t('common.min_length', { length: '3' })
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: t('common.max_length', { length: '50' })
                                        }
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.name?.message && <p className="capitalize text-[13px] text-danger">{errors?.name?.message}</p>}
                            </div>

                            <div>
                                <label>{t('banks.bankNameAr')} <Astrisks /></label>
                                <input type="text" id="nameAr"
                                    {...register('nameAr', {
                                        required: {
                                            value: true,
                                            message: t('common.required')
                                        },
                                        pattern: {
                                            value: /^[\u0621-\u064A\u0660-\u0669\u0030-\u0039\s&0-9]+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & والارقام فقط' : 'Only Arabic letters, spaces, & sign and numbers are allowed'
                                        },
                                        minLength: {
                                            value: 3,
                                            message: t('common.min_length', { length: '3' })
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: t('common.max_length', { length: '50' })
                                        }
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.nameAr?.message && <p className="capitalize text-[13px] text-danger">{errors?.nameAr?.message}</p>}
                            </div>

                        </div>

                        <div className="add-new-category-btn mt-3 text-right">
                            <Button type="submit" disabled={banks?.loading} className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainColor rounded-md capitalize text-white text-[12px] px-10 py-2 min-w-[200px]">{t('common.edit')}</Button>
                        </div>
                    </form>
                </Box>
            </Modal>

            {/* {banks?.loading ? <Overlay /> : null} */}
        </>
    );
}
export default EditBanks;