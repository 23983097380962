import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer'
import logo from '../../Assets/imgs/minilogo.png'
import { formatDateToUserTimezone } from './dateFormate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { formatDateToUserTimezone } from '../../Assets/fonts/static/Cairo-Bold.ttf';

Font.register({
    family: 'ScheherazadeNew-SemiBold',
    src: '/ScheherazadeNew-SemiBold.ttf', // Replace with the correct path
});
// Font.register({
//     family: 'Cairo',
//     src: '/Cairo-Regular.ttf', // Replace with the correct path
// });

export const styles = StyleSheet.create({
    page: {
        backgroundColor: '#E4E4E4',
        fontFamily: 'ScheherazadeNew-SemiBold',
        padding: 10,
        // transform: 'rotate(90deg)'
        // width: "100%"
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        title: {
            fontSize: '23px',
            marginBottom: '0px',
            fontWeight: '900',
            color: "#442B7E",
            fontFamily: 'ScheherazadeNew-SemiBold'
        },

        subText: {
            fontSize: '16px',
            color: '#333',
            flexDirection: 'row',
            alignItems: 'center',
            gap: "5px",
            fontFamily: 'ScheherazadeNew-SemiBold'
        },

        dateText: {
            fontSize: "13px",
            color: "#555",
        },
        owedText: {
            fontSize: '14px',
            color: '#36b07b',
            fontWeight: '600',
            margin: '0px',
            padding: '0px',
        },
        owesText: {
            fontSize: '14px',
            color: '#d91d5a',
            fontWeight: '600',
            margin: '0px',
            padding: '0px',
        },

        img: {
            width: '75px',
            height: '75px',
            objectFit: 'contain'
        }
    },

    breaker: {
        height: '2px',
        backgroundColor: '#ccc',
        margin: '8px 0px'
    },

    body: {
        marginTop: "10px",
        title: {
            fontSize: '18px',
            margin: '12px 0',
            fontWeight: '900',
            color: "#442B7E",
        },
        table: {
            marginTop: '10px',
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#442B7E",
            // borderRightWidth: 0,
            // borderBottomWidth: 0,

            tableRow: {
                flexDirection: "row",
                // wrap: false
            },
            tableCol: {
                width: "25%",
                // width: 'auto',
                borderStyle: "solid",
                borderWidth: 1,
                fontSize: 12,
                flexGrow: 1
            },
            tableHeaderCol: {
                backgroundColor: "#442B7E",
                color: "#fff",
            },

            tableCell: {
                margin: "auto",
                marginTop: 5,
                borderTopWidth: 0,
                padding: '5px',
                textAlight: "center"
            },
            tableHeaderCell: {
                fontSize: '12px',
                fontFamily: 'ScheherazadeNew-SemiBold'

            },
            tableBodyCell: {
                fontSize: "9px"
            }
        },
    }

});

export const usePDFGenerator = ({ columns, data, name = "Report", date, owed, owes }) => {

    const [limit, setlimit] = useState(15)
    const [isLandscape, setisLandscape] = useState(columns?.length > 6 ? true : false)
    const { t } = useTranslation()
    // console.log('columns', columns);
    // console.log('data', data);

    useEffect(() => {
        setisLandscape(columns?.length > 6 ? true : false)
        if (isLandscape) {
            setlimit(columns?.length > 10 ? 5 : 6)
        } else {
            setlimit(15)
        }
    }, [columns, isLandscape])


    let parentArrays = [];
    // console.log(data);

    function splitArrays(arr, chunkSize = 15) {

        if (!Array.isArray(arr)) {
            return parentArrays = [];
        }
        if (chunkSize <= 0 || arr?.length <= chunkSize) {
            return parentArrays = [arr];
        }


        for (let i = 0; i <= arr.length; i += chunkSize) {
            if (arr.length !== 0) {
                parentArrays.push(arr.slice(i, i + chunkSize));
            }
        }

        return parentArrays;
    }

    // const chunkSize = columns?.length > 5 ? 17 : 22
    splitArrays(data, limit);



    // console.log(parentArrays);

    const NewText = ({ text }) => text;

    // console.log(columns);
    // console.log(date);

    // const processedData = data?.map((item) => {
    //     const processedItem = {};
    //     columns?.forEach((column) => {
    //         processedItem[column.id] =
    //             item[column.id] &&
    //             (column.id === 'orderTime' ||
    //                 column.id === 'transactionTime' ||
    //                 column.id === 'createdAt' ||
    //                 column.id === 'deliveredAt'
    //                 ? formatDateToUserTimezone(item[column.id])
    //                 : item[column.id]);
    //     });
    //     return processedItem;
    // });

    const [fontsLoaded, setFontsLoaded] = useState(false);

    useEffect(() => {
        const loadFonts = async () => {
            // Simulate font loading delay (remove in production)
            await new Promise(resolve => setTimeout(resolve, 500));
            setFontsLoaded(true);
        };
        loadFonts();
    }, []);

    if (!fontsLoaded) {
        return { PDFDocument: () => <Document><Page><Text>Loading Fonts...</Text></Page></Document> };
    }

    const PDFDocument = () => {
        return (
            <Document title='Report'>
                {parentArrays ? parentArrays?.map((splitData, index) => (
                    splitData?.length !== 0 ? <Page key={index} size="A4" style={styles.page} orientation={isLandscape ? 'landscape' : 'portrait'} break wrap={false}>
                        <View style={styles.header} data-title='report-header'>
                            <View>
                                <Text style={styles.header.title}>{name}</Text>
                                <View style={styles.header.subText}>
                                    <Text>Date Covered:</Text>

                                    <Text style={styles.header.dateText}>{(date[0]?.startDate && date[0]?.endDate) ?
                                        <>{formatDateToUserTimezone(date[0]?.startDate)} - {formatDateToUserTimezone(date[0]?.endDate)}</>
                                        :
                                        "All Time Coverage"
                                    }</Text>
                                </View>
                                {owed ? <Text style={styles.header.owedText}>{t('deliveryContractors.transactions.contractorOwed')}: {owed} {t('common.sar')}</Text> : null}
                                {owes ? <Text style={styles.header.owesText}>{t('deliveryContractors.transactions.contractorOwes')}: {owes} {t('common.sar')}</Text> : null}

                            </View>
                            <Image src={logo} style={styles.header.img} />
                        </View>

                        <View style={styles.breaker}></View>

                        {/* <Text style={styles.body.title}>{name}</Text> */}

                        <View style={styles.body} data-title='report-body'>

                            <View style={styles.body.table.table}>
                                <View style={styles.body.table.tableRow}>

                                    {columns && columns?.map(column => (
                                        <View style={[styles.body.table.tableHeaderCol, styles.body.table.tableCol]} key={column?.id}>
                                            <Text style={[styles.body.table.tableHeaderCell, styles.body.table.tableCell]} children={<NewText text={column.name} />} />
                                        </View>
                                    ))}

                                </View>

                                {
                                    splitData && splitData?.map((item, index) => (
                                        <View style={styles.body.table.tableRow} key={index}>
                                            {
                                                columns && columns?.map((column, index2) => (
                                                    // console.log(column)
                                                    <View style={styles.body.table.tableCol} key={column?.id}>
                                                        <Text style={[styles.body.table.tableCell, styles.body.table.tableBodyCell]}>{Object.entries(item)?.map((obj) => column && obj[0] === column?.id ? (obj[0] === 'orderTime' || obj[0] === 'transactionTime' || obj[0] === 'createdAt' || obj[0] === 'deliveredAt') ? formatDateToUserTimezone(obj[1]) : obj[1] : '')}</Text>

                                                    </View>

                                                ))
                                            }
                                        </View>
                                    ))
                                }

                            </View>
                        </View>

                        <Text
                            wrap={false}
                            style={{ fontSize: "10px", textAlign: "center", color: "#777", position: "absolute", bottom: "8px", left: "50%", transform: "translateX(-50%)" }}
                            render={({ pageNumber, totalPages }) =>
                                `Page ${pageNumber} of ${totalPages}`
                            }
                            fixed
                        />
                    </Page>
                        : null
                )) : ""}

            </Document>
        )
    }


    return { PDFDocument }
}

// export const PdfDocument = () => {
//     const columns = JSON.parse(sessionStorage.getItem('columns'))
//     const data = JSON.parse(sessionStorage.getItem('data'));
//     const date = JSON.parse(sessionStorage.getItem('resDR'));

//     // console.log(date);

//     let parentArrays = [];


//     function splitArrays(arr, chunkSize = 15) {

//         if (!Array.isArray(arr)) {
//             return parentArrays = [];
//         }
//         if (chunkSize <= 0 || arr?.length <= chunkSize) {
//             return parentArrays = [arr];
//         }


//         for (let i = 0; i <= arr.length; i += chunkSize) {
//             parentArrays.push(arr.slice(i, i + chunkSize));
//         }

//         return parentArrays;
//     }

//     const chunkSize = columns?.length > 4 ? 15 : 22
//     splitArrays(data, chunkSize);

//     // console.log(parentArrays);

//     const NewText = ({ text }) => text;


//     // console.log('data', data);
//     // console.log('columns', columns);
//     // console.log(Object.entries(data));

//     return (
//         <Document title='Report' >
//             {parentArrays?.map((arr, index) => (
//                 <Page key={index} size="A4" style={styles.page} >

//                     <View style={styles.header} data-title='report-header'>
//                         <View>
//                             <Text style={styles.header.title}>Transaction Report</Text>
//                             <View style={styles.header.subText}>
//                                 <Text>Date Covered:</Text>

//                                 <Text style={styles.header.dateText}>{date ?
//                                     <>{formatDateToUserTimezone(date?.fromDate)} - {formatDateToUserTimezone(date?.toDate)}</>
//                                     :
//                                     "All Time Coverage"
//                                 }</Text>
//                             </View>
//                         </View>
//                         <Image src={logo} style={styles.header.img} />
//                     </View>

//                     <View style={styles.breaker}></View>

//                     {/* <Text style={styles.body.title}>Restaurant Name #55</Text> */}

//                     <View style={styles.body} data-title='report-body'>

//                         <View style={styles.body.table.table}>
//                             <View style={styles.body.table.tableRow}>

//                                 {columns && columns?.map(column => (
//                                     <View style={[styles.body.table.tableHeaderCol, styles.body.table.tableCol]} key={column?.id}>
//                                         <Text style={[styles.body.table.tableHeaderCell, styles.body.table.tableCell]} children={<NewText text={column.name} />} />
//                                     </View>
//                                 ))}

//                             </View>

//                             {
//                                 arr && arr?.map((item, index) => (
//                                     <View style={styles.body.table.tableRow} key={index}>
//                                         {
//                                             columns && columns?.map((column, index) => (
//                                                 // console.log(item)
//                                                 <View style={styles.body.table.tableCol} key={index}>
//                                                     <Text style={[styles.body.table.tableCell, styles.body.table.tableBodyCell]}>{Object.entries(item)?.map((obj) => obj[0] === column?.id ? (obj[0] === 'orderTime' || obj[0] === 'transactionTime') ? formatDateToUserTimezone(obj[1]) : obj[1] : '')}</Text>
//                                                 </View>

//                                             ))
//                                         }
//                                     </View>
//                                 ))
//                             }

//                         </View>
//                     </View>
//                     <Text
//                         wrap={false}
//                         style={{ fontSize: "10px", textAlign: "center", color: "#777", position: "absolute", bottom: "8px", left: "50%", transform: "translateX(-50%)" }}
//                         render={({ pageNumber, totalPages }) =>
//                             `Page ${pageNumber} of ${totalPages}`
//                         }
//                         fixed
//                     />
//                 </Page>
//             ))}

//         </Document>
//     )
// }



// this function to display the pdf into the web

// const RestaurantReportPdf = ({ cols, data }) => {

//     return (
//         <div className='w-full h-[750px]'>
//             <PdfDocument />
//         </div>
//     );
// }

// export default RestaurantReportPdf;