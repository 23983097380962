import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";
// import { toast } from "react-toastify";
import i18next from "i18next"; // Import i18next

export const restaurantTransactions = createAsyncThunk(
  "restaurantTransactionsFunc",
  async (
    { page, size, col, dir, resId, orderType, searchValue, selectedDate },
    { rejectWithValue, dispatch }
  ) => {
    let colCheck;
    // console.log(page);

    if (col) {
      // colCheck = col === 'Created at' ? 'created_at' : col === 'Delieverd at' ? 'delivered_at' : col === 'Updated at' ? 'updated_at' : col === 'Restaurant Name' ? 'restaurant_name' : col === 'Courier Name' ? 'courier_name' : col === 'Courier Rating' ? 'courier_rating' : col === 'Courier Number' ? 'courier_mobile_number' : col === 'Customer Name' ? 'customer_name' : col === 'Customer Number' ? 'customer_mobile_number' : col === 'Customer Address' ? 'customer_address' : col === 'Order Rating' ? 'order_rating' : col === 'Branch Name' ? 'branch_name' : col === 'Branch Number' ? 'branch_number' : col === 'Branch Address' ? 'branch_address' : col === 'Min Charge' ? 'minimum_charge' : col === 'Avg Delivery Time' ? 'average_delivery_time' : col === 'Avg Rating' ? 'average_rating' : col === 'Total Orders' ? 'total_orders' : col === 'Delivery Charges' ? 'delivery_charges' : col === 'Pickup Schedule' ? 'pickup_schedule' : col === 'Vehicle Plate' ? 'vehicle_plate' : col === 'Original Price' ? 'original_price' : col === 'Final Price' ? 'final_price' : col.replace(/\s+/g, '');
      colCheck =
        col === i18next.t("restaurants.transactions.orderId")
          ? "id"
          : col === i18next.t("restaurants.transactions.amount")
          ? "transaction_amount"
          : col === i18next.t("restaurants.transactions.status")
          ? "transaction_status"
          : col === i18next.t("restaurants.transactions.wallet")
          ? "wallet_balance_used"
          : col === "Order Status" || col === "حالة الطلب"
          ? "status"
          : col.replace(/\s+/g, "").toLowerCase();
    }

    const url = !col
      ? `/restaurants/restaurant-transactions-report/${resId}?page=${
          page ? page : 1
        }&size=${size ? size : 10}${
          !orderType ||
          orderType === "" ||
          orderType === "undefined" ||
          orderType === undefined
            ? ""
            : `&orderType=${orderType}`
        }${searchValue ? `&search=${searchValue}` : ""}${
          selectedDate
            ? `&fromDate=${selectedDate?.fromDate}&toDate=${selectedDate?.toDate}`
            : ""
        }`
      : `/restaurants/restaurant-transactions-report/${resId}?page=${
          page ? page : 1
        }&size=${size ? size : 10}${
          !orderType ||
          orderType === "" ||
          orderType === "undefined" ||
          orderType === undefined
            ? ""
            : `&orderType=${orderType}`
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${
          searchValue ? `&search=${searchValue}` : ""
        }${
          selectedDate
            ? `&fromDate=${selectedDate?.fromDate}&toDate=${selectedDate?.toDate}`
            : ""
        }`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const contractorTransactions = createAsyncThunk(
  "contractor_Transactions_Func",
  async (
    { page, size, col, dir, resId, orderType, searchValue, selectedDate },
    { rejectWithValue, dispatch }
  ) => {
    let colCheck;

    if (col) {
      // colCheck = col === 'Created at' ? 'created_at' : col === 'Delieverd at' ? 'delivered_at' : col === 'Updated at' ? 'updated_at' : col === 'Restaurant Name' ? 'restaurant_name' : col === 'Courier Name' ? 'courier_name' : col === 'Courier Rating' ? 'courier_rating' : col === 'Courier Number' ? 'courier_mobile_number' : col === 'Customer Name' ? 'customer_name' : col === 'Customer Number' ? 'customer_mobile_number' : col === 'Customer Address' ? 'customer_address' : col === 'Order Rating' ? 'order_rating' : col === 'Branch Name' ? 'branch_name' : col === 'Branch Number' ? 'branch_number' : col === 'Branch Address' ? 'branch_address' : col === 'Min Charge' ? 'minimum_charge' : col === 'Avg Delivery Time' ? 'average_delivery_time' : col === 'Avg Rating' ? 'average_rating' : col === 'Total Orders' ? 'total_orders' : col === 'Delivery Charges' ? 'delivery_charges' : col === 'Pickup Schedule' ? 'pickup_schedule' : col === 'Vehicle Plate' ? 'vehicle_plate' : col === 'Original Price' ? 'original_price' : col === 'Final Price' ? 'final_price' : col.replace(/\s+/g, '');
      colCheck =
        col === i18next.t("common.id")
          ? "id"
          : col === i18next.t("deliveryContractors.transactions.branchId")
          ? "branch_id"
          : col === i18next.t("deliveryContractors.transactions.createdAt")
          ? "created_at"
          : col === i18next.t("deliveryContractors.transactions.deliveredAt")
          ? "delivered_at"
          : col === i18next.t("deliveryContractors.transactions.finalPrice")
          ? "final_price"
          : col === i18next.t("deliveryContractors.transactions.status")
          ? "status"
          : col.replace(/\s+/g, "").toLowerCase();
    }

    const url = !col
      ? `/delivery-contractors/contractor-report/${resId}?page=${
          page ? page : 1
        }&size=${size ? size : 10}&lang=${
          localStorage.getItem("lang") === "ar" ? "ar" : "en"
        }${
          !orderType ||
          orderType === "" ||
          orderType === "undefined" ||
          orderType === undefined
            ? ""
            : `&orderType=${orderType}`
        }${searchValue ? `&search=${searchValue}` : ""}${
          selectedDate
            ? `&fromDate=${selectedDate?.fromDate}&toDate=${selectedDate?.toDate}`
            : ""
        }`
      : `/delivery-contractors/contractor-report/${resId}?page=${
          page ? page : 1
        }&size=${size ? size : 10}&lang=${
          localStorage.getItem("lang") === "ar" ? "ar" : "en"
        }${
          !orderType ||
          orderType === "" ||
          orderType === "undefined" ||
          orderType === undefined
            ? ""
            : `&orderType=${orderType}`
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${
          searchValue ? `&search=${searchValue}` : ""
        }${
          selectedDate
            ? `&fromDate=${selectedDate?.fromDate}&toDate=${selectedDate?.toDate}`
            : ""
        }`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  restaurantTrans: [],
  contractorTrans: [],
  error: null,
};
export const TransactionsSlice = createSlice({
  name: "transactions-slice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder

      .addCase(restaurantTransactions.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(restaurantTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurantTrans = action.payload;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(restaurantTransactions.rejected, (state, action) => {
        // console.log(action.payload);
        // if (action.payload) {
        //   sessionStorage.setItem("data", JSON.stringify([]));
        // }
        state.loading = false;
        state.restaurantTrans = null;
        state.error = action.payload;
      })

      .addCase(contractorTransactions.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(contractorTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.contractorTrans = action.payload;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(contractorTransactions.rejected, (state, action) => {
        // console.log(action.payload);
        // if (action.payload) {
        //   sessionStorage.setItem("data", JSON.stringify([]));
        // }
        state.loading = false;
        state.contractorTrans = null;
        state.error = action.payload;
      });
  },
});

// export const {logout} = TransactionsSlice.actions

export default TransactionsSlice.reducer;
