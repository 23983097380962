const optionsAr = {
  timeZone: "Asia/Riyadh",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};
const options = {
  timeZone: "Asia/Riyadh",
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

export const dateFormate = (date) => {
  if (date) {
    let currentDate = new Date(date);
    // const options = { timeZone: 'Asia/Riyadh', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', ,hour12 : true };
    let newDateTime =
      localStorage.getItem("lang") === "ar"
        ? currentDate.toLocaleDateString("ar-EG", optionsAr)
        : currentDate.toLocaleString("en-GB", options);
    newDateTime = newDateTime
      .replace(/\//g, "-")
      .replace(/am|pm/i, (match) => match.toUpperCase());

    return newDateTime;
  }
};

export const fullDate = (date) => {
  if (date) {
    let currentDate = new Date(date);
    // const options = { timeZone: 'Asia/Riyadh', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', ,hour12 : true };
    // const optionsAr = { timeZone: 'Asia/Riyadh', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', ,hour12 : true };
    let newDateTime =
      localStorage.getItem("lang") === "ar"
        ? currentDate.toLocaleDateString("ar-EG", optionsAr)
        : currentDate.toLocaleString("en-US", options);
    newDateTime = newDateTime.replace(/am|pm/i, (match) => match.toUpperCase());

    // newDateTime = newDateTime.replace(/\//g, '-');
    // console.log(newDateTime);
    return newDateTime;
  }
};

export const dateOnlyFormate = (date) => {
  let currentDate = new Date(date);
  const options = {
    timeZone: "Asia/Riyadh",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const optionsAr = {
    timeZone: "Asia/Riyadh",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  let newDate =
    localStorage.getItem("lang") === "ar"
      ? currentDate.toLocaleDateString("ar-EG", optionsAr)
      : currentDate.toLocaleDateString("en-GB", options);
  newDate = newDate.replace(/\//g, "-");
  newDate = newDate.replace(/am|pm/i, (match) => match.toUpperCase());

  return newDate;
};

export const timeOnlyFormate = (date) => {
  let currentDate = new Date(date);
  const timeOptions = {
    timeZone: "Asia/Riyadh",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  const timeOptionsAr = {
    timeZone: "Asia/Riyadh",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  const newTime =
    localStorage.getItem("lang") === "ar"
      ? currentDate.toLocaleTimeString("ar-EG", timeOptionsAr)
      : currentDate.toLocaleTimeString("en-US", timeOptions);

  return newTime;
};

export const timeToDateFormat = (time) => {
  let timeString = time;
  const [hours, minutes, seconds] = timeString.split(":");
  let date = new Date();
  date.setHours(hours, minutes, seconds);
  return date;
};

export const convertTo12Hour = (time) => {
  let [hours, minutes] = time.split(":");
  let period = +hours < 12 ? "AM" : "PM";
  hours = +hours % 12 || 12;
  return `${hours}:${minutes} ${period}`;
};

// convert time to UTC
export const UTCDateFormat = (date) => {
  const optionsAr = {
    timeZone: "UTC",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const options = {
    timeZone: "UTC",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  if (date) {
    let currentDate = new Date(date);
    let newDateTime =
      localStorage.getItem("lang") === "ar"
        ? currentDate.toLocaleDateString("ar-EG", optionsAr)
        : currentDate.toLocaleString("en-GB", options);
    newDateTime = newDateTime
      .replace(/\//g, "-")
      .replace(/am|pm/i, (match) => match.toUpperCase());

    return newDateTime;
  }
};

// convert time to current user locale time
export const formatDateToUserTimezone = (date) => {
  if (!date) {
    return "";
  }

  try {
    const currentDate = new Date(date);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log(userTimezone);

    // Options for formatting (common for both languages)
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: userTimezone,
    };

    let formattedDate;

    if (localStorage.getItem("lang") === "ar") {
      // Use "long" month format for Arabic
      formattedDate = currentDate.toLocaleDateString("ar-EG", options);
    } else {
      formattedDate = currentDate.toLocaleDateString("en-GB", options);
    }

    // Replace slashes with hyphens and capitalize AM/PM
    formattedDate = formattedDate
      .replace(/\//g, "-")
      .replace(/am|pm/i, (match) => match.toUpperCase());

    return formattedDate;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

export const localUserTimezone = (time) => {
  if (time) {
    const timeFormat = time?.split(":");
    const [hours, minutes, seconds] = timeFormat;

    return `${minutes}M : ${seconds}S`;
  }
};
