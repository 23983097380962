import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getAccounts = createAsyncThunk(
  "accountsFunc",
  async (
    { page, size, col, dir, searchValue },
    { rejectWithValue, dispatch }
  ) => {
    let colCheck;

    if (col) {
      colCheck =
        col === "Created at"
          ? "created_at"
          : col === "Account holder name" || col === "اسم مالك الحساب"
          ? "account_holder_name"
          : col === "Balance" || col === "القيمة"
          ? "balance"
          : col === "Bank Name" || col === "اسم البنك"
          ? "bank_name"
          : col === "Status" || col === "الحالة"
          ? "bank_name"
          : col === "Balance" || col === "القيمة"
          ? "balance"
          : col.replace(/\s+/g, "")?.toLowerCase();
    }

    const url = !col
      ? `/accounts?page=${page ? page : 1}&size=${size ? size : 10}${
          searchValue ? `&search=${searchValue}` : ""
        }`
      : `/accounts?page=${page ? page : 1}&size=${
          size ? size : 10
        }&sortBy=${colCheck}${dir ? `&sortOrder=${dir.toUpperCase()}` : ""}${
          searchValue ? `&search=${searchValue}` : ""
        }`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTransaction = createAsyncThunk(
  "transactionFunc",
  async (
    { id, transPage, transSize, selectedDate, col, dir },
    { rejectWithValue, dispatch }
  ) => {
    let colCheck;

    if (col) {
      colCheck =
        col === "Created at" || col === "تاريخ الانشاء"
          ? "created_at"
          : col === "Amount" || col === "المبلغ"
          ? "transaction_amount"
          : col === "Order Payment Method" || col === "طريقة الدفع"
          ? "order_payment_method"
          : col === "Outward" || col === "خارج"
          ? "outward"
          : col === "Status" || col === "الحالة"
          ? "status"
          : col === "Order ID" || col === "رقم الطلب"
          ? "orderId"
          : col.replace(/\s+/g, "")?.toLowerCase();
    }

    const url = !col
      ? `/accounts/${id}/transactions?page=${transPage}&size=${transSize}${
          selectedDate
            ? `&fromDate=${selectedDate?.fromDate}&toDate=${selectedDate?.toDate}`
            : ""
        }`
      : `/accounts/${id}/transactions?page=${transPage}&size=${transSize}${
          selectedDate
            ? `&fromDate=${selectedDate?.fromDate}&toDate=${selectedDate?.toDate}`
            : ""
        }&sortBy=${colCheck}${dir ? `&sortOrder=${dir.toUpperCase()}` : ""}`;

    // let url = `/accounts/${id}/transactions?page=${transPage}&size=${transSize}${selectedDate ? `&fromDate=${adjustedFromDate}&toDate=${adjustedToDate}` : ''}`

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchAccountClearance = createAsyncThunk(
  "patchAccountClearanceFunc",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    // console.log(values);
    try {
      const res = await toast.promise(
        axios.put(`/accounts/${id}/account-clearance`, values),
        {
          pending: "Loading...",
          success: "Account Transactions Cleared Successfully",
        }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  transLoading: false,
  accounts: [],
  transactions: [],
  error: null,
};
export const AccountsSlice = createSlice({
  name: "AccountsSlice-Func",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAccounts.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.accounts = action.payload;
        // console.log(action.payload);
      })

      .addCase(getAccounts.rejected, (state, action) => {
        state.loading = false;
        state.accounts = "";
        state.error = action.payload;
      })

      .addCase(getTransaction.pending, (state, action) => {
        state.transLoading = true;
        state.error = "";
      })

      .addCase(getTransaction.fulfilled, (state, action) => {
        state.transLoading = false;
        state.error = "";
        state.transactions = action.payload;
        // console.log(action.payload);
      })

      .addCase(getTransaction.rejected, (state, action) => {
        state.transLoading = false;
        state.transactions = "";
        state.error = action.payload;
      })

      .addCase(patchAccountClearance.pending, (state, action) => {
        state.transLoading = true;
        state.error = "";
      })

      .addCase(patchAccountClearance.fulfilled, (state, action) => {
        state.transLoading = false;
        state.error = "";
      })

      .addCase(patchAccountClearance.rejected, (state, action) => {
        state.transLoading = false;
        state.error = action.payload;
      });
  },
});

// export const {logout} = AccountsSlice.actions

export default AccountsSlice.reducer;
