import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getProfile = createAsyncThunk(
  "ProfileFunc",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get("/profile");
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        dispatch(errorToast(error.response.data.error));
        return rejectWithValue(error.response.data.error);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateProfile = createAsyncThunk(
  "UpdateProfileFunc",
  async (values, { rejectWithValue, dispatch }) => {
    // console.log(values);
    try {
      const res = await toast.promise(
        axios.put("/profile", values),
        {
          pending: "Loading...",
          success: "Profile Updated Successfully",
        },
        { toastId: "profileToastUpdate" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateProfileImage = createAsyncThunk(
  "UpdateProfileImageFunc",
  async (values, { rejectWithValue, dispatch }) => {
    // console.log(values);
    try {
      const res = await axios.put("/profile/update-profile-picture", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log(res);
      return res.data;
    } catch (error) {
      if (
        error?.response?.data?.message ===
        "Please make sure you have selected a file"
      ) {
        toast.warn("No Image Selected, Keep Old Image?!");
      } else if (
        error.response &&
        error.response.data &&
        error.response.data?.message !==
          "Please make sure you have selected a file"
      ) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  profile: [],
  profileImage: "",
  error: null,
};
export const AuthSlice = createSlice({
  name: "authSlice-Func",
  initialState,
  reducers: {
    logout: (state, action) => {
      state.loading = false;
      state.profile = null;
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.profile = action.payload;
        // console.log(action.payload);
      })

      .addCase(getProfile.rejected, (state, action) => {
        state.loading = false;
        state.profile = "";
        state.error = action.payload;
      })

      .addCase(updateProfile.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.profile = action.payload;
        // console.log(action.payload);
      })

      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.profile = "";
        state.error = action.payload;
      })

      .addCase(updateProfileImage.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(updateProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.profileImage = action.payload;
        // console.log(action.payload);
      })

      .addCase(updateProfileImage.rejected, (state, action) => {
        state.loading = false;
        state.profileImage = "";
        state.error = action.payload;
      });
  },
});

export const { logout } = AuthSlice.actions;

export default AuthSlice.reducer;
