import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { formatDateToUserTimezone } from "../../../../Components/Common/dateFormate";

const customStyles = {
    headRow: {
        style: {
            borderBottom: '1px solid #ddd',
            marginBottom: '8px',

        },
    },
    headCells: {
        style: {
            color: '#979797',
            fontSize: '14px',
            position: 'relative',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: '#64aaef20',
            borderBottomColor: '#FFFFFF',
            borderRadius: '5px',
            outline: '1px solid #FFFFFF',
        },
        style: {
            padding: '8px 0px',
            fontWeight: '600',
            fontSize: '16px',
            marginBottom: '15px'
        }
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const DriverAssignTable = ({ data, t }) => {
    const dispatch = useDispatch()
    const { assignHistory } = useSelector(state => state?.orders)

    const columns = [
        // {
        //     id: 'id',
        //     name: 'ID',
        //     selector: row => row.id,
        //     sortable: false,
        //     width:'80px'
        // },
        {
            id: 'courierName',
            name: t('assignHistory.name'),
            cell: row => <div className="flex items-center gap-x-2"> <img src={row.courierImage} className="w-8 h-8 rounded-[50%]" alt={row.courierName} /> <span>{row.courierName}</span></div>,
            sortable: false,
        },
        {
            id: 'courierNumber',
            name: t('assignHistory.phone'),
            selector: row => row.courierMobileNumber,
            sortable: false,
        },
        {
            id: 'createdAt',
            name: t('assignHistory.createdat'),
            cell: row => <span title={formatDateToUserTimezone(row.createdAt)}>{formatDateToUserTimezone(row.createdAt)}</span>,
            sortable: false,
        },
        // {
        //     id: 'orderId',
        //     name: t('assignHistory.orderid'),
        //     selector: row => row.orderId,
        //     sortable: false,
        // },
        // {
        //     id: 'actions',
        //     name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
        //     allowOverflow: true,
        //     button : true,
        //     cell: row=>(
        //         <>
        //             {<ActionCell data={row} />}
        //         </>
        //     )
        // },
    ];


    return (
        <>
            <DataTable
                direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                columns={columns}
                data={assignHistory?.data}
                // pagination
                // paginationPerPage={2}
                // paginationRowsPerPageOptions={[2,10,50,100]}
                customStyles={customStyles}
                highlightOnHover
                className='w-full'
            />
        </>
    );
}

export default DriverAssignTable;