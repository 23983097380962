import React, { useState, useEffect } from 'react';

const NumberCounter = ({ startNumber, endNumber, duration, suffix }) => {
    const [currentNumber, setCurrentNumber] = useState(parseInt(startNumber) || 0);

    useEffect(() => {
        const startTime = performance.now();
        const totalChange = parseInt(endNumber) - parseInt(startNumber);

        const animate = (time) => {
            const elapsedTime = time - startTime;
            const progress = Math.min(elapsedTime / parseInt(duration), 1);
            const easingProgress = easeOutCubic(progress); // Using a cubic easing function

            setCurrentNumber(Math.round(startNumber + (totalChange * easingProgress)));

            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);

        return () => {
            // Cleanup the animation frame if the component is unmounted
            setCurrentNumber(endNumber);
        };
    }, [startNumber, endNumber, duration]);

    // Easing function for a smoother, slower effect
    const easeOutCubic = (t) => (--t) * t * t + 1;

    return (
        <span>
            {currentNumber}{suffix}
        </span>
    );
};

export default NumberCounter;
