import { Button, ListItemButton, Menu, MenuItem } from '@mui/material';
import { useCallback, useState } from 'react';
import DataTable from 'react-data-table-component';
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { BsDot } from 'react-icons/bs'
import { FaEdit, FaRegEye } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
// import kfc from '../../../Assets/imgs/kfc.svg'
import EditBranchModal from './Branches-Components/editBranchModal';
import AddBranchModal from './Branches-Components/addBranchModal';
import { IoMdAddCircle } from 'react-icons/io';
import ColumnSelection from '../../../Components/Common/columnSelection';
import { useRemoteSort } from '../../../Components/Common/sortHook'
import NoDataEmoji from '../../../Components/Common/nodataEmoje';
import { TableStyles } from '../../../Components/Common/tableStyle';
import { useGoogleMaps } from '../../../Components/Common/mapLoader';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { getBranches } from '../../Redux/Slice/AccountManager/Branch/branches';
import { formatDateToUserTimezone } from '../../Common/dateFormate';
import { useSearchBox } from '../../Common/searchHook';
//Custome Call UI for the data table action column

function ActionCell({ data }) {
    const [openModal, setopenModal] = useState(false)
    const [Type, setType] = useState('')
    const handelOpen = (type) => {
        setopenModal(true)
        setType(type)
    }
    const handelClose = () => setopenModal(false)
    // console.log(data);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none', padding: 0 } }}
                className='shadow-md p-0'
            >
                <div className={`[&>li]:flex [&>li]:items-center ${localStorage.getItem('lang') === 'ar' ? '[&>li]:flex-row-reverse [&>li>svg]:ml-2' : 'flex-row [&>li>svg]:mr-2'} [&>li]:mb-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize`}>
                    {/* <MenuItem className='text-mainGreen hover:bg-[#5cac7d42]'><BsTelephone /> +9225621123</MenuItem> */}
                    <MenuItem className='text-[#9b96a8] hover:bg-[#d9c8ff42]' onClick={() => handelOpen('view')}><FaRegEye />{localStorage.getItem('lang') === 'ar' ? 'عرض' : 'View'}</MenuItem>
                    <MenuItem className='text-mainColor hover:bg-[#442b7e42]' onClick={() => handelOpen('edit')}><FaEdit />{localStorage.getItem('lang') === 'ar' ? 'تعديل' : 'Edit'}</MenuItem>
                    {/* <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42]'><FaStore />Resturant profile </MenuItem>
                    <MenuItem className='text-mainYellow hover:bg-[#d9ad5642]'><FaBarcode />resturant menu</MenuItem>
                    <MenuItem className='text-[#3699FF] hover:bg-[#369bff42]'><RiMoneyDollarCircleLine />completed orders</MenuItem>
                    <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><FaStoreAltSlash />deactivate</MenuItem> */}
                </div>
            </Menu>

            <EditBranchModal type={Type} open={openModal} close={handelClose} data={data} />
        </div>
    );
}

const Branches = () => {
    const { t, i18n } = useTranslation()

    const [openModal, setopenModal] = useState(false)
    // const [searchValue, setsearchValue] = useState('')
    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')

    const { currentPage } = useSelector((state) => state?.resetPagination);
    const { toggle, sort } = useSelector((state) => state?.resetPagination);

    const { isLoaded, loadError } = useGoogleMaps();

    const handelOpen = (type) => {
        setopenModal(true)
    }
    const handelClose = () => setopenModal(false)

    const { branches } = useSelector(state => state)
    const dispatch = useDispatch()

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    // useEffect(() => {
    //     setsearchValue('')
    // }, [search])


    const columns = [
        {
            id: 'branchName',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم الفرع' : 'Branch Name'}`,
            sortable: true,
            cell: (row) => (
                <div className='flex gap-x-2 items-center'>
                    {/* <img src={kfc} alt='returant-logo' className='w-10 h-10 rounded-[50%]' /> */}
                    <p>{row.name}</p>
                </div>
            )
        },
        {
            id: 'branchArabicName',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم الفرع العربى' : 'Branch Arabic Name'}`,
            sortable: true,
            selector: (row) => row?.nameAr
        },
        {
            id: 'address',
            name: `${localStorage.getItem('lang') === 'ar' ? 'العنوان' : 'Address'}`,
            cell: (row) => <div title={row.address} className='max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap'>{row.address}</div>,
            sortable: true,
        },
        {
            id: 'description',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الوصف' : 'Description'}`,
            cell: (row) => <div title={row.description} className='max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap'>{row.description}</div>,
        },
        {
            id: 'createdAt',
            name: `${localStorage.getItem('lang') === 'ar' ? 'تم إنشاءه في' : 'Created At'}`,
            selector: row => formatDateToUserTimezone(row.createdAt),
            sortable: true,
        },
        {
            id: "acceptanceRate",
            name: t('restaurants.acceptanceRate'),
            cell: row => <span>{row?.acceptanceRate}%</span>,
            sortable: false,
        },
        {
            id: 'averageOrderPreparationTime',
            name: t('restaurants.averageOrderPreparationTime'),
            cell: row => <div data-tag="allowRowEvents">{row?.averageOrderPreparationTime}M</div>,
            sortable: false,
        },
        {
            id: 'actions',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اوامر' : 'Actions'}`,
            allowOverflow: true,
            button: true,
            cell: row => (
                <>
                    {<ActionCell data={row} />}
                </>
            )
        },
    ];


    const data = branches?.branches?.data


    // console.log(data);

    // open calander overlay
    // const { dateOverlay } = useSelector(state => state.overlay)

    // console.log(dateOverlay);

    // const handleSearch = () => {
    //     dispatch(getBranches({ searchValue })).then(() => dispatch(resetPage()))
    // }

    // const handleReset = () => {
    //     dispatch(getBranches({ page, size })).then(() => dispatch(resetPage()))
    //     setsearchValue('')
    // }

    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);

    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);

    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };

    // const [selectedByIndex, setselectedByIndex] = useState(-1)
    // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

    // const sortByList = [
    //     {name:'id'},
    //     {name:'Branch Name'},
    //     {name:'address'},
    //     {name:'created at'},
    // ]

    // const sortOrderList = [
    //     {name:'Asc'},
    //     {name:'Desc'},
    // ]

    // const handleActiveBy = (item,index)=>{
    //     setselectedByIndex(index)
    //     setcol(item?.name)
    //     setAnchorElBy(null)
    //     // dispatch(getBranches({page,row,col,dir,searchValue}))
    // }
    // const handleActiveOrder = (item,index)=>{
    //     setselectedOrderIndex(index)
    //     setdir(item?.name)
    //     setAnchorElOrder(null);
    // }

    const placeHolder = t('placeholder.searchBranchPlaceholder')
    const { searchValue, searchInput } = useSearchBox(getBranches, placeHolder)
    const { handleRemoteSort, icon } = useRemoteSort(getBranches, dispatch, page, size, searchValue)



    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['branchName', 'branchArabicName', 'address', 'description', 'createdAt', 'acceptanceRate', 'averageOrderPreparationTime', 'actions']);

    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = () => {
        setopen(true)
    }
    const handleClose = () => {
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['branchName', 'branchArabicName', 'address', 'description', 'createdAt', 'acceptanceRate', 'averageOrderPreparationTime', 'actions']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);


    return (
        <article className="branches-wrapper">
            <section className="branches-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('branch.title')}</h1>
            </section>

            <section className={`branches-control-wrapper flex ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} flex-wrap justify-center lg:justify-between items-center mt-5 w-full`}>

                <div className="orders-search-wrapper flex items-center gap-x-2">
                    <div>
                        {/* <FormControl
                            className="search-form [&>div>input]:py-[.6rem]"
                            sx={{ m: 1, width: '35ch', backgroundColor: 'white', borderRadius: '6px', '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': { padding: '10px' }, '& fieldset': { borderColor: 'primary.main', border: 'none' } }} variant="outlined">
                            <OutlinedInput
                                type="search"
                                id="outlined-adornment-search"
                                value={searchValue}
                                placeholder={`${localStorage?.getItem('lang') === 'ar' ? 'ابحث في الفروع' : 'Search Branches...'}`}
                                onChange={(e) => {
                                    setsearchValue(e.target.value)
                                    dispatch(resetPage())
                                    if (!e.target.value) {
                                        handleReset();
                                        dispatch(resetPage())
                                    }
                                }}
                                startAdornment={
                                    <InputAdornment position="start">

                                        <IconButton
                                            aria-label="search"
                                            onClick={handleSearch}
                                            edge="start"
                                        >
                                            <CiSearch />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl> */}
                        {searchInput()}
                    </div>

                    {/* <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openBy ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBy ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickBy}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                        >
                            {col ? col?.replace(/_/g, " ") : 'Sort By'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElBy}
                            open={openBy}
                            onClose={handleCloseBy}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                Sort By
                            </MenuItem>

                            {sortByList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                    onClick={()=>handleActiveBy(item,index)}
                                    selected = {index === selectedByIndex}
                                >
                                    {item?.name?.replace(/_/g, " ")}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div>

                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrder ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickOrder}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                        >
                            {dir ? dir : 'Sort Order'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElOrder}
                            open={openOrder}
                            onClose={handleCloseOrder}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                Sort Order
                            </MenuItem>

                            {sortOrderList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                    onClick={()=>handleActiveOrder(item,index)}
                                    selected = {index === selectedOrderIndex}
                                >
                                    {item?.name}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div> */}


                </div>

                <div className='flex items-center gap-x-3'>
                    <Button onClick={handelOpen} className={`capitalize text-white font-semibold bg-mainGreen rounded-md py-[6px] gap-x-1 flex items-center ${localStorage?.getItem('lang') === 'ar' ? 'pl-2' : 'pr-2'}`}><IoMdAddCircle className={`w-7 h-7 rounded-[50%] ${localStorage?.getItem('lang') === 'ar' ? 'ml-2' : 'mr-2'}`} /><span className='text-sm font-playfair'>{t('branch.addBtn')}</span></Button>

                    <div className="flex">
                        <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                        {/* <Button onClick={handleOpen} sx={{py:1,px:1}} className='capitalize text-white bg-mainColor pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                    </div>
                </div>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                                ))}
                            </Box>
                            )}
                    >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white rounded-md relative">
                {data?.length === 0 ?
                    <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage={size}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            paginationServer
                            paginationTotalRows={branches?.branches?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            sortServer
                            sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                            onSort={handleRemoteSort}
                            paginationDefaultPage={page}
                            keyField="id"
                            paginationResetDefaultPage={true}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: t('common.paginationRowText'),
                                    rangeSeparatorText: t('common.of')
                                }
                            }
                        />

                        {branches?.loading ?
                            <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                                <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                            </div> : null}
                    </>

                }

            </section>

            <AddBranchModal open={openModal} close={handelClose} data={data} isLoaded={isLoaded} />
        </article>
    );
}

export default Branches;