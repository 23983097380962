import { Box, Button, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";
import { getRestaurants, toggleVisibility } from "../../../Components/Redux/Slice/Restaurants/restaurantsSlice";

const VisibilityModal = ({ open, close, id, visibility }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '1px solid #ddd',
        boxShadow: 24,
        px: 1,
        py: 2,
        borderRadius: '10px'
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        const values = {
            id: id,
            visibility: visibility === 0 ? 'invisible' : 'visible'
        }
        await dispatch(toggleVisibility(values)).then(() => {
            dispatch(getRestaurants({ page: 1, size: 10 }))
            dispatch(resetPage())
            dispatch(resetSearch())
            dispatch(resetSort())
            close()
        })
    }

    return (
        <>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-warning"
                aria-describedby="modal-warning-delete"
            >
                <Box
                    sx={style}
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                >
                    <div className="warnning-modal-wrapper">
                        <div className="flex justify-end items-center">
                            <div>
                                <Button color="error" className="text-2xl -mt-2" onClick={close}><IoCloseCircleOutline /></Button>
                            </div>
                        </div>

                        <div className={`capitalize text-center w-full font-semibold text-lg mt-4 ${visibility !== 0 ? 'text-mainGreen' : 'text-mainRed'}`}>
                            <h2>{visibility === 0 ? t('common.hideWarning') : t('common.showWarning')}</h2>
                        </div>

                        <div className={`flex gap-x-7 items-center mt-8 w-full justify-end [&>button]:min-w-[150px]  ${localStorage.getItem('lang') === 'ar' ? 'pl-4 flex-row-reverse' : 'pr-4'} `}>
                            <Button className="bg-gray-400 text-white capitalize" onClick={close}>{t('offers.warning_modal.cancel_btn')}</Button>
                            <Button className=" capitalize text-white bg-mainColor" onClick={handleSubmit}>{t('common.save_btn')}</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default VisibilityModal;