import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../Toast-Messages/toastMessage";

export const getRating = createAsyncThunk(
  "getRateFunc",
  async ({ page, size, selectedDate }, { rejectWithValue, dispatch }) => {
    // let from_date = selectedDate?.startDate?.toISOString(1).slice(0,10)
    // console.log(page,size);
    // let from_date = null,
    //     adjustedFromDate = null,
    //     to_date = null,
    //     adjustedToDate = null

    // if(selectedDate){
    //     from_date = new Date(selectedDate?.startDate)
    //     adjustedFromDate = new Date(from_date.getTime() + (from_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);

    //     to_date = new Date(selectedDate?.endDate)
    //     adjustedToDate = new Date(to_date.getTime() + (to_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);
    // }

    // console.log(selectedDate);

    let url = `my-restaurant/ratings?page=${page ? page : 1}&size=${
      size ? size : 10
    }${selectedDate ? `&from_date=${selectedDate?.fromDate}` : ""}${
      selectedDate ? `&to_date=${selectedDate?.toDate}` : ""
    }`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getRatingSummary = createAsyncThunk(
  "getRatingFunc",
  async (date, { rejectWithValue, dispatch, selectedDate }) => {
    // console.log(date);

    let url = date
      ? `my-restaurant/ratings/summary?${
          selectedDate ? `&from_date=${selectedDate?.fromDate}` : ""
        }${selectedDate ? `&to_date=${selectedDate?.toDate}` : ""}`
      : `my-restaurant/ratings/summary`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  rate: [],
  ratingSummary: [],
  error: null,
};
export const RatingSlice = createSlice({
  name: "RatingSlice-Func",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getRating.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getRating.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.rate = action.payload;
        // console.log(action.payload);
      })

      .addCase(getRating.rejected, (state, action) => {
        state.loading = false;
        state.rate = [];
        state.error = action.payload;
      })

      .addCase(getRatingSummary.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getRatingSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.ratingSummary = action.payload;
        // console.log(action.payload);
      })

      .addCase(getRatingSummary.rejected, (state, action) => {
        state.loading = false;
        state.ratingSummary = [];
        state.error = action.payload;
      });
  },
});

export default RatingSlice.reducer;
