import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";
// import { toast } from "react-toastify";
import i18next from "i18next"; // Import i18next

export const getPayments = createAsyncThunk(
  "getPaymentsFunc",
  async (
    { page, size, col, dir, searchValue, selectedDate },
    { rejectWithValue, dispatch }
  ) => {
    let colCheck;

    if (col) {
      // colCheck = col === 'Created at' ? 'created_at' : col === 'Delieverd at' ? 'delivered_at' : col === 'Updated at' ? 'updated_at' : col === 'Restaurant Name' ? 'restaurant_name' : col === 'Courier Name' ? 'courier_name' : col === 'Courier Rating' ? 'courier_rating' : col === 'Courier Number' ? 'courier_mobile_number' : col === 'Customer Name' ? 'customer_name' : col === 'Customer Number' ? 'customer_mobile_number' : col === 'Customer Address' ? 'customer_address' : col === 'Order Rating' ? 'order_rating' : col === 'Branch Name' ? 'branch_name' : col === 'Branch Number' ? 'branch_number' : col === 'Branch Address' ? 'branch_address' : col === 'Min Charge' ? 'minimum_charge' : col === 'Avg Delivery Time' ? 'average_delivery_time' : col === 'Avg Rating' ? 'average_rating' : col === 'Total Orders' ? 'total_orders' : col === 'Delivery Charges' ? 'delivery_charges' : col === 'Pickup Schedule' ? 'pickup_schedule' : col === 'Vehicle Plate' ? 'vehicle_plate' : col === 'Original Price' ? 'original_price' : col === 'Final Price' ? 'final_price' : col.replace(/\s+/g, '');
      colCheck =
        col === i18next.t("ID")
          ? "id"
          : col === i18next.t("payments.orderId")
          ? "order_id"
          : col === i18next.t("payments.amount")
          ? "amount"
          : col === i18next.t("payments.paymentType")
          ? "payment_type"
          : col === i18next.t("payments.paymentBrand")
          ? "payment_brand"
          : col === i18next.t("payments.status")
          ? "status"
          : col === i18next.t("payments.createdAt")
          ? "created_at"
          : col.replace(/\s+/g, "").toLowerCase();
    }

    const url = !col
      ? `/payments?page=${page ? page : 1}&size=${size ? size : 10}${
          searchValue ? `&search=${searchValue}` : ""
        }${
          selectedDate
            ? `&fromDate=${selectedDate?.fromDate}&toDate=${selectedDate?.toDate}`
            : ""
        }
        `
      : `/payments?page=${page ? page : 1}&size=${
          size ? size : 10
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${
          searchValue ? `&search=${searchValue}` : ""
        }${
          selectedDate
            ? `&fromDate=${selectedDate?.fromDate}&toDate=${selectedDate?.toDate}`
            : ""
        }`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  payments: [],
  error: null,
};
export const PaymentSlice = createSlice({
  name: "payments-slice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder

      .addCase(getPayments.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = action.payload;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(getPayments.rejected, (state, action) => {
        // console.log(action.payload);
        // if (action.payload) {
        //   sessionStorage.setItem("data", JSON.stringify([]));
        // }
        state.loading = false;
        state.payments = null;
        state.error = action.payload;
      });
  },
});

// export const {logout} = PaymentSlice.actions

export default PaymentSlice.reducer;
