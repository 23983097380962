import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getBanks = createAsyncThunk(
  "getAllBanksFunc",
  async (
    { page, size, searchValue, col, dir },
    { rejectWithValue, dispatch }
  ) => {
    let colCheck;

    if (col) {
      // colCheck = col === 'Created at' ? 'created_at' : col === 'Last Login' ? 'last_login' : col === 'Updated at' ? 'updated_at' : col === 'Name' ? 'full_name' :  col.replace(/\s+/g, '');
      colCheck =
        col === "Created at" || col === "تم انشاءه فى"
          ? "created_at"
          : col === "Last Login" || col === "اخر تسجيل دخول"
          ? "last_login"
          : col === "Updated at" || col === "تعديل في"
          ? "updated_at"
          : col === "Name" || col === "الاسم"
          ? "full_name"
          : col === "Email" || col === "الايميل"
          ? "email"
          : col === "Status" || col === "الحالة"
          ? "status"
          : col.replace(/\s+/g, "");
    }

    const url = !col
      ? `/banks?page=${page ? page : 1}&size=${size ? size : 10}${
          searchValue ? `&search=${searchValue}` : ""
        }`
      : `/banks?page=${page ? page : 1}&size=${
          size ? size : 10
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${
          searchValue ? `&search=${searchValue}` : ""
        }`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addBank = createAsyncThunk(
  "addBanksFunc",
  async (data, { rejectWithValue, dispatch }) => {
    const url = `/banks`;

    try {
      const res = await axios.post(url, data);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editBank = createAsyncThunk(
  "editBanksFunc",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    const url = `/banks/${id}`;

    try {
      const res = await axios.put(url, data);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getBanksList = createAsyncThunk(
  "getAllBanksListFunc",
  async (_, { rejectWithValue, dispatch }) => {
    const url = `/banks/list`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  banks: [],
  allBanks: [],
  error: null,
};
export const BankSlice = createSlice({
  name: "BankSlice-Func",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getBanks.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getBanks.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.banks = action.payload;
        // console.log(action.payload);
      })

      .addCase(getBanks.rejected, (state, action) => {
        state.loading = false;
        state.banks = "";
        state.error = action.payload;
      })

      .addCase(addBank.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(addBank.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // state.banks = action.payload;
        // console.log(action.payload);
      })

      .addCase(addBank.rejected, (state, action) => {
        state.loading = false;
        // state.banks = "";
        state.error = action.payload;
      })

      .addCase(editBank.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(editBank.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // state.banks = action.payload;
        // console.log(action.payload);
      })

      .addCase(editBank.rejected, (state, action) => {
        state.loading = false;
        // state.banks = "";
        state.error = action.payload;
      })

      .addCase(getBanksList.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getBanksList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.allBanks = action.payload;
        // console.log(action.payload);
      })

      .addCase(getBanksList.rejected, (state, action) => {
        state.loading = false;
        state.allBanks = [];
        state.error = action.payload;
      });
  },
});

// export const {} = BankSlice.actions

export default BankSlice.reducer;
