import { Box, Button, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTransaction, patchAccountClearance } from "../../Components/Redux/Slice/Accounts/accounts";
import { openHandler } from "../../Components/Redux/Slice/common/overlaySlice";
import { DateRangePicker } from "react-date-range";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dateFormate, dateOnlyFormate, formatDateToUserTimezone } from "../../Components/Common/dateFormate";
import { Link } from "react-router-dom";
import NoDataEmoji from "../../Components/Common/nodataEmoje";
import DataTable from "react-data-table-component";
import { FaAngleDown } from "react-icons/fa";
import { useRemoteSort } from "../../Components/Common/sortHook";
import { BsDot, BsSortDown, BsSortUp } from "react-icons/bs";
import { toast } from "react-toastify";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";

const Transactions = ({ activeRowData, t, dispatch, accounts, transPage, settransPage, transSize, settransSize, register, reset, setValue, id, resetPage, TableStyles, handleSubmit, errors, control }) => {
    const [selectedDate, setSelectedDate] = useState(null); // for storing the selected date from date picker
    const [date, setdate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const handleDateRangeChange = async (e) => {

        setdate([e.selection])
        let dateRange = e?.selection
        let fromDate = dayjs(dateRange?.startDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
        let toDate = dayjs(dateRange?.endDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
        let selectedDate = { fromDate: fromDate, toDate: toDate }
        let newPage = 1
        settransPage(1)
        settransSize(10)
        setSelectedDate(selectedDate)
        await dispatch(getTransaction({ transPage: newPage, transSize, id, selectedDate }))
        // dispatch(getRatingSummary(selectedDate))
    }
    // console.log(data);
    // const handleDateRangeChange = (e) => {
    //     setdate([e.selection])
    //     let selectedDate = e?.selection
    //     setSelectedDate(selectedDate)
    //     dispatch(getTransaction({ transPage, transSize, id, selectedDate }))
    //     // dispatch(getRatingSummary(selectedDate))
    // }

    const { dateOverlay } = useSelector(state => state.overlay)

    const handleButton = () => {
        dispatch(openHandler({ dateShow: !dateOverlay }))
    }
    // let page = page,
    //     size = size

    // console.log(page);
    const transColumns = [
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'رقم الطلب' : 'Order ID'}`,
            cell: row => <Button><Link to='/dashboard/orders/orderdetails' state={{ id: row?.orderId }}>{row?.orderId}</Link></Button>,
            sortable: false,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'المبلغ' : 'Amount'}`,
            cell: row => <><span className={`${row?.outward ? 'text-mainRed' : 'text-mainGreen'}`}>{row.transactionAmount}</span>&nbsp; <span> {t('common.sar')}</span></>,
            sortable: true,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'status'}`,
            cell: row => <span className={`bg-opacity-25 capitalize text-center font-semibold min-w-[120px] p-1 rounded-md
                ${row?.transactionStatus === 'PENDING' ? 'text-amber-400 bg-amber-400' : row?.transactionStatus === 'COMPLETED' ? 'text-mainGreen bg-mainGreen' : row?.transactionStatus === 'CANCELLED' ? 'text-mainRed bg-mainRed' : row?.transactionStatus === 'ON_HOLD' ? 'text-mainYellow bg-mainYellow' : 'text-dark bg-dark'}`}>
                {localStorage.getItem('lang') === 'ar' ? row?.transactionStatusAr : row?.transactionStatus?.toLowerCase()}
            </span>,
            sortable: false,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'تاريخ الانشاء' : 'Created at'}`,
            selector: row => formatDateToUserTimezone(row.createdAt),
            sortable: true,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'طريقة الدفع' : 'Order Payment Method'}`,
            cell: row => <><span>{row.orderPaymentMethod}</span></>,
            sortable: true,
        },

        // {
        //     name: 'Actions',
        //     allowOverflow: true,
        //     button : true,
        //     cell: ActionCell

        // },
    ];


    const transData = accounts?.transactions?.data

    const handlepageChange = page => {
        settransPage(page);
    };
    const handleTransactionRowChange = (perPage) => {
        settransSize(perPage);
    };


    // Account Clearance Modal

    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        minWidth: '500px',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        py: 4,
        px: 2,
        borderRadius: 5,
    };

    const [openClearance, setOpenClearance] = useState(false);
    const [from, setfrom] = useState(null)
    const [to, setto] = useState(null)

    const handleClearanceOpen = (e) => {
        setOpenClearance(true)

    };
    const handleClearanceClose = () => {
        setOpenClearance(false);
        setfrom(null)
        setto(null)
        reset()
    }

    // useEffect(() => {
    //     let fromDateFormat = from ? new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())).toISOString() : null;
    //     setValue('startDate',fromDateFormat)
    // }, [from])

    // useEffect(() => {
    //     let toDateFormat = to ? new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(),23, 59, 59)).toISOString() : null;
    //     setValue('endDate',toDateFormat)
    // }, [to])


    const submitHandler = async (values) => {
        if (values?.endDate < values?.startDate) {
            toast.error('End date cannot be before start date')
        } else {
            dispatch(patchAccountClearance({ id, values })).then((e) => {
                if (e?.payload?.message === "SUCCESS") {
                    dispatch(getTransaction({ transPage, transSize, id }))
                    handleClearanceClose()
                } else {
                    handleClearanceClose()
                }
            })
        }
    }


    const [sortCount, setSortCount] = useState(0);
    const [defState, setdefState] = useState(0);
    const [col, setCol] = useState(null);
    const [dir, setDir] = useState(null);
    const [icon, seticon] = useState(<BsSortUp />);

    const handleRemoteSort = (column) => {
        let colName = column?.name;
        if (col === colName && sortCount === 0) {
            setDir('DESC');
            setSortCount(1);
            seticon(<BsSortDown />)
        } else if (col === colName && sortCount === 1) {
            setDir(null);
            setCol(null);
            setSortCount(0);
            seticon(<BsDot className="text-[1px] opacity-0" />)
            // Here, you would reset your data to its original state
        } else {
            setCol(colName);
            setDir('ASC');
            setSortCount(0);
            seticon(<BsSortUp />)
        }
    };

    useEffect(() => {
        dispatch(getTransaction({ transPage, transSize, col, dir, id, selectedDate }));
    }, [getTransaction, col, dir, dispatch, transPage, transSize, selectedDate, id]);
    // console.log(transData);


    return (
        <>
            <div className='accounts-preview-title font-semibold mb-3 text-[#4b515e71] text-lg capitalize'>
                <h1 className="text-2xl text-mainColor">{activeRowData?.accountHolderName}</h1>

                <div className={`flex items-center justify-between mt-3 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <div className="flex items-center gap-x-2 capitalize">
                        <h2>{t('accounts.transactions')}</h2>

                        <div className='date-wrapper relative w-fit'>
                            <Button className='bg-white text-[#333] rounded-md flex items-center' onClick={handleButton}>
                                <p className='flex items-center text-[#333] font-semibold font-playfair capitalize text-sm'> {date[0]?.startDate && date[0]?.endDate ? dateOnlyFormate(dayjs(date[0]?.startDate)) + " - " + dateOnlyFormate(dayjs(date[0]?.endDate)) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></p>
                            </Button>

                            {dateOverlay ?

                                <div className={`absolute -top-10 z-[9999] bg-white ${localStorage?.getItem('lang') === 'ar' ? 'left-full' : 'top-full'}`} dir='ltr'>
                                    <DateRangePicker
                                        onChange={handleDateRangeChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={date}
                                        direction="vertical"
                                    />
                                </div>
                                : null}

                        </div>

                    </div>

                    <div>
                        <Button className="bg-[#e5edf5] capitalize text-sm px-3" onClick={handleClearanceOpen}>{t('accounts.clearance_btn')}</Button>
                    </div>
                </div>
            </div>

            <div className="relative bg-white rounded-md">
                {transData?.length === 0 ?
                    <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            key={id}
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={transColumns}
                            data={transData}
                            pagination
                            paginationPerPage={transSize}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            paginationServer
                            paginationTotalRows={accounts?.transactions?.meta?.total}
                            paginationDefaultPage={1}
                            paginationResetDefaultPage={resetPage ? 1 : null}
                            onChangePage={handlepageChange}
                            onChangeRowsPerPage={handleTransactionRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            sortIcon={icon}
                            onSort={handleRemoteSort}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: t('common.paginationRowText'),
                                    rangeSeparatorText: t('common.of')
                                }
                            }
                        />

                        {accounts?.transLoading ?
                            <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                                <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                            </div> : null}
                    </>
                }

            </div>

            <Modal
                open={openClearance}
                onClose={handleClearanceClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                    <div className="flex justify-between items-center border-b pb-2 mb-3">
                        <h1 className="text-xl text-[#333] capitalize font-semibold">{t('accounts.clearance_btn')}</h1>

                        {/* <div>
                            <FaTimesCircle onClick={handleClearanceClose} className="text-mainRed text-xl cursor-pointer" />
                        </div> */}
                    </div>

                    <form noValidate onSubmit={handleSubmit(submitHandler)}>
                        <div className=" [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">

                            <div className="flex items-center justify-between gap-4 [&>div>label]:capitalize [&>div>label]:mb-2 [&>div>label]:block">

                                <div>
                                    <label>{t('accounts.clearance.form.start_date')}</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Controller
                                            name="startDate"
                                            control={control}
                                            defaultValue={from}
                                            rules={{ required: t('common.required') }}
                                            render={({ field }) => (
                                                <MobileDatePicker
                                                    {...field}
                                                    className="w-full p-0 bg-[#f3f6f9] rounded-md"
                                                    sx={{ '& fieldset': { border: 0, outline: 0 } }}
                                                    id="startDate"
                                                    // disablePast
                                                    value={dayjs(from)}
                                                    onChange={(e) => {
                                                        field?.onChange(dayjs(e)?.utc()?.format('YYYY-MM-DDTHH:mm:ss.000[Z]'))
                                                        const selectedDate = dayjs(e);
                                                        setfrom(selectedDate);
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>

                                    {errors?.startDate?.message ? <p className="capitalize text-sm text-danger">{errors?.startDate?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('accounts.clearance.form.start_date_helper')}</p>}
                                </div>

                                <div>
                                    <label>{t('accounts.clearance.form.end_date')}</label>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Controller
                                            name="endDate"
                                            control={control}
                                            defaultValue={to}
                                            rules={{ required: t('common.required') }}
                                            render={({ field }) => (
                                                <MobileDatePicker
                                                    {...field}
                                                    className="w-full p-0 bg-[#f3f6f9] rounded-md"
                                                    sx={{ '& fieldset': { border: 0, outline: 0 } }}
                                                    id="endDate"
                                                    // disablePast
                                                    value={dayjs(to)}
                                                    onChange={(e) => {
                                                        field?.onChange(dayjs(e)?.endOf('day')?.utc()?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
                                                        const selectedDate = dayjs(e);
                                                        setto(selectedDate);
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>

                                    {errors?.endDate?.message ? <p className="capitalize text-sm text-danger">{errors?.endDate?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('accounts.clearance.form.end_date_helper')}</p>}
                                </div>

                            </div>

                            <div className="mt-3">
                                <label>{t('accounts.clearance.form.notes')}</label>

                                <textarea
                                    className="w-full  h-auto min-h-[90px] resize-none rounded-md bg-[#f3f6f9] focus:outline-slate-400 text-mainColor p-2"
                                    id="notes"

                                    {...register('notes', {
                                        required: {
                                            value: true,
                                            message: t('common.required')
                                        },
                                        minLength: {
                                            value: 10,
                                            message: t('common.min_length', { length: '10' })
                                        },
                                        maxLength: {
                                            value: 500,
                                            message: t('common.max_length', { length: '500' })
                                        },
                                    })}

                                    minLength={10}
                                    maxLength={500}
                                />

                                {errors?.notes?.message ? <p className="capitalize text-[14px] text-danger">{errors?.notes?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('accounts.clearance.form.notes_helper')}</p>}

                            </div>

                            <div className={`flex ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} justify-end item-center gap-x-4 mt-6 mb-0 capitalize text-sm [&>button]:text-white [&>button]:rounded-lg [&>button]:px-4`}>
                                <Button className="bg-gray-400 capitalize" onClick={handleClearanceClose}>{t('common.cancel_btn')}</Button>
                                <Button className="capitalize bg-mainColor disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed" type="submit" disabled={accounts?.transLoading}>{t('common.submit')}</Button>
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    );
}

export default Transactions;