import { Switch } from "@mui/material";
import { useState } from "react";
import VisibilityModal from "./visibilityModal";
import { useEffect } from "react";

const VisibilitySwitch = ({ row }) => {
    const [checked, setChecked] = useState(!row?.isHidden);

    useEffect(() => {
        setChecked(!row?.isHidden)
    }, [row])


    // Modal
    const [open, setopen] = useState(false)

    const handleOpen = () => {
        setopen(true)
        setChecked(!checked)
    }

    const handleClose = () => {
        setopen(false)
        setChecked(!row?.isHidden)
    }
    return (
        <>
            <Switch
                checked={checked}
                onChange={handleOpen}
                inputProps={{ 'aria-label': 'controlled' }}
            />


            <VisibilityModal open={open} close={handleClose} id={row?.id} visibility={row?.isHidden} />
        </>
    );
}

export default VisibilitySwitch;