import { TbReport } from "react-icons/tb";
import { BsFiletypePdf } from "react-icons/bs";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import DataTable from "react-data-table-component";
import ColumnSelection from "../../../Components/Common/columnSelection";
import { TableStyles } from "../../../Components/Common/tableStyle";
import { formatDateToUserTimezone } from "../../../Components/Common/dateFormate";
import { useSearchParams } from "react-router-dom";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import { DateRangePicker } from "react-date-range";
import { openHandler } from "../../../Components/Redux/Slice/common/overlaySlice";
import dayjs from "dayjs";
import { FaAngleDown } from "react-icons/fa6";
import { BsDot } from "react-icons/bs";
import { contractorTransactions } from "../../../Components/Redux/Slice/Transactions/transactions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { usePDFGenerator } from "../../../Components/Common/pdfGenerator";
import { useXLSX } from "../../../Components/Common/xlsxGenerator";
import { useSearchBox } from "../../../Components/Common/searchHook";

const ContractorTransactions = () => {
    const [searchParams] = useSearchParams();
    let resId = searchParams.get("id");
    // console.log(resId);

    // const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const transactions = useSelector(state => state?.transactions)
    // const [searchValue, setsearchValue] = useState('')
    const [orderType, setorderType] = useState('');

    const { currentPage, toggle, search, sort } = useSelector((state) => state?.resetPagination);

    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    // useEffect(() => {
    //     setsearchValue('')
    // }, [search])

    // const handleSearch = () => {
    //     dispatch(contractorTransactions({ resId, searchValue })).then(() => dispatch(resetPage()))
    // }

    // const handleReset = () => {
    //     dispatch(contractorTransactions({ resId, page, size })).then(() => dispatch(resetPage()))
    //     setsearchValue('')
    // }

    // function handleModalRowClick(data, event) {
    //     event.stopPropagation()
    //     navigate('/dashboard/orders/orderdetails', { state: { id: data?.orderId } })

    // }

    useEffect(() => {
        dispatch(contractorTransactions({ resId }))
        // .then(response => {
        //     if (response?.payload?.data) {
        //         // sessionStorage.removeItem('resDR');
        //     }
        // });
    }, [resId, dispatch])


    let data = transactions?.contractorTrans?.data
    // console.log(transactions?.restaurantTrans?.data);

    const columns = [
        {
            id: 'id',
            name: t('common.id'),
            cell: row => <span>{row?.id}</span>,
            sortable: true,
        },

        {
            id: 'branchId',
            name: t('deliveryContractors.transactions.branchId'),
            cell: row => row?.branchId,
            sortable: true,
        },
        {
            id: 'branchName',
            name: t('deliveryContractors.transactions.branchName'),
            cell: row => row?.branchName,
            sortable: false,
        },
        {
            id: 'branchAddress',
            name: t('deliveryContractors.transactions.branchAddress'),
            cell: row => row?.branchAddress,
            sortable: false,
        },
        {
            id: 'customerName',
            name: t('deliveryContractors.transactions.customerName'),
            cell: row => row?.customerName,
            sortable: false,
        },
        {
            id: 'customerAddress',
            name: t('deliveryContractors.transactions.customerAddress'),
            cell: row => row?.customerAddress,
            sortable: false,
        },
        {
            id: 'courierName',
            name: t('deliveryContractors.transactions.courierName'),
            cell: row => row?.courierName,
            sortable: false,
        },
        {
            id: 'createdAt',
            name: t('deliveryContractors.transactions.createdAt'),
            cell: row => formatDateToUserTimezone(row?.createdAt),
            sortable: true,
        },
        {
            id: 'deliveredAt',
            name: t('deliveryContractors.transactions.deliveredAt'),
            cell: row => formatDateToUserTimezone(row?.deliveredAt),
            sortable: true,
        },
        {
            id: 'finalPrice',
            name: t('deliveryContractors.transactions.finalPrice'),
            cell: row => row?.finalPrice,
            sortable: true,
        },
        {
            id: 'status',
            name: t('deliveryContractors.transactions.status'),
            cell: row => <span data-tag="allowRowEvents" className={`capitalize bg-opacity-10 font-semibold px-2 py-1 rounded-md min-w-[110px] text-center block text-[15px] ${row.status === 'Out_for_delivery' ? 'text-mainYellow bg-mainYellow' : row.status === 'Ready_for_pickup' ? 'text-[#a2c948] bg-[#a2c948]' : row?.status === 'Confirmed' ? 'text-mainGreen bg-mainGreen' : row?.status === 'In_the_kitchen' ? 'text-mainRed bg-mainRed' : row?.status === 'Delivered' ? 'text-mainColor bg-mainColor' : row?.status === 'Abandoned' ? 'text-[#b45757] bg-[#b45757]' : row?.status === 'New' ? 'text-[#d6cc7e] bg-[#d6cc7e]' : row?.status === 'Cancelled' ? 'text-[#474747] bg-[#474747]' : row?.status === 'Picked_up' ? 'text-[#8ce4f0] bg-[#8ce4f0]' : row?.status === 'Rejected' ? 'text-[#bd5796] bg-[#bd5796]' : row?.status === 'Submitted' ? 'text-[#76a0bd] bg-[#76a0bd]' : ''}`}>
                {
                    row.status === 'Out_for_delivery' ? t('common.outForDelivery') :
                        row.status === 'Confirmed' ? t('common.confirmed') :
                            row.status === 'In_the_kitchen' ? t('common.inTheKitchen') :
                                row.status === 'Delivered' ? t('common.delivered') :
                                    row.status === 'Abandoned' ? t('common.abandoned') :
                                        row.status === 'New' ? t('common.new') :
                                            row.status === 'Cancelled' ? t('common.cancelled') :
                                                row.status === 'Picked_up' ? t('common.pickedup') :
                                                    row.status === 'Rejected' ? t('common.rejected') :
                                                        row.status === 'Ready_for_pickup' ? t('common.redyForPickUp') :
                                                            row.status === 'Submitted' ? t('common.submitted') : null

                }
            </span>,
            sortable: false,
        },

        // {
        //     id: 'actions',
        //     name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
        //     allowOverflow: true,
        //     button: true,
        //     cell: row => (
        //         <>
        //             {<ActionCell data={row} t={t} />}
        //         </>
        //     )
        // },
    ];

    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['id', 'branchId', 'branchName', 'customerName', 'courierName', 'createdAt', 'status']);

    const [openColumn, setopenColumn] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = () => {
        setopenColumn(true)
    }
    const handleClose = () => {
        setopenColumn(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
        // sessionStorage.setItem('columns', JSON.stringify(newVisibleColumns));
    }, []);

    useEffect(() => {
        setSelectedColumns(['id', 'branchId', 'branchName', 'customerName', 'courierName', 'createdAt', 'status']);
        // sessionStorage.setItem('columns', JSON.stringify(columns));
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
        // sessionStorage.setItem('columns', JSON.stringify(columns.filter((column) => selectedColumns.includes(column.id))));

    }, [selectedColumns]);




    // filter change

    // const handleTypeChange = (event) => {
    //     let courierType = event.target.value
    //     // console.log(event.target);
    //     // settype({id:val});
    //     setorderType(courierType);
    //     dispatch(resetSearch());
    //     dispatch(resetSort({ sort: true }))
    //     dispatch(resetPage())

    //     // dispatch(getCouriers({page,size,courierType,searchValue}))
    // };


    // date range filter
    const [selectedDate, setSelectedDate] = useState(null); // for storing the selected date from date picker
    const [date, setdate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const handleDateRangeChange = async (e) => {
        let newPage = 1
        setpage(1)
        setsize(10)
        setdate([e.selection])
        let dateRange = e?.selection
        let fromDate = dayjs(dateRange?.startDate).utc().format('YYYY-MM-DDTHH:mm:ss')
        let toDate = dayjs(dateRange?.endDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
        let selectedDate = { fromDate: fromDate, toDate: toDate }

        setSelectedDate(selectedDate)
        await dispatch(contractorTransactions({ page: newPage, size, resId, selectedDate })).then(() => {
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
            dispatch(resetPage())
        })
        // dispatch(getRatingSummary(selectedDate))
    }

    const { dateOverlay } = useSelector(state => state.overlay)

    const handleButton = () => {
        dispatch(openHandler({ dateShow: !dateOverlay }))
    }

    const placeHolder = t('placeholder.searchTransactionsPlaceholder')
    const { searchValue, searchInput } = useSearchBox(contractorTransactions, placeHolder)
    const { handleRemoteSort, icon } = useRemoteSort(contractorTransactions, dispatch, page, size, searchValue, orderType, selectedDate, resId)

    const { PDFDocument } = usePDFGenerator({ columns: visibleColumns, data: data || [], date, name: "Contractor Transaction Report", type: "contractor", owed: transactions?.contractorTrans?.contractorOwed, owes: transactions?.contractorTrans?.contractorOwes })
    // const { generateCsvReport } = useCSV({ columns: visibleColumns, data: data || [], date, name: "Contractior CSV Report" })
    const { generateXlsxReport } = useXLSX({ columns: visibleColumns, data: data || [], name: 'Contractor xlsx Report', owed: transactions?.contractorTrans?.contractorOwed, owes: transactions?.contractorTrans?.contractorOwes });


    // for PDF report generator
    const generatePdfReport = () => {

        return (
            <PDFDownloadLink document={<PDFDocument />} fileName="Contractior Transactions Report">
                <Button className="flex items-center gap-x-1 py-2 px-4">
                    <BsFiletypePdf className="text-mainRed text-xl" />
                    <span className="text-sm capitalize text-[#999]">
                        PDF File
                    </span>
                </Button>
            </PDFDownloadLink>
        )
    }

    // for CSV report generator
    // const generateCsvReport = () => {
    //     // this to display all columns in CSV
    //     // let headers = data && Object?.keys(data?.[0])?.map(header => ({ label: header.replace(/([A-Z])/g, ' $1').toLowerCase(), key: header }));

    //     let selectedHeaders = visibleColumns?.map(column => ({ label: column?.name, key: column?.id }))

    //     const newData = data?.map((item) => selectedHeaders?.reduce((acc, { key }) => {
    //         if (item?.hasOwnProperty(key)) {
    //             acc[key] = item[key];
    //         }
    //         return acc;
    //     }, {}))




    //     return (
    //         <IconButton>
    //             {data ?
    //                 <CSVLink data={newData} filename="Restaurant Transactions Report" headers={selectedHeaders} separator=";">
    //                     <BsFiletypeCsv className="text-mainGreen" />
    //                 </CSVLink>

    //                 : <BsFiletypeCsv className="text-mainGreen" />
    //             }
    //         </IconButton>

    //     )
    // }


    // for generating reports menu

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleFilterOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterClose = () => {
        setAnchorEl(null);
    };


    // console.log(transactions?.contractorTrans?.data);

    return (
        <>
            <Box dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                {/* <PDFViewer className="w-full min-h-[400px]">
                    <PDFDocument />
                </PDFViewer> */}
                <h2 className="text-xl capitalize font-semibold text-dark">{t('deliveryContractors.transactions.title') + " #" + resId}</h2>
                <div className={`flex justify-between mt-2 ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <div className="text-base capitalize font-semibold">
                        <p className="text-mainGreen">{t('deliveryContractors.transactions.contractorOwed')}: {transactions?.contractorTrans?.contractorOwed} {t('common.sar')}</p>
                        <p className="text-mainRed">{t('deliveryContractors.transactions.contractorOwes')}: {transactions?.contractorTrans?.contractorOwes} {t('common.sar')}</p>
                    </div>
                    <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={openColumn} close={handleClose} />
                </div>

                <div className={`restaurant-transactions-filter-wrapper mt-4 flex justify-between ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center gap-x-3`}>
                    <div className="status-type flex items-center gap-x-2">
                        {/* <section className="search-section-wrapper">
                            <FormControl className="capitalize" sx={{ minWidth: 175, border: 0, m: 0, borderRadius: '6px', backgroundColor: 'white', '& fieldset': { borderColor: 'primary.main', border: 'none' } }} size="small">
                                <Select
                                    labelId="select-courier-type"
                                    id="demo-order-select-type"
                                    value={orderType}
                                    // label="courier type"
                                    onChange={handleTypeChange}
                                    displayEmpty
                                >
                                    <MenuItem value={''} className='text-[#333] font-semibold font-playfair'>{t('couriers.courierTransactions')}</MenuItem>
                                    <MenuItem value="all" className='text-[#333] font-semibold font-playfair' placeholder='All'>{t('common.all')}</MenuItem>
                                    <MenuItem value="COMPLETED" className='text-[#333] font-semibold font-playfair' placeholder='Completed'>{t('common.completed')}</MenuItem>
                                    <MenuItem value="PENDING" className='text-[#333] font-semibold font-playfair' placeholder='Pending'>{t('common.pending')}</MenuItem>
                                    <MenuItem value="CANCELLED" className='text-[#333] font-semibold font-playfair' placeholder='Cancelled'>{t('common.cancelled')}</MenuItem>
                                    <MenuItem value="ON_HOLD" className='text-[#333] font-semibold font-playfair' placeholder='On Hold'>{t('common.onHold')}</MenuItem>
                                </Select>
                            </FormControl>
                        </section> */}

                        <section className='date-wrapper relative'>
                            <Button className='bg-white text-[#333] font-semibold rounded-md py-[10px]' onClick={handleButton}>
                                {/* <button className='flex items-center text-[#333] font-semibold font-playfair capitalize text-sm'> {date[0]?.startDate && date[0]?.endDate ? new Date(fromDate.getTime() + (fromDate?.getTimezoneOffset() * -60000)).toISOString().slice(0, 10) + " - " + new Date(toDate.getTime() + (toDate?.getTimezoneOffset() * -60000)).toISOString().slice(0, 10) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></button> */}
                                <button className='flex items-center text-[#333] font-semibold font-playfair capitalize text-sm'> {date[0]?.startDate && date[0]?.endDate ? formatDateToUserTimezone(dayjs(date[0]?.startDate)) + " - " + formatDateToUserTimezone(dayjs(date[0]?.endDate)) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></button>
                            </Button>

                            {dateOverlay ?

                                <div className={`absolute top-full z-[9999] bg-white ${localStorage?.getItem('lang') === 'ar' ? 'left-full' : ''}`} dir='ltr'>
                                    <DateRangePicker
                                        onChange={handleDateRangeChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={date}
                                        direction="vertical"
                                        editableDateInputs={true}
                                    />
                                </div> : null}

                        </section>
                    </div>

                    <div className="search-container">
                        {searchInput()}
                    </div>

                </div>
                <section className="datatable-wrapper mt-5 bg-white relative p-2">

                    <div className={`gen-reports-actions flex justify-end items-center gap-x-1 ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        <div>
                            <Button
                                id="filtr-button"
                                aria-controls={open ? 'filter-button' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleFilterOpen}
                                className=" disabled:pointer-events-auto disabled:bg-opacity-30 disabled:cursor-not-allowed flex items-center gap-x-1 text-blue-100 bg-opacity-80 bg-blue-900 capitalize"
                                disabled={!data || data?.length === 0}
                            >
                                <TbReport className="text-xl" /> {t('common.generateReport')}
                            </Button>
                            <Menu
                                id="filter-menu"
                                aria-labelledby="filter-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleFilterClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}

                                sx={
                                    { '.MuiMenuItem-root': { padding: 0 } }
                                }
                            >
                                <MenuItem onClick={handleClose} className="mb-2">
                                    {generatePdfReport()}
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    {generateXlsxReport()}
                                </MenuItem>
                            </Menu>

                        </div>

                        {/* <Button onClick={test}>Test</Button> */}
                    </div>

                    {data?.length === 0 ?
                        <div className='p-8'><NoDataEmoji /></div>
                        :

                        <>
                            <DataTable
                                key={resId}
                                direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                                columns={visibleColumns}
                                data={data}
                                pagination
                                paginationPerPage={size}
                                paginationRowsPerPageOptions={[10, 20, 50, transactions?.contractorTrans?.meta?.total]}
                                paginationServer
                                paginationTotalRows={transactions?.contractorTrans?.meta?.total}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleRowChange}
                                customStyles={TableStyles}
                                highlightOnHover
                                sortServer
                                sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                                onSort={handleRemoteSort}
                                paginationDefaultPage={page}
                                // onRowClicked={(data, event) => {

                                //     handleModalRowClick(data, event)
                                // }}
                                keyField="contractors-transactions-id"
                                paginationResetDefaultPage={true}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: t('common.paginationRowText'),
                                        rangeSeparatorText: t('common.of')
                                    }
                                }
                            />
                        </>
                    }

                    {transactions?.loading ?
                        <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                            <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                        </div> : null}
                </section>

            </Box>
        </>
    );
}

export default ContractorTransactions;