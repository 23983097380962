import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getCoupons = createAsyncThunk(
  "CouponsFunc",
  async (
    { page, size, col, dir, searchValue },
    { rejectWithValue, dispatch }
  ) => {
    // console.log(page,size);
    let colCheck;

    if (col) {
      colCheck =
        col === "Created At" || col === "تتاريخ الانشاء"
          ? "created_at"
          : col === "Code" || col === "الكود"
          ? "code"
          : col === "value" || col === "القيمة"
          ? "value"
          : col === "Expire Date" || col === "تاريخ الانتهاء"
          ? "expiry_date"
          : col === "Max Total Redemptions" ||
            col === "الحد الأقصى لإجمالي عمليات الاسترداد"
          ? "max_total_redemptions"
          : col === "Max Amount" || col === "اقصي قيمة"
          ? "max_amount"
          : col === "Max Redemption Per User" ||
            col === "الحد الأقصى للاسترداد لكل مستخدم"
          ? "max_redemptions_per_user"
          : col === "Discount Type" || col === "نوع الخصم"
          ? "discount_type"
          : col.replace(/\s+/g, "").toLowerCase();
    }

    const url = !col
      ? `/coupons?page=${page ? page : 1}&size=${size ? size : 10}${
          searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""
        }`
      : `/coupons?page=${page ? page : 1}&size=${size ? size : 10}${
          searchValue ? `&search=${encodeURIComponent(searchValue)}` : ""
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addCoupons = createAsyncThunk(
  "addCouponsFunc",
  async (values, { rejectWithValue, dispatch }) => {
    // console.log(page,size);

    const url = `/coupons?${`lang=${localStorage.getItem("lang")}`}`;
    try {
      const res = await toast.promise(axios.post(url, values), {
        pending: "Loading...",
        success: "Coupon Added Successfully",
      });
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editCoupons = createAsyncThunk(
  "editCouponsFunc",
  async ({ values, id }, { rejectWithValue, dispatch }) => {
    // console.log(page,size);

    const url = `/coupons/${id}`;
    try {
      const res = await toast.promise(axios.put(url, values), {
        pending: "Loading...",
        success: "Coupon Updated Successfully",
      });
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const toggleCoupon = createAsyncThunk(
  "toggleCouponsFunc",
  async ({ status, id }, { rejectWithValue, dispatch }) => {
    // console.log(status);

    try {
      const res = await toast.promise(
        axios.put(`/coupons/${status}`, { id: id }),
        {
          pending: "Loading....",
          success: `Coupon ${status}d Successfully 👌`,
          // error: 'Promise rejected 🤯'
        },
        { toastId: "CouponStatusToast" }
      );
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  coupons: [],
  couponsActive: [],
  couponsDeactivated: [],
  error: null,
};
export const CouponsSlice = createSlice({
  name: "CouponsSlice-Func",
  initialState,
  reducers: {
    clearCourierState: (state, action) => {
      state.singleCourier = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCoupons.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.coupons = action.payload;
        // console.log(action.payload);
      })

      .addCase(getCoupons.rejected, (state, action) => {
        state.loading = false;
        state.coupons = "";
        state.error = action.payload;
      })

      .addCase(addCoupons.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(addCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(addCoupons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(editCoupons.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(editCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        // console.log(action.payload);
      })

      .addCase(editCoupons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCourierState } = CouponsSlice.actions;

export default CouponsSlice.reducer;
