import { useEffect, useState } from "react";
import { FaAngleRight } from 'react-icons/fa'
import '../../../../Assets/style/style.scss'
import { ListItemButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { openHandler } from "../../../Redux/Slice/common/overlaySlice";
import { getPartnerStatistics } from "../../../Redux/Slice/Report/report";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NumberCounter from "../../../Common/counterEffect";
const OrderSummary = () => {
    const { t, i18n } = useTranslation()

    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch()

    const { report } = useSelector(state => state)

    useEffect(() => {
        let dateRange = 'last_7_days'
        dispatch(getPartnerStatistics({ dateRange }))
    }, [dispatch])

    // console.log(report?.partnerStatistics);

    const btns = [
        // {label:'today', param : 'today'},
        { label: 'last week', labelAr: "اخر أسبوع", param: 'last_7_days' },
        { label: 'last month', labelAr: "اخر شهر", param: 'last_30_days' },
        { label: 'last 5 months', labelAr: "اخر 5 أشهر", param: 'last_5_months' },
    ]

    const filterClickHandler = (index, item) => {
        setActiveTab(index)
        let dateRange = item?.param
        dispatch(getPartnerStatistics({ dateRange }))
    }

    // const {orderNotify} = useSelector(state=>state.overlay)
    // const showOrdersHandler = ()=>{
    //     // setshowOrders(!showOrders)
    //     dispatch(openHandler({orderNotification:true}))
    // }

    const statusAnalytics = [
        { id: 1, name: 'on delivery', nameAr: 'قيد التوصيل', count: report?.partnerStatistics?.data?.outForDeliveryOrders, color: '#5CAC7D' },
        { id: 2, name: 'delivered', nameAr: 'تم التوصيل', count: report?.partnerStatistics?.data?.deliveredOrders, color: '#442B7E' },
        { id: 3, name: 'canceled', nameAr: 'تم الالغاء', count: report?.partnerStatistics?.data?.cancelledOrders, color: '#C6345C' },
    ]


    return (
        <article className="order-summary bg-white pr-4 py-3 rounded-md shadow-sm h-full">
            <section className="order-summary-title flex justify-between items-center px-4 pr-0">
                <div>
                    <h2 className='font-bold text-lg lg:text-base text-[#464E5F] capitalize'>{t("dashboard.order_summary.title")}</h2>
                    <p className="font-medium text-[12px] text-[#B5B5C3]">{report?.partnerStatistics?.data?.totalOrders} {t("dashboard.order_summary.order")}</p>
                </div>

                <div className="px-1 py-1 bg-[#F4F6FC] rounded-sm">
                    {btns?.map((item, index) => {
                        return (
                            <button key={index} className={`capitalize text-xs font-bold text-[#999999] px-2 py-2 lg:px-2 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-white text-black' : null}`}
                                onClick={() => filterClickHandler(index, item)}
                            >
                                {localStorage?.getItem('lang') === 'ar' ? item.labelAr : item.label}
                            </button>
                        )
                    })}
                </div>
            </section>

            <section className="manage-order mt-8 w-full px-4 pr-0">
                <div className="flex justify-between items-center w-full p-2 py-4 bg-[#e3f1e9] rounded-md">
                    <div className="left-section flex capitalize">
                        <div className="counter">
                            <span className={`px-6 py-2 bg-mainGreen rounded-md text-white font-bold ${localStorage?.getItem('lang') === 'ar' ? 'ml-3' : 'mr-3'} `}>{report?.partnerStatistics?.data?.inTheKitchenOrders + report?.partnerStatistics?.data?.submittedOrders}</span>
                        </div>
                        <div className={`${localStorage?.getItem('lang') === 'ar' ? 'left-sec-title-ar' : 'left-sec-title'}  capitalize text-[#3F4254] font-semibold`}>
                            {t("dashboard.order_summary.new_order")}
                        </div>
                    </div>

                    <div className="right-section relative text-[#442B7E] font-semibold">
                        {/* <ListItemButton className="flex items-center capitalize" onClick={showOrdersHandler}>manage orders <FaAngleRight /> </ListItemButton> */}
                        <Link to='/dashboard/orders'><ListItemButton className={`flex items-center capitalize ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} `}>{t("dashboard.order_summary.manage_order")} <FaAngleRight /> </ListItemButton></Link>

                        {/* {orderNotify ? <div className="orders-notify absolute -top-24 left-[80%] z-[9999] bg-white min-w-[350px] max-h-[400px] h-[400px] overflow-hidden overflow-y-scroll p-3 shadow-md rounded-md">
                            {[...Array(10)]?.map(()=> <OrderNotification /> )}
                        </div> : null} */}
                    </div>
                </div>
            </section>

            <section className="order-status-analytics-wrapper px-4 pr-0 mt-12 overflow-hidden overflow-y-auto max-h-[150px]">
                <div className="flex flex-wrap justify-between gap-5 [&>div]:grow">
                    {statusAnalytics?.map(item => (
                        <div className="bg-[#F3F6F9] p-6 rounded-md" key={item.id}>
                            <p style={{ color: item.color }} className="text-3xl mb-3 font-semibold"><NumberCounter startNumber={0} endNumber={item.count} duration={5000} /></p>
                            <p className="text-[#999999] text-sm capitalize">{localStorage?.getItem('lang') === 'ar' ? item.nameAr : item.name}</p>
                        </div>
                    ))}
                </div>
            </section>

        </article>
    );
}
export default OrderSummary;