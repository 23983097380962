import { Box, Button, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { deleteCourier, getCouriers } from "../../../Components/Redux/Slice/Couriers/couriersSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Overlay from "../../../Components/Common/overlay";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: 'auto',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 2,
    p: 4,
    borderRadius: 5,
};

const DeleteCourier = ({ open, close, id }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const form = useForm()
    const { register, handleSubmit, formState, reset } = form
    const { errors } = formState
    const couriers = useSelector(state => state?.couriers)

    const closeModal = () => {
        close()
        reset()
    }

    const deleteCourierSubmitHandler = async (data) => {
        const page = 1,
            size = 10

        let values = {
            id: id,
            ...data
        }
        dispatch(deleteCourier(values)).then((e) => {
            console.log(e);

            if (e?.payload?.message === 'SUCCESS') {
                dispatch(getCouriers({ page, size }))
                closeModal()
                dispatch(resetSearch());
                dispatch(resetSort({ sort: true }))
                dispatch(resetPage())
            }
        })
    }

    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                    {/* <h2>{t('couriers.courier_details.deleteMessage')}</h2> */}
                    <form onSubmit={handleSubmit(deleteCourierSubmitHandler)}
                        className="[&>div>label]:font-semibold [&>div>label]:capitalize [&>div>label]:text-lg [&>div>label]:block [&>div>label]:text-mainColor [&>div>label]:mb-4
                                [&>div>textarea]:w-full [&>div>textarea]:shadow-sm [&>div>textarea]:resize-none [&>div>textarea]:h-[120px] [&>div>textarea]:border [&>div>textarea]:rounded-md [&>div>textarea]:p-2 [&>div>textarea]:text-mainColor"
                    >
                        <div>
                            <label>{t('couriers.courier_details.deletionReason')}</label>

                            <textarea
                                className="placeholder:capitalize"
                                // placeholder={t('couriers.courier_details.deletionReason')}
                                {...register('deletationReason', {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9\s]+$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام و المسافات فقط' : 'Only English letters, numbers, and spaces are allowed'
                                    },
                                })}
                            >

                            </textarea>

                            {errors?.deletationReason?.message ? <p className="capitalize text-sm text-danger">{errors?.deletationReason?.message}</p> : null}
                        </div>

                        <div className="mt-4">
                            <label>{t('couriers.courier_details.deletionReasonAr')}</label>

                            <textarea
                                className="placeholder:capitalize"
                                // placeholder={t('couriers.courier_details.deactivateReasonAr')}
                                {...register('deletationReasonAr', {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    },
                                    pattern: {
                                        value: /^[\u0621-\u064A\u0660-\u0669\u0030-\u0039\s]+$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والارقام ' : 'Only Arabic letters, numbers, spaces, are allowed'
                                    },
                                })}
                            >

                            </textarea>

                            {errors?.deletationReasonAr?.message ? <p className="capitalize text-sm text-danger">{errors?.deletationReasonAr?.message}</p> : null}
                        </div>

                        <div className="mt-6 flex items-center justify-around [&>button]:capitalize [&>button]:font-semibold [&>button]:px-6">
                            <Button onClick={closeModal} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  bg-transparent text-mainRed border border-solid border-mainRed">{t('common.cancel_btn')}</Button>
                            <Button type="submit" disabled={couriers?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  bg-mainColor text-white">{t('common.confirm')}</Button>
                        </div>
                    </form>
                    {couriers?.loading ? <Overlay /> : null}
                </Box>

            </Modal>
        </>
    );
}

export default DeleteCourier;