import { CgSpinnerTwoAlt } from "react-icons/cg";
import { Box, Button, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getContractors, toggleContractor } from "../../../Components/Redux/Slice/DeliveryContractors/deliveryContractor";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";
import { useEffect } from "react"

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 350,
    maxWidth: "900px",
    width: "100%",
    bgcolor: 'background.paper',
    border: '1px solid #ddd',
    boxShadow: 24,
    px: 2,
    py: 2,
    borderRadius: '10px'
};

const WarningProviderModal = ({ open, close, warnType, data }) => {
    const form = useForm()
    const { register, handleSubmit, formState, reset } = form
    const { errors } = formState

    let id = data?.id
    const { t, i18n } = useTranslation()
    const deliveryContractor = useSelector(state => state?.deliveryContractor)
    const dispatch = useDispatch()

    const closeModal = () => {
        close()
        reset()
    }

    useEffect(() => {
        reset({
            reason: "",
            reasonAr: ""
        })
    }, [reset, warnType])


    const page = 1,
        size = 10
    const toggleProviderHandler = async (data) => {
        let values = {
            id: id,
            ...data
        }

        await dispatch(toggleContractor({ values, warnType })).then((e) => {
            if (e?.payload?.message === 'SUCCESS') {
                closeModal()
                dispatch(getContractors({ page, size }))
                dispatch(resetSearch());
                dispatch(resetSort({ sort: true }))
                dispatch(resetPage())
            }
        })

    }
    return (
        <>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-warning"
                aria-describedby="modal-warning-delete"
            >
                <Box
                    sx={style}
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                >
                    <div className="warnning-modal-wrapper">
                        <div className="flex justify-end items-center">
                            <div>
                                <Button color="error" className="text-2xl -mt-2" onClick={close}><IoCloseCircleOutline /></Button>
                            </div>
                        </div>

                        {/* <div className={`capitalize text-center w-full font-semibold ${(!data?.isActive && warnType !== 'delete') ? 'text-mainGreen' : 'text-mainRed'} text-lg mt-4`}>
                            <h2>{warnType === 'deactivate' ? t('deliveryContractors.warningModal.deactivate_title') : warnType === 'reactivate' ? t('deliveryContractors.warningModal.reactivate_title') : warnType === 'delete' ? t('deliveryContractors.warningModal.delete_title') : null }</h2>
                        </div> */}

                        <form onSubmit={handleSubmit(toggleProviderHandler)}
                            className="[&>div>label]:font-semibold [&>div>label]:capitalize [&>div>label]:text-lg [&>div>label]:block [&>div>label]:text-mainColor [&>div>label]:mb-4
                                    [&>div>textarea]:w-full [&>div>textarea]:shadow-sm [&>div>textarea]:resize-none [&>div>textarea]:h-[120px] [&>div>textarea]:border [&>div>textarea]:rounded-md [&>div>textarea]:p-2 [&>div>textarea]:text-mainColor"
                        >
                            <>
                                <div>
                                    {warnType === 'delete' ? <label>{t('couriers.courier_details.deletionReason')}</label> : <label>{t('couriers.courier_details.deactivateReason')}</label>}


                                    <textarea
                                        className="placeholder:capitalize"
                                        // placeholder={t('couriers.courier_details.deletionReason')}
                                        {...register('reason', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            pattern: {
                                                value: /^[a-zA-Z0-9\s]+$/,
                                                message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام و المسافات فقط' : 'Only English letters, numbers, and spaces are allowed'
                                            },
                                        })}
                                    >

                                    </textarea>

                                    {errors?.reason?.message ? <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p> : null}
                                </div>

                                <div className="mt-4">
                                    {warnType === 'delete' ? <label>{t('couriers.courier_details.deletionReasonAr')}</label> : <label>{t('couriers.courier_details.deactivateReasonAr')}</label>}

                                    <textarea
                                        className="placeholder:capitalize"
                                        // placeholder={t('couriers.courier_details.deactivateReasonAr')}
                                        {...register('reasonAr', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            pattern: {
                                                value: /^[\u0621-\u064A\u0660-\u0669\u0030-\u0039\s]+$/,
                                                message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والارقام ' : 'Only Arabic letters, numbers, spaces, are allowed'
                                            },
                                        })}
                                    >

                                    </textarea>

                                    {errors?.reasonAr?.message ? <p className="capitalize text-sm text-danger">{errors?.reasonAr?.message}</p> : null}
                                </div>
                            </>


                            <div className={`flex gap-x-7 items-center mt-8 w-full justify-end [&>button]:capitalize  ${localStorage.getItem('lang') === 'ar' ? 'pl-4 flex-row-reverse' : 'pr-4'} `}>
                                <Button onClick={closeModal} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  bg-transparent text-mainRed border border-solid border-mainRed">{t('common.cancel_btn')}</Button>
                                {warnType === 'deactivate' ?
                                    <Button type="submit" disabled={deliveryContractor?.loading} className="bg-mainRed text-mainRed bg-opacity-30 disabled:bg-opacity-40 disabled:pointer-events-auto disabled:cursor-not-allowed min-w-[100px]" >{deliveryContractor?.loading ? <span className="animate-spin text-xl"><CgSpinnerTwoAlt /></span> : t('deliveryContractors.deactivate_provider')}</Button>
                                    :
                                    // warnType === 'reactivate' ?
                                    //     <Button type="submit" disabled={deliveryContractor?.loading} className="bg-mainGreen text-mainGreen bg-opacity-30 disabled:bg-opacity-40 disabled:pointer-events-auto disabled:cursor-not-allowed min-w-[100px]" >{deliveryContractor?.loading ? <span className="animate-spin text-xl"><CgSpinnerTwoAlt /></span> : t('deliveryContractors.reactivate_provider')}</Button>
                                    //     :
                                    warnType === 'delete' ?
                                        <Button type="submit" disabled={deliveryContractor?.loading} className="bg-mainRed text-mainRed bg-opacity-30 disabled:bg-opacity-40 disabled:pointer-events-auto disabled:cursor-not-allowed" >{deliveryContractor?.loading ? <span className="animate-spin"><CgSpinnerTwoAlt /></span> : t('deliveryContractors.delete_provider')}</Button>
                                        :
                                        null
                                }

                            </div>
                        </form>


                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default WarningProviderModal;