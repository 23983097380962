import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRemoteSort } from "../../Components/Common/sortHook";
import { useDispatch, useSelector } from "react-redux";
import ColumnSelection from "../../Components/Common/columnSelection";
import NoDataEmoji from "../../Components/Common/nodataEmoje";
import DataTable from "react-data-table-component";
import { TableStyles } from "../../Components/Common/tableStyle";
import { BsDot } from "react-icons/bs";
import { formatDateToUserTimezone } from "../../Components/Common/dateFormate";
import { useSearchBox } from "../../Components/Common/searchHook";
import { getBanks } from "../../Components/Redux/Slice/Banks/banks";
import { Button, Menu, MenuItem } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import AddBanks from "./Bank-Components/addBankModal";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import EditBanks from "./Bank-Components/editBankModal";

function ActionCell({ data, t }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    // open edit bank modal

    const [openEditBank, setopenEditBank] = useState(false)

    const handleOpenEditBank = () => {
        setopenEditBank(true)
    }

    const handleCloseEditBank = () => {
        setopenEditBank(false)
    }

    return (
        <div className='action-wrapper relative' key={data?.id}>
            <Button className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></Button>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none', padding: 2 } }}
                className='shadow-md p-0'
                sx={{ '& .MuiList-padding': { p: 0 } }}
            >
                <div className='[&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    <MenuItem onClick={handleOpenEditBank} className='text-mainColor hover:bg-[#442b7e42]' ><FaEdit />{t('common.edit')}</MenuItem>
                </div>
            </Menu>

            <EditBanks open={openEditBank} close={handleCloseEditBank} data={data} />
        </div>
    );
}
const Banks = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // const [searchValue, setsearchValue] = useState()
    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)

    const { currentPage, toggle, sort } = useSelector((state) => state?.resetPagination);

    const banks = useSelector(state => state.banks)

    const handlePageChange = page => {
        setpage(page);
        // console.log(page);
    };

    const handleRowSizeChange = rows => {
        setsize(rows);
    };

    // useEffect(() => {
    //     dispatch(getPayments({ page, size }))
    // }, [dispatch])

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    // useEffect(() => {
    //     setsearchValue('')
    // }, [search])

    // useEffect(() => {
    //     //   console.log(searchValue);
    // }, [searchValue])

    const columns = [
        // {
        //     id: 'id',
        //     name: 'ID',
        //     selector: row => row.id,
        //     sortable: false,
        //     width: 'auto',
        //     maxWidth: "80px"
        // },
        {
            id: 'name',
            name: t('banks.bankName'),
            selector: row => row.name,
            sortable: false,
            // width: 'auto',
            // maxWidth: "100px"
        },
        {
            id: 'nameAr',
            name: t('banks.bankNameAr'),
            selector: row => row.nameAr,
            sortable: false,
        },

        {
            id: 'createdAt',
            name: t('banks.createdAt'),
            selector: row => formatDateToUserTimezone(row.createdAt),
            sortable: false,
        },

        {
            id: 'actions',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
            allowOverflow: true,
            button: true,
            cell: row => (
                <>
                    {<ActionCell data={row} t={t} key={row?.id} />}
                </>
            )
        },
    ];

    const data = banks?.banks?.data

    // console.log(banks);

    // const handleSearch = () => {
    //     // dispatch(getPayments({ searchValue })).then(() => dispatch(resetPage()))
    // }

    // const handleReset = () => {
    //     // dispatch(getPayments({ page, size })).then(() => dispatch(resetPage()))
    //     setsearchValue('')
    // }


    const placeHolder = t('placeholder.searchBanksPlaceholder')
    const { searchValue, searchInput } = useSearchBox(getBanks, placeHolder)
    const { handleRemoteSort, icon } = useRemoteSort(getBanks, dispatch, page, size, searchValue)


    const [selectedColumns, setSelectedColumns] = useState(['name', 'nameAr', 'createdAt', 'actions']);

    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = () => {
        setopen(true)
    }
    const handleClose = () => {
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['name', 'nameAr', 'createdAt', 'actions']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);



    // add bank modal

    const [bankModalOpen, setbankModalOpen] = useState(false)

    const handleOpenBankModal = () => {
        setbankModalOpen(true)
    }
    const handleCloseBankModal = () => {
        setbankModalOpen(false)
    }
    return (
        <div className="banks-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('banks.title')}</h1>
            </section>

            <section className={`banks-actions-wrapper flex ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center gap-x-3 justify-between`}>
                {searchInput()}

                <div className="flex items-center gap-3">
                    <Button onClick={handleOpenBankModal} className={`capitalize text-white bg-mainGreen rounded-md py-1 flex justify-between gap-x-2 min-w-[110px]`}> <IoMdAddCircle className={`w-8 h-8 rounded-[50%] ${localStorage.getItem('lang')} ? 'ml-2' : 'mr-2' `} />{t('banks.addBank')}</Button>
                    <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                </div>

            </section>

            {/* <section className="add-bank-wrapper flex justify-end mt-3">
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white relative">
                {data?.length === 0 ?
                    <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 15, banks?.banks?.meta?.total]}
                            paginationServer
                            paginationTotalRows={banks?.banks?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowSizeChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            sortServer
                            sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                            onSort={handleRemoteSort}
                            paginationDefaultPage={page}
                            keyField="order-table-id"
                            paginationResetDefaultPage={true}
                            // onRowClicked={(data) => navigate('orderdetails', { state: { id: data?.id } })}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: t('common.paginationRowText'),
                                    rangeSeparatorText: t('common.of')
                                }
                            }
                        // expandableRows
                        // expandableRowsComponent={ExpandedComponent}
                        // expandableIcon={{
                        //     collapsed: localStorage.getItem('lang') === "en" ? <FaAngleRight /> : <FaAngleLeft />,
                        //     expanded: <FaAngleDown />,
                        // }}
                        />
                    </>
                }
            </section>

            <AddBanks open={bankModalOpen} close={handleCloseBankModal} />

            {banks?.loading ?
                <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                    <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                </div> : null}
        </div>
    );
}

export default Banks;