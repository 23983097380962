import { CgCalendarDates } from "react-icons/cg";
import { AiOutlineEdit } from "react-icons/ai";
import { Button, IconButton, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import UnsubscribeModal from "./warnModal";
import { getSubPackages } from "../../../../Components/Redux/Slice/Packages/packages";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "../../../../Components/Common/overlay";
import { formatDateToUserTimezone } from "../../../../Components/Common/dateFormate";
import EditSubscribeModal from "./editSubscribeModal";
import NoDataEmoji from "../../../Common/nodataEmoje";

const SubscripedOffers = ({ t }) => {
    const [id, setid] = useState(null)
    const [openModal, setopenModal] = useState(false)
    const handleOpenModal = () => setopenModal(true)
    const handleCloseModal = () => setopenModal(false)
    const dispatch = useDispatch()
    const packages = useSelector(state => state?.packages)


    const [page, setpage] = useState(1)
    const [size, setsize] = useState(12)

    const handlePageChange = (e, page) => {
        setpage(page);
    };
    useEffect(() => {
        dispatch(getSubPackages({ page, size }))
    }, [dispatch, page, size])

    // edit subscription modal
    const [openEdit, setopenEdit] = useState(false)
    const [editData, seteditData] = useState()
    const handleOpenEdit = () => setopenEdit(true)
    const handleCloseEdit = () => setopenEdit(false)


    return (
        <>
            <article className="my-subscribed-packages-wrapper relative  h-[63vh]">
                <section className="subscriped-offers-wrapper grid grid-cols-2 md:grid-cols-3 gap-4 max-h-[520px] overflow-hidden overflow-y-auto">
                    {(packages?.subPackages?.data && packages?.subPackages?.data?.length !== 0) ? packages?.subPackages?.data?.map((card, index) => (
                        <div key={index} className="p-3 rounded-lg bg-white">
                            <div className="title-wrapper flex justify-between items-start">
                                <p className="text-lg text-[#333] capitalize font-semibold">{localStorage.getItem('lang') === 'ar' ? card?.packageNameAr : card?.packageName}</p>
                                <IconButton onClick={() => {
                                    handleOpenEdit()
                                    seteditData(card)
                                }} className="p-1 text-mainColor"><AiOutlineEdit /></IconButton>
                            </div>

                            <p className="text-mainColor font-semibold">{card?.discountValue} {card?.discountType === 'fixed' ? t('common.sar') : '%'}</p>

                            <div className="offer-body-wrapper my-4">
                                <p className="font-semibold mb-2 text-sm text-[#5c5c5c]">{t('dp.sub.period')}</p>
                                <p className="flex items-center gap-x-1 text-[#727272] text-sm"><CgCalendarDates className="text-xl" /> <span>{formatDateToUserTimezone(card?.subscriptionStartDate)}</span> -<span>{formatDateToUserTimezone(card?.subscriptionEndDate)}</span></p>
                            </div>

                            <div>
                                <Button onClick={() => {
                                    handleOpenModal()
                                    setid(card?.id)
                                }} className="w-full capitalize bg-transparent border border-solid border-mainRed text-mainRed text-center hover:bg-mainRed hover:text-white">{t('dp.unsubscripe')}</Button>
                            </div>
                        </div>
                    ))

                        :

                        <div className="flex items-center justify-center col-span-full h-full">
                            <NoDataEmoji />
                        </div>

                    }
                </section>

                <UnsubscribeModal open={openModal} close={handleCloseModal} id={id} t={t} />
                <EditSubscribeModal open={openEdit} close={handleCloseEdit} t={t} data={editData} />
                {packages?.loading ? <Overlay /> : null}
            </article>

            <section className="pagination-wrapper mt-9">
                <Pagination dir='ltr' count={packages?.subPackages?.meta?.totalPages} defaultPage={1} onChange={handlePageChange} />
            </section>
        </>
    );
}

export default SubscripedOffers;