import { BiArchiveOut } from "react-icons/bi";
import { MenuItem } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Button, Menu } from "@mui/material";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import DataTable from "react-data-table-component";
import { TableStyles } from "../../../Components/Common/tableStyle";
import { getArchivedPackages } from "../../../Components/Redux/Slice/Packages/packages";
import ColumnSelection from "../../../Components/Common/columnSelection";
import AddPackageModal from "./addPackage";
import { BsDot } from "react-icons/bs";
import ArchiveWarningModal from "./archiveWarnModal.jsx";
import { formatDateToUserTimezone } from "../../../Components/Common/dateFormate.js";
import { useSearchBox } from "../../../Components/Common/searchHook.jsx";

function ActionCell({ data, t }) {
    // const [openModal, setopenModal] = useState(false)
    // const handelOpen = ()=>setopenModal(true)
    // const handelClose = ()=>setopenModal(false)
    // console.log(data);

    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openModal, setopenModal] = useState(false)
    const handleOpenModal = () => setopenModal(true)
    const handleCloseModal = () => setopenModal(false)
    // console.log(data);
    return (
        <div className='action-wrapper relative'>
            <Button className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></Button>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none', padding: 0 } }}
                className='shadow-md p-0'
            >
                <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    <MenuItem className='text-mainYellow hover:bg-mainYellow hover:bg-opacity-20 capitalize' onClick={handleOpenModal}><BiArchiveOut /><span>{t('common.unarchive')}</span></MenuItem>
                    {/* <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><FaStoreAltSlash />deactivate</MenuItem> */}
                </div>
            </Menu>

            <ArchiveWarningModal open={openModal} close={handleCloseModal} id={data?.id} status={'unarchive'} t={t} />
        </div>
    );
}

const ArchivedPackages = ({ t }) => {
    const dispatch = useDispatch()
    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)
    // const [searchValue, setsearchValue] = useState('')

    const packages = useSelector(state => state?.packages)

    const { currentPage } = useSelector((state) => state?.resetPagination);
    const { toggle, sort } = useSelector((state) => state?.resetPagination);

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    // useEffect(() => {
    //     setsearchValue('')
    // }, [search])

    useEffect(() => {
        dispatch(getArchivedPackages({ page, size }))
    }, [dispatch])

    const handlePageChange = page => {
        setpage(page);
        // console.log(page);
    };

    const handleRowSizeChange = rows => {
        setsize(rows);
    };

    const data = packages?.archivePackages?.data
    // console.log(packages?.archivePackages?.data);

    const columns = [
        {
            id: 'id',
            name: 'ID',
            selector: row => row?.id,
            sortable: true,
            width: '80px'
        },

        {
            id: "name",
            name: t('dp.packageName'),
            cell: row => <span className="capitalize">{row?.packageName}</span>,
            sortable: true,
        },

        {
            id: "nameAr",
            name: t('dp.packageNameAr'),
            cell: row => <span className="capitalize">{row?.packageNameAr}</span>,
            sortable: true,
        },

        // {
        //     id : "pcType",
        //     name: t('dp.packageType'),
        //     cell: row => <span className="capitalize">{localStorage.getItem('lang') === 'ar' ? row?.packageTypeAr?.replace(/_/g,' ') : row?.packageType?.replace(/_/g,' ')}</span>,
        //     sortable: false,
        // },

        {
            id: "discountType",
            name: t('dp.discountType'),
            cell: row => <span className="capitalize">{row?.discountType === 'fixed' ? t('common.fixed') : row?.discountType === 'percentage' ? t('common.percentage') : null}</span>,
            sortable: false,
        },

        {
            id: "discountValue",
            name: t('dp.discountValue'),
            cell: row => <div className="capitalize flex items-center gap-1"><span>{row?.discountValue}</span>  <span>{row?.discountType === 'fixed' ? t('common.sar') : "%"}</span></div>,
            sortable: true,
        },

        {
            id: "archivedAt",
            name: t('dp.archivedAt'),
            cell: row => formatDateToUserTimezone(row?.archivedAt),
            sortable: true,
        },

        {
            id: "actions",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
            allowOverflow: true,
            button: true,
            cell: row => (
                <>
                    {<ActionCell data={row} t={t} />}
                </>
            )

        },
    ];

    // const handleSearch = () => {
    //     dispatch(getArchivedPackages({ searchValue })).then(() => dispatch(resetPage()))
    // }

    // const handleReset = () => {
    //     dispatch(getArchivedPackages({ page, size })).then(() => dispatch(resetPage()))
    //     setsearchValue('')
    // }

    // useEffect(() => {
    //     // console.log(searchValue);
    // }, [searchValue])

    const placeHolder = t('placeholder.searchPackagesPlaceholder')
    const { searchValue, searchInput } = useSearchBox(getArchivedPackages, placeHolder)
    const { handleRemoteSort, icon } = useRemoteSort(getArchivedPackages, dispatch, page, size, searchValue);



    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['id', 'name', 'nameAr', 'discountType', 'discountValue', 'archivedAt', 'actions']);
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = () => {
        setopen(true)
    }
    const handleClose = () => {
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);


    useEffect(() => {
        setSelectedColumns(['id', 'name', 'nameAr', 'discountType', 'discountValue', 'archivedAt', 'actions']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);


    // add package modal

    const [openAdd, setopenAdd] = useState(false)
    const handleOpenAdd = () => setopenAdd(true)
    const handleCloseAdd = () => setopenAdd(false)

    return (
        <article className="dp-data-table">

            <section className="flex items-center justify-between mb-4">

                <div className={`flex gap-x-2 items-center justify-end my-2 `}>
                    {searchInput()}
                    {/* <FormControl
                        className="search-form [&>div>input]:py-[.6rem] w-full"
                        sx={{ width: '35ch', backgroundColor: 'white', borderRadius: '6px', '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': { padding: '10px' }, '& fieldset': { borderColor: 'primary.main', border: 'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            value={searchValue}
                            placeholder={`${localStorage.getItem('lang') === 'ar' ? 'ابحث في الباقات...' : 'Search Packages...'}`}
                            onChange={(e) => {
                                setsearchValue(e.target.value)
                                dispatch(resetPage())
                                if (!e.target.value) {
                                    handleReset();
                                    dispatch(resetPage())
                                }
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="search"
                                        onClick={handleSearch}
                                        edge="start"
                                    >
                                        <CiSearch />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl> */}
                    {/* <Button className='capitalize text-white bg-mainGreen rounded-3xl py-1 px-1 pr-2'> <IoMdAddCircle className='w-8 h-8 m-auto rounded-[50%]' /><Link to='addrestaurant' className='w-full'>add restaurant</Link></Button> */}
                </div>

                <div className="flex items-center gap-x-2">

                    {/* <div className="dp-add-new-dp-wrapper">
                        <Button onClick={handleOpenAdd} className="capitalize ml-auto p-2 text-white bg-mainGreen min-w-[140px] flex items-center gap-x-2 font-semibold"><IoMdAddCircle className="text-xl" />{t('dp.addNewPackage')}</Button>
                    </div> */}

                    <div>
                        <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                    </div>
                </div>

            </section>


            <div className="dp-data-table-wrapper relative">
                {data?.length === 0 ?
                    <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            paginationServer
                            paginationTotalRows={packages?.archivePackages?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowSizeChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            sortServer
                            sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                            onSort={handleRemoteSort}
                            paginationDefaultPage={page}
                            keyField="dp-table"
                            paginationResetDefaultPage={true}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: t('common.paginationRowText'),
                                    rangeSeparatorText: t('common.of')
                                }
                            }
                        />

                    </>
                }
                {packages?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </div>

            <AddPackageModal t={t} open={openAdd} close={handleCloseAdd} />
        </article>
    );
}

export default ArchivedPackages;