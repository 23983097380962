import { Button } from "@mui/material";
import { RiFileExcel2Line } from "react-icons/ri";
import * as XLSX from "xlsx";
import { formatDateToUserTimezone } from "./dateFormate";

export const useXLSX = ({ columns, data = [], name, owed, owes }) => {
    const generateXlsxReport = () => {
        let selectedHeaders = columns?.map((column) => ({
            label: column?.name,
            key: column?.id,
        }));

        // let newSelectedHeader = [...selectedHeaders, { label: 'Owed', key: "owed" }, { label: 'Owes', key: "owes" }]
        // // console.log(newSelectedHeader);
        // const additionalData = [...data, { owed: owed }, { owes: owes }]

        const newData = data?.map((item) =>
            selectedHeaders?.reduce((acc, { key }) => {
                if (item?.hasOwnProperty(key)) {
                    if (key === "orderTime" || key === "transactionTime" || key === "createdAt" || key === "deliveredAt") {
                        acc[key] = formatDateToUserTimezone(item[key]);
                    } else {
                        acc[key] = item[key];
                    }
                }
                return acc;
            }, {})
        );

        const handleDownload = () => {
            // 1. Create the header row:
            const headerRow = selectedHeaders.map((header) => header.label);
            if (owed && owes) {
                headerRow.push("Owed", "Owes");
            }

            // 2. Create the data rows from the *transformed* newData:
            const dataRows = newData?.map((item) => {
                const row = selectedHeaders?.map(({ key }) => item[key] === 0 ? 0 : item[key] || '');
                return row;
            });

            // 4. Combine header, data, and last row into a 2D array:
            const wsData = [headerRow, ...dataRows];

            // Add the owed/owes values to the last row of wsData.
            if (wsData.length > 0) {
                wsData.push(Array(selectedHeaders.length).fill('').concat([owed, owes]))
            } else {
                wsData.push(headerRow.map(() => '').concat([owed, owes]));
            }
            // console.log(wsData);

            // 4. Create the worksheet:
            const ws = XLSX.utils.aoa_to_sheet(wsData);

            // 5. Create the workbook and download:
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, `${name ? name : "Restaurant Transactions Report"}.xlsx`);
        };

        return (
            <Button
                className="hover:bg-none w-full py-2 px-4"
                onClick={handleDownload}
            >
                <span className="flex items-center gap-x-1">
                    <RiFileExcel2Line className="text-mainGreen text-xl" />
                    <span className="text-sm text-[#999] capitalize font-medium">
                        Excel File
                    </span>
                </span>
            </Button>
        );
    };

    return { generateXlsxReport };
};