import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";
import { toast } from "react-toastify";

export const getVariables = createAsyncThunk(
  "getVariablesFunc",
  async (_, { rejectWithValue, dispatch }) => {
    const url = `/restaurant-system-configuration`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendVariables = createAsyncThunk(
  "sendVariablesFunc",
  async (values, { rejectWithValue, dispatch }) => {
    const url = `/restaurant-system-configuration`;

    try {
      const res = await toast.promise(axios.post(url, values), {
        toastId: "sendVariables",
        pending: "Loading...",
        success: "Variable Updated Successfully.",
      });
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const initState = {
  loading: false,
  variables: [],
  errors: null,
};
const AdminSettingSlice = createSlice({
  name: "Admin Setting Slice",
  initialState: initState,
  // reducers:{},
  extraReducers: (builder) => {
    builder
      .addCase(getVariables.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVariables.fulfilled, (state, action) => {
        state.loading = false;
        state.variables = action.payload;
        state.errors = null;
      })
      .addCase(getVariables.rejected, (state, action) => {
        state.loading = false;
        state.variables = [];
        state.errors = action.payload;
      })
      .addCase(sendVariables.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendVariables.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
      })
      .addCase(sendVariables.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export default AdminSettingSlice.reducer;
