import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Routing from './Components/Routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { closeHandler } from './Components/Redux/Slice/common/overlaySlice';
import axios from 'axios'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-phone-input-2/lib/style.css'

function App() {

  const [navCollapse, setnavCollapse] = useState(false)
  const { auth } = useSelector(state => state)

  const toggleNavHanlder = () => {
    let toggle = navCollapse
    setnavCollapse(!toggle)
  }

  const { status, dateOverlay, orderNotify, restaurantRequest } = useSelector(state => state.overlay)
  const state = useSelector(state => state)
  // console.log(orderNotify)
  const token = localStorage.getItem('atk') ? localStorage.getItem('atk') : null
  const dispatch = useDispatch()
  // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
  // if(process.env.REACT_APP_NODE_ENV === 'production'){
  //   axios.defaults.baseURL = 'https://api.takein.sa/admin-app'
  // } else if(process.env.REACT_APP_NODE_ENV === 'staging'){
  //   axios.defaults.baseURL = 'https://api-staging.takein.sa/admin-app'
  // } else {
  //   axios.defaults.baseURL = 'https://api-develop.takein.sa/admin-app'
  // }
  if (window.location.hostname === 'admin.takein.sa') {
    axios.defaults.baseURL = 'https://api.takein.sa/admin-app'
  } else if (window.location.hostname === 'admin-staging.takein.sa') {
    axios.defaults.baseURL = 'https://api-staging.takein.sa/admin-app'
  } else if (window.location.hostname === 'admin-develop.takein.sa') {
    axios.defaults.baseURL = 'https://api-develop.takein.sa/admin-app'
  } else if (window.location.hostname === 'localhost') {
    axios.defaults.baseURL = 'https://api-develop.takein.sa/admin-app'
  }
  // console.log('axios.defaults.baseUrl', axios.defaults.baseURL)
  // console.log('process.env.REACT_APP_NODE_ENV', process.env.REACT_APP_NODE_ENV)
  // console.log('process.env', process.env)
  // console.log(window.location.hostname)
  // axios.defaults.headers.post["Content-Type"] = "multipart/form-data"
  axios.defaults.headers.post["Content-Type"] = "application/json"
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`

  const navigate = useNavigate()
  const location = useLocation()


  useEffect(() => {
    console.log('Up and Running');
    if (localStorage.getItem('atk') && state?.auth?.role === 'ACCOUNT_MANAGER' && (!localStorage.getItem('pid') || localStorage.getItem('pid') === null) && localStorage.getItem('prv') === 'true') {
      navigate('/selectrestaurant', { replace: true })
    } else if (localStorage.getItem('atk') && location.pathname === '/' && state?.auth?.role === 'SUPER_ADMIN' && localStorage.getItem('prv') === 'true') {
      navigate('/dashboard/home', { replace: true })
    } else if (localStorage.getItem('atk') && location.pathname === '/' && state?.auth?.role === 'ACCOUNT_MANAGER' && localStorage.getItem('prv') === 'true') {
      navigate('/dashboard/partner/home', { replace: true })
    }
    else if ((!localStorage.getItem('atk') || localStorage.getItem('atk') === 'undefined') && location.pathname === '/') {
      navigate('/login')
    } else if (localStorage.getItem('atk') && localStorage.getItem('prv') === 'false') {
      navigate('/firstnewpassword', { replace: true })
    } else if (localStorage.getItem('atk') && location.pathname === '/' && (state?.auth?.role !== 'SUPER_ADMIN' || state?.auth?.role !== 'ACCOUNT_MANAGER') && (localStorage.getItem('prv') === 'true')) {
      navigate('/dashboard/restaurant', { replace: true })
    }

  }, [auth?.passwordResetVerification])

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear()
        navigate('/login')
      }
      return Promise.reject(error);
    }
  );

  // console.log(process.env.REACT_APP_NODE_ENV);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={8500}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        limit={8}
      />
      <article className="TakeIn-admin overflow-hidden h-screen bg-[#F2F0F6] relative">

        <main className='h-full overflow-y-auto'>
          <Routing />
        </main>

        {status || dateOverlay || orderNotify || restaurantRequest ? <section onClick={() => dispatch(closeHandler())} className='overlay backdrop-blur-sm bg-[rgba(0,0,0,.01)] absolute top-0 bottom-0 left-0 right-0 z-[999]'></section> : null}
      </article>
    </>
  );
}

export default App;
