import { Button, ListItemButton, } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineModeEditOutline } from 'react-icons/md'
import ProfileModal from "./ProfileComponents/profileChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, updateProfile } from "../../Components/Redux/Slice/Profile/profileSlice";
import previewIcon from '../../Assets/imgs/previewIcon.svg'
import BreadCrumb from '../../Components/BreadCrumb/breadCrumb';
import Overlay from "../../Components/Common/overlay";
import { addFile } from "../../Components/Redux/Slice/Upload/file";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from "react-i18next";
import Compressor from "compressorjs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { formatDateToUserTimezone } from "../../Components/Common/dateFormate";

const Profile = () => {
    const { t, i18n } = useTranslation()
    const MySwal = withReactContent(Swal);
    const { profile } = useSelector(state => state)
    const dispatch = useDispatch()
    const form = useForm({
        defaultValues: {
            firstName: profile?.profile?.data?.firstName,
            lastName: profile?.profile?.data?.lastName,
            email: profile?.profile?.data?.email,
            mobileNumber: profile?.profile?.data?.mobileNumber
        },
    })
    const { register, control, handleSubmit, formState, setValue, getValues, reset, } = form
    const { errors, isDirty } = formState
    const [preview, setPreview] = useState(profile?.profile?.data?.profileImage);
    const [img, setimg] = useState(null);

    // console.log(profile?.profile?.data);
    // const optionsAr = { timeZone: 'Asia/Riyadh', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    // const options = { timeZone: 'Asia/Riyadh', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    // const stringFormat = lastLoginDate.toLocaleString(localStorage.getItem('lang') === 'ar' ? optionsAr : options)
    // let stringFormat = localStorage.getItem('lang') === 'ar' ? lastLoginDate.toLocaleString('ar-EG', optionsAr) : lastLoginDate.toUTCString('en-US', options);
    const lastLoginDate = new Date(profile?.profile?.data?.lastLogin)
    let stringFormat = formatDateToUserTimezone(lastLoginDate);

    // console.log(lastLoginDate.toISOString());
    useEffect(() => {
        // console.log(img); 
    }, [img])


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 4,
        borderRadius: 5
    };

    // get profile info

    useEffect(() => {
        dispatch(getProfile())
        setPreview(profile?.profile?.data?.profileImage)
        // console.log(getValues());
        // console.log(profile?.loading);
    }, [dispatch, profile?.profile?.data?.profileImage])

    useEffect(() => {
        setValue('firstName', profile?.profile?.data?.firstName);
        setValue('lastName', profile?.profile?.data?.lastName);
        setValue('email', profile?.profile?.data?.email);
        setValue('mobileNumber', profile?.profile?.data?.mobileNumber);
        setValue('gender', 'male');
        setValue('dateOfBirth', '1980-06-06');

    }, [setValue, profile]);


    const maxSize = 1024 * 1024 * 5
    const compressionLimit = 55
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    const [loadImgCompress, setloadImgCompress] = useState(false)

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {

            if (file.size > maxSize) {
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if (!acceptedImageTypes.includes(file.type)) {
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                if ((file.size / 1024) > compressionLimit) {
                    setloadImgCompress(true)
                    new Compressor(file, {
                        quality: 0.6,
                        maxWidth: 600,
                        maxHeight: 600,


                        success: (compressedResult) => {
                            setloadImgCompress(false)
                            setimg(compressedResult)
                        },
                    });
                } else {
                    setimg(file)
                }
                // setimg(e.target.files[0])
                setValue('', '', { shouldDirty: true })
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    // setValue('ResturantProfileImage', reader.result);
                    setPreview(reader.result);
                }
            }
        }

    }


    const submitHandler = async (data) => {
        // const formData = new FormData();
        // formData.append("file", img);
        // console.log(data);
        // console.log(formData);
        // const updateProfileData = dispatch(updateProfile(data))
        // const ProfileImage = dispatch(updateProfileImage(formData))

        // const res = await Promise.all([updateProfileData, ProfileImage]);
        // res[0].meta.requestStatus === 'fulfilled' && res[1].meta.requestStatus === 'fulfilled' ? toast.success('Profile Updated Successfully') : console.log('error');
        let formData = new FormData()
        if (img) {
            formData.append('file', img)
            try {
                const response = await dispatch(addFile(formData));

                if (response.payload?.data) {

                    const imageUrl = response.payload.data.url;
                    const formData = {
                        ...data, // include all other form data
                        image: imageUrl
                    };

                    await dispatch(updateProfile(formData))
                }
            } catch (error) {
                // console.log(error);
            }

        } else {
            const formData = {
                ...data, // include all other form data
            };
            await dispatch(updateProfile(formData))
        }
    }

    const paths = [
        { path: '/dashboard/home', name: 'dashboard', nameAr: "لوحة التحكم" },
        { path: '', name: 'profile', nameAr: "الحساب الشخصى" },
    ]

    const { file } = useSelector(state => state)

    return (
        <article className="profile-wrapper">
            <div className="profile-title text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">
                <h2 className="font-playfair">{t('profile.title')}</h2>
            </div>
            <div className=" relative flex flex-wrap md:flex-nowrap  justify-between gap-4 items-start [&>section]:basis-full md:[&>section]:basis-1/2 [&>section]:rounded-md mb-40">
                <section className="profile-left-section bg-white p-5">
                    <div><BreadCrumb paths={paths} seperator='/' /></div>
                    <form className="profile-image-wrapper mt-5" onSubmit={handleSubmit(submitHandler)} noValidate>
                        <div className="w-[150px] h-[150px] bg-[rgba(206,206,206,0.3)] relative">

                            <div className="defaultImage w-full h-full z-10 absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center rounded-lg">
                                <img src={previewIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" />
                            </div>
                            <div className="preview-wrapper shadow-md relative rounded-lg z-30 w-full h-full">
                                <div className="absolute top-0 right-0 w-full h-full rounded-lg z-40">
                                    <input type="file" id="file" accept="image/*" onChange={handleImageChange} className="absolute right-0 cursor-pointer opacity-1 z-10 opacity-0 w-full h-full" />
                                    <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span>
                                </div>
                                {preview && <img src={preview} alt="Resturant Profile" className="w-full h-full rounded-lg object-cover" />}
                                {loadImgCompress ? <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-[rgba(0,0,0,.4)] text-white flex items-center justify-center">
                                    <p className="w-fit animate-spin text-3xl"><AiOutlineLoading3Quarters /></p>
                                </div> : null}
                            </div>

                            {/* <div className="preview-wrapper shadow-md w-[200px] h-[200px] relative p-2 bg-[#f7f5fd] rounded-md">
                                <div className="absolute -top-5 -right-2 cursor-pointer w-full">
                                    <input type="file" id="file" name="profileImage" accept="image/*" onChange={handleImageChange} className="absolute right-0 cursor-pointer opacity-1 w-[100px] z-10 opacity-0" />
                                    <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span>
                                </div>
                                {preview ? <img src={preview ? preview : profile?.profile?.data?.profileImage} alt="Preview" className="w-full h-full rounded-md" /> : null }
                            </div> */}
                        </div>

                        <div className="form-input-wrapper mt-8 [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            <div>
                                <label>{t('users.form.first_name')}</label>
                                <input type="text" id="firstName"
                                    {...register('firstName', {
                                        required: {
                                            value: true,
                                            message: t('common.required')
                                        },
                                        minLength: {
                                            value: 3,
                                            message: 'min length is 3 characters'
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: 'max length is 50 characters'
                                        },
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                // defaultValue={profile?.profile?.data?.firstName}
                                />

                                {/* {errors?.firstName?.message ? <p className="capitalize text-sm text-danger">{errors?.firstName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('users.form.first_name_helper')}</p>} */}
                                {errors?.firstName?.message && <p className="capitalize text-sm text-danger">{errors?.firstName?.message}</p>}
                            </div>

                            <div>
                                <label>{t('users.form.last_name')}</label>
                                <input type="text" id="lastName"
                                    {...register('lastName', {
                                        required: {
                                            value: true,
                                            message: t('common.required')
                                        },
                                        minLength: {
                                            value: 3,
                                            message: 'min length is 3 characters'
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: 'max length is 50 characters'
                                        },
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                    defaultValue={profile?.profile?.data?.lastName}
                                />

                                {errors?.lastName?.message && <p className="capitalize text-sm text-danger">{errors?.lastName?.message}</p>}
                            </div>

                            <div>
                                <label>{t('users.form.phone')}</label>
                                <input type="text" id="mobileNumber"
                                    {...register('mobileNumber', {
                                        required: {
                                            value: true,
                                            message: t('common.required')
                                        },
                                        minLength: {
                                            value: 11,
                                            message: 'min length is 11 digits'
                                        },
                                        maxLength: {
                                            value: 22,
                                            message: 'max length is 22 digits'
                                        },
                                        pattern: {
                                            value: /^(\+)?\d+$/,
                                            message: 'Only numbers and (+) sign in the beginning allowed'
                                        },
                                    })}
                                    minLength={11}
                                    maxLength={22}
                                    defaultValue={profile?.profile?.data?.mobileNumber}
                                />

                                {errors?.mobileNumber?.message && <p className="capitalize text-sm text-danger">{errors?.mobileNumber?.message}</p>}
                            </div>

                            <div>
                                <label>{t('users.form.email')}</label>
                                <input type="email" id="email"
                                    {...register('email', {
                                        pattern: {
                                            value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                            message: 'Wrong Email Format'
                                        },
                                        required: {
                                            value: true,
                                            message: t('common.required')
                                        },
                                        minLength: {
                                            value: 3,
                                            message: 'min length is 3 characters'
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: 'max length is 50 characters'
                                        },
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                    defaultValue={profile?.profile?.data?.email}
                                />

                                {errors?.email?.message && <p className="capitalize text-sm text-danger">{errors?.email?.message}</p>}
                            </div>

                        </div>

                        <div className="profile-submit-btn-wrapper absolute -bottom-32 right-10 w-[250px]">
                            <ListItemButton disabled={profile?.loading || file?.loading} className="justify-center p-0 disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed bg-mainGreen text-white text-sm font-semibold rounded-md"><button type="submit" className="  w-full  px-8 py-3">{t('common.save_btn')}</button></ListItemButton>
                        </div>

                    </form>
                </section>
                <section className="profile-right-section capitalize">
                    <div className="bg-white p-5 rounded-md">
                        <p className="font-semibold text-[#333]">{t('profile.login_info')}</p>
                        <div className="mt-4">
                            <p className="text-[13px] text-[#333] font-medium">{t('profile.last_login')}</p>
                            <span className="mt-3 text-[#979797] text-[12px] block">{stringFormat}</span>
                        </div>
                    </div>

                    <div className="flex justify-between items-center mt-5 w-full p-5 rounded-md bg-white">
                        <p>{t('profile.password')}</p>

                        <div className="w-fit">
                            <Button onClick={handleOpen} className="w-fit justify-end bg-[#E3F1E9] text-mainGreen rounded-md capitalize">{t('profile.change_password')}</Button>
                        </div>
                    </div>
                </section>
            </div>

            <ProfileModal open={open} close={handleClose} t={t} />

            {profile?.loading || file?.loading ? <Overlay /> : null}
        </article>
    );
}

export default Profile;