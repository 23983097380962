
import { FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput, } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { CiSearch } from 'react-icons/ci'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getRestaurantOrders } from '../../../Components/Redux/Slice/Orders/orders';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { FaEye } from 'react-icons/fa';
import { formatDateToUserTimezone, fullDate } from '../../../Components/Common/dateFormate';
import BreadCrumb from '../../../Components/BreadCrumb/breadCrumb';
import { useRemoteSort } from '../../../Components/Common/sortHook';
import ColumnSelection from '../../../Components/Common/columnSelection';
import { AiFillStar } from 'react-icons/ai';
import NoDataEmoji from '../../../Components/Common/nodataEmoje';
import { TableStyles } from '../../../Components/Common/tableStyle'
import { useTranslation } from 'react-i18next';
import { resetPage } from '../../../Components/Redux/Slice/ResetPagination/resetPagination';
import { useSearchBox } from '../../../Components/Common/searchHook';

//Custome Call UI for the data table action column
function ActionCell({ data }) {
    // const [openModal, setopenModal] = useState(false)
    // const handelOpen = ()=>setopenModal(true)
    // const handelClose = ()=>setopenModal(false)
    // console.log(data);

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // console.log(data?.id);

    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none', padding: 0 } }}
                className='shadow-md p-0'
            >
                <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42]'><Link to='/dashboard/orders/orderdetails' state={{ id: data?.id }} className={`w-full flex items-center gap-x-3 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>{localStorage.getItem('lang') === 'ar' ? <><FaEye />عرض تفاصيل الطلب</> : <><FaEye />View Order Details</>}</Link></MenuItem>
                </div>
            </Menu>

            {/* <EditrestaurantModal open={openModal} close={handelClose} data={data} img={kfc} /> */}
        </div>
    );
}

const RestaurantOrders = () => {
    const { t, i18n } = useTranslation()
    //data table
    // const restaurants = useSelector(state => state.restaurants)
    const dispatch = useDispatch()
    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')
    // const [searchValue, setsearchValue] = useState('')

    const { currentPage, toggle } = useSelector((state) => state?.resetPagination);

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])


    const location = useLocation()

    let resId = location?.state?.id
    let name = location?.state?.name
    useEffect(() => {
        dispatch(getRestaurantOrders({ page, size, resId }))
    }, [dispatch, resId])

    const { orders } = useSelector(state => state)
    // console.log(orders)

    const handlePageChange = page => {
        setpage(page);
        // console.log(page);
    };

    const handleRowSizeChange = rows => {
        setsize(rows);
    };

    useEffect(() => {
        dispatch(getRestaurantOrders({ page, size, resId }))
    }, [dispatch, page, size])


    // const handleSearch = () => {
    //     dispatch(getRestaurantOrders({ searchValue })).then(() => dispatch(resetPage()))
    // }

    // const handleReset = () => {
    //     dispatch(getRestaurantOrders({ page, size })).then(() => dispatch(resetPage()))
    //     setsearchValue('')
    // }


    // console.log(restaurants);

    // const dateFormate = (date)=>{
    //     let currentDate = new Date(date)
    //     const newDate = date?.slice(0,10)
    //     const newTime = currentDate.toLocaleTimeString();
    //     const fullDateTime = newDate + " " + newTime
    //     // console.log(newDate);
    //     return fullDateTime
    // }

    const data = orders?.resOrders?.data

    // console.log(data);

    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row.id,
        //     sortable: false,
        //     width:'80px'
        // },
        {
            id: "resName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم المطعم' : 'Restaurant Name'}`,
            selector: row => row.restaurantName,
            sortable: true,
        },
        {
            id: "courierName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم السائق' : 'Courier Name'}`,
            selector: row => row.courierName,
            sortable: true,
        },
        {
            id: "courierNumber",
            name: `${localStorage.getItem('lang') === 'ar' ? 'هاتف السائق' : 'Courier Number'}`,
            selector: row => row?.courierMobileNumber,
            sortable: true,
        },
        {
            id: "courierRating",
            name: `${localStorage.getItem('lang') === 'ar' ? 'تقييم السائق' : 'Courier Rating'}`,
            selector: row => <div className='flex' data-tag="allowRowEvents"> {[...Array(row?.courierRating)].map((_, index) => <AiFillStar key={index} className='text-mainYellow' />)} </div>,
            sortable: true,
        },
        {
            id: "createdAt",
            name: `${localStorage.getItem('lang') === 'ar' ? 'تم انشاءه فى' : 'Created at'}`,
            selector: row => formatDateToUserTimezone(row.createdAt),
            sortable: true
        },
        // {
        //     name: 'Delieverd at',
        //     selector: row => row?.deliveredAt ? formatDateToUserTimezone(row.deliveredAt) : '',
        //     sortable : true
        // },
        {
            id: "orderRating",
            name: `${localStorage.getItem('lang') === 'ar' ? 'تقييم الطلب' : 'Order Rating'}`,
            selector: row => <div data-tag="allowRowEvents" className='flex'> {[...Array(row?.orderRating)].map((_, index) => <AiFillStar key={index} className='text-mainYellow' />)} </div>,
            sortable: true,
        },
        {
            id: "orderStatus",
            name: `${localStorage.getItem('lang') === 'ar' ? 'حالة الطلب' : 'Order Status'}`,
            selector: row => row.orderStatus === 'OUT_FOR_DELIVERY' ? <span className='capitalize text-mainYellow bg-mainYellow bg-opacity-10 font-semibold px-2 py-1 rounded-md min-w-[100px] text-center block'>{row.orderStatus?.replace(/_/g, ' ').toLowerCase()}</span> : row?.orderStatus === 'CONFIRMED' ? <span className='capitalize block text-mainGreen bg-mainGreen bg-opacity-10 font-semibold px-2 py-1 rounded-md min-w-[100px] text-center'>{row.orderStatus?.replace(/_/g, ' ').toLowerCase()}</span> : row?.orderStatus === 'IN_THE_KITCHEN' ? <span className='capitalize block text-mainRed bg-mainRed bg-opacity-10 font-semibold px-2 py-1 rounded-md min-w-[100px] text-center'>{row.orderStatus?.replace(/_/g, ' ').toLowerCase()}</span> : <span className='capitalize block text-mainColor bg-mainColor bg-opacity-10 font-semibold px-2 py-1 rounded-md min-w-[100px] text-center'>{row.orderStatus?.replace(/_/g, ' ').toLowerCase()}</span>,
            sortable: false,
        },
        // {
        //     name: 'Vat',
        //     selector: row => row.vat,
        //     sortable: false,
        // },
        {
            id: "pickupSchedule",
            name: `${localStorage.getItem('lang') === 'ar' ? 'وقت الاستلام' : 'Pickup Schedule'}`,
            selector: row => <span data-tag="allowRowEvents">{fullDate(row.pickupSchedule)}</span>,
            sortable: true,
        },
        {
            id: "deliveryCharges",
            name: `${localStorage.getItem('lang') === 'ar' ? 'رسوم التوصيل' : 'Delivery Charges'}`,
            cell: row => <span data-tag="allowRowEvents" className='grow text-center'>{row.deliveryCharges} <span className='text-mainGreen font-semibold'>SAR</span></span>,
            sortable: false,
            grow: 1,
            justifyContent: 'center',
            className: 'delivery'
        },
        {
            id: "vehiclePlate",
            name: `${localStorage.getItem('lang') === 'ar' ? 'لوحة المركبة' : 'Vehicle Plate'}`,
            selector: row => <span data-tag="allowRowEvents">{row.vehiclePlate}</span>,
            sortable: true,
        },
        {
            id: "customerName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم العميل' : 'Customer Name'}`,
            selector: row => row?.customerName,
            sortable: true,
        },
        {
            id: "customerNumber",
            name: `${localStorage.getItem('lang') === 'ar' ? 'هاتف العميل' : 'Customer Number'}`,
            selector: row => row?.customerMobileNumber,
            sortable: true,
        },
        {
            id: "customerAddress",
            name: `${localStorage.getItem('lang') === 'ar' ? 'عنوان العميل' : 'Customer Address'}`,
            selector: row => row?.customerAddress,
            sortable: true,
        },
        {
            id: "originalPrice",
            name: `${localStorage.getItem('lang') === 'ar' ? 'السعر الاساسى' : 'Original Price'}`,
            selector: row => <span data-tag="allowRowEvents">{row?.originalPrice} <span className='text-mainGreen font-semibold'>SAR</span></span>,
            sortable: true,
        },
        {
            id: "finalPrice",
            name: `${localStorage.getItem('lang') === 'ar' ? 'السعر النهائى' : 'Final Price'}`,
            selector: row => <span data-tag="allowRowEvents">{row?.finalPrice} <span className='text-mainGreen font-semibold'>SAR</span></span>,
            sortable: true,
        },
        {
            id: "branchName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم الفرع' : 'Branch Name'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.branchName} </span>,
            sortable: true,
        },
        {
            id: "branchNumber",
            name: `${localStorage.getItem('lang') === 'ar' ? 'هاتف الفرع' : 'Branch Number'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.branchNumber} </span>,
            sortable: true,
        },
        {
            id: "branchAddress",
            name: `${localStorage.getItem('lang') === 'ar' ? 'عنوان الفرع' : 'Branch Address'}`,
            cell: row => <span data-tag="allowRowEvents">{row?.branchAddress} </span>,
            sortable: true,
        },
        {
            id: "actions",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
            allowOverflow: true,
            button: true,
            cell: row => (
                <>
                    {<ActionCell data={row} />}
                </>
            )

        },
    ];

    const placeHolder = t('placeholder.searchOrdersPlaceholder')
    const { searchValue, searchInput } = useSearchBox(getRestaurantOrders, placeHolder)
    const { handleRemoteSort, icon } = useRemoteSort(getRestaurantOrders, dispatch, page, size, searchValue, null, null, resId)

    const [selectedColumns, setSelectedColumns] = useState(['resName', 'courierName', 'courierNumber', 'createdAt', 'orderRating', 'orderStatus', 'deliveryCharges', 'customerName', 'customerNumber', 'actions']);

    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = () => {
        setopen(true)
    }
    const handleClose = () => {
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['resName', 'courierName', 'courierNumber', 'createdAt', 'orderRating', 'orderStatus', 'deliveryCharges', 'customerName', 'customerNumber', 'actions']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);


    const paths = [
        { path: '/dashboard/restaurant', name: 'restaurants', nameAr: "المطاعم" },
        { path: '', name: 'completed orders', nameAr: "الطلبات المكتملة" },
    ]

    return (
        <article className="restaurants-wrapper">
            <div><BreadCrumb paths={paths} seperator='/' /></div>
            <section className="restaurant-title [&>p]:font-playfair">
                <p className="text-mainColor font-bold text-2xl">{name}</p>
                <p className="text-[#929292] font-semibold text-lg">{t('restaurants.restaurant_orders.title')}</p>
            </section>

            <section>

                <div className={`flex gap-x-3 mt-5 justify-end ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    {/* <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openBy ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBy ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickBy}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                        >
                            {col ? col?.replace(/_/g, " ") : 'Sort By'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElBy}
                            open={openBy}
                            onClose={handleCloseBy}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                Sort By
                            </MenuItem>

                            {sortByList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                    onClick={()=>handleActiveBy(item,index)}
                                    selected = {index === selectedByIndex}
                                >
                                    {item?.name?.replace(/_/g, " ")}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div> */}

                    {/* <div>

                        <Button
                            id="sort-dropdown"
                            aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrder ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickOrder}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                        >
                            {dir ? dir : 'Sort Order'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElOrder}
                            open={openOrder}
                            onClose={handleCloseOrder}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                Sort Order
                            </MenuItem>

                            {sortOrderList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                    onClick={()=>handleActiveOrder(item,index)}
                                    selected = {index === selectedOrderIndex}
                                >
                                    {item?.name}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                        
                    </div> */}

                    <div className={`orders-search-wrapper flex items-center justify-end gap-x-3 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        {/* <FormControl
                            className="search-form [&>div>input]:py-[.6rem]"
                            sx={{ width: '35ch', backgroundColor: 'white', borderRadius: '6px', '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': { py: '9px' }, '& fieldset': { borderColor: 'primary.main', border: 'none' } }} variant="outlined">
                            <OutlinedInput
                                type="search"
                                id="outlined-adornment-search"
                                placeholder={`${localStorage.getItem('lang') === 'ar' ? 'ابحث في الطلبات...' : 'Search Restaurants...'}`}
                                onChange={(e) => {
                                    setsearchValue(e.target.value)
                                    dispatch(resetPage())
                                    if (!e.target.value) {
                                        handleReset();
                                        dispatch(resetPage())
                                    }
                                }}
                                startAdornment={
                                    <InputAdornment position="start">

                                        <IconButton
                                            aria-label="search"
                                            onClick={handleSearch}
                                            edge="start"
                                        >
                                            <CiSearch />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl> */}
                        {searchInput()}

                        <div className="flex">
                            <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                            {/* <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                        </div>

                    </div>

                </div>

            </section>

            <section className="datatable-wrapper mt-5 bg-white rounded-md relative">

                {data?.length === 0 ?
                    <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            paginationServer
                            paginationTotalRows={orders?.resOrders?.meta?.total}
                            paginationDefaultPage={page}
                            paginationResetDefaultPage={true}
                            keyField="id"
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowSizeChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            sortServer
                            sortIcon={icon}
                            onSort={handleRemoteSort}
                        />
                    </>
                }
                {orders?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div>
                    : null}
            </section>
        </article>
    );
}

export default RestaurantOrders;