import { TbReport } from "react-icons/tb";
import { BsFiletypePdf } from "react-icons/bs";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import DataTable from "react-data-table-component";
import ColumnSelection from "../../../Components/Common/columnSelection";
import { TableStyles } from "../../../Components/Common/tableStyle";
import { formatDateToUserTimezone } from "../../../Components/Common/dateFormate";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import { DateRangePicker } from "react-date-range";
import { openHandler } from "../../../Components/Redux/Slice/common/overlaySlice";
import dayjs from "dayjs";
import { FaAngleDown } from "react-icons/fa6";
import { BsDot } from "react-icons/bs";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { usePDFGenerator } from "../../../Components/Common/pdfGenerator";
import { useXLSX } from "../../../Components/Common/xlsxGenerator";
import { useSearchBox } from "../../../Components/Common/searchHook";
import { getCourierMetrics } from "../../../Components/Redux/Slice/Metrics/metrics";

const DeliveryMetrics = () => {
    // const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // const [searchValue, setsearchValue] = useState('')
    const [activeTab, setActiveTab] = useState(0);
    const [dateRange, setdateRange] = useState('last_7_days');
    const { currentPage, toggle, sort } = useSelector((state) => state?.resetPagination);

    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    // useEffect(() => {
    //     dispatch(getCourierMetrics({ dateRange }))
    // }, [dispatch])

    const btns = [
        { label: 'last week', labelAr: "اخر أسبوع", param: 'last_7_days' },
        { label: 'last month', labelAr: "اخر شهر", param: 'last_30_days' },
        { label: 'last 5 months', labelAr: "اخر 5 أشهر", param: 'last_5_months' },
    ]

    const filterClickHandler = (item, index) => {
        setActiveTab(index)
        // let dateRange = item?.param
        setdateRange(item?.param)
        // dispatch(getCourierMetrics({ dateRange: item?.param }))
    }


    const courierMetrics = useSelector(state => state?.metrics)
    // console.log(courierMetrics);

    let data = courierMetrics?.courierMetrics?.data

    const columns = [
        {
            id: 'driverId',
            name: t('couriers.reports.driverId'),
            cell: row => <span>{row?.driverId}</span>,
            sortable: false,
        },

        {
            id: 'courierName',
            name: t('couriers.reports.courierName'),
            cell: row => row?.courierName,
            sortable: false,
        },
        {
            id: 'courierMobileNumber',
            name: t('couriers.reports.courierMobileNumber'),
            cell: row => row?.courierMobileNumber,
            sortable: false,
        },
        {
            id: 'totalOrders',
            name: t('couriers.reports.totalOrders'),
            cell: row => row?.totalOrders,
            sortable: false,
        },
        {
            id: 'avgDeliveryTime',
            name: t('couriers.reports.avgDeliveryTime'),
            cell: row => row?.avgDeliveryTime,
            sortable: false,
        },
        {
            id: 'avgCompletionTime',
            name: t('couriers.reports.avgCompletionTime'),
            cell: row => row?.avgCompletionTime,
            sortable: false,
        },
        {
            id: 'avgDriverRating',
            name: t('couriers.reports.avgDriverRating'),
            cell: row => row?.avgDriverRating,
            sortable: false,
        },
        {
            id: 'avgAcceptanceRate',
            name: t('couriers.reports.avgAcceptanceRate'),
            cell: row => row?.avgAcceptanceRate,
            sortable: false,
        },

        // {
        //     id: 'actions',
        //     name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
        //     allowOverflow: true,
        //     button: true,
        //     cell: row => (
        //         <>
        //             {<ActionCell data={row} t={t} />}
        //         </>
        //     )
        // },
    ];

    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['driverId', 'courierName', 'courierMobileNumber', 'totalOrders', 'avgDeliveryTime', 'avgCompletionTime', 'avgAcceptanceRate', 'avgDriverRating']);

    const [openColumn, setopenColumn] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = () => {
        setopenColumn(true)
    }
    const handleClose = () => {
        setopenColumn(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['driverId', 'courierName', 'courierMobileNumber', 'totalOrders', 'avgDeliveryTime', 'avgCompletionTime', 'avgAcceptanceRate', 'avgDriverRating']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);



    // date range filter
    const [selectedDate, setSelectedDate] = useState(null);
    const [date, setdate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const handleDateRangeChange = async (e) => {
        let newPage = 1
        setpage(1)
        setsize(10)
        setdate([e.selection])
        let dateRange = e?.selection
        let fromDate = dayjs(dateRange?.startDate).utc().format('YYYY-MM-DDTHH:mm:ss')
        let toDate = dayjs(dateRange?.endDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
        let selectedDate = { fromDate: fromDate, toDate: toDate }

        setSelectedDate(selectedDate)
        await dispatch(getCourierMetrics({ page: newPage, size, selectedDate })).then(() => {
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
            dispatch(resetPage())
        })
        // dispatch(getRatingSummary(selectedDate))
    }

    const { dateOverlay } = useSelector(state => state.overlay)

    const handleButton = () => {
        dispatch(openHandler({ dateShow: !dateOverlay }))
    }

    const placeHolder = t('placeholder.searchCourierReportPlaceholder')
    const { searchValue, searchInput } = useSearchBox(getCourierMetrics, placeHolder)
    const { handleRemoteSort, icon } = useRemoteSort(getCourierMetrics, dispatch, page, size, searchValue, null, selectedDate, null, null, null, dateRange)

    const { PDFDocument } = usePDFGenerator({ columns: visibleColumns, data: data || [], date, name: "Couriers Metrics Report" })
    // const { generateCsvReport } = useCSV({ columns: visibleColumns, data: data || [], date, name: "Contractior CSV Report" })
    const { generateXlsxReport } = useXLSX({ columns: visibleColumns, data: data || [], name: 'Couriers Metrics xlsx Report' });


    // for PDF report generator
    const generatePdfReport = () => {

        return (
            <PDFDownloadLink document={<PDFDocument />} fileName="Couriers Metrics Report">
                <Button className="flex items-center gap-x-1 py-2 px-4">
                    <BsFiletypePdf className="text-mainRed text-xl" />
                    <span className="text-sm capitalize text-[#999]">
                        PDF File
                    </span>
                </Button>
            </PDFDownloadLink>
        )
    }

    // for CSV report generator
    // const generateCsvReport = () => {
    //     // this to display all columns in CSV
    //     // let headers = data && Object?.keys(data?.[0])?.map(header => ({ label: header.replace(/([A-Z])/g, ' $1').toLowerCase(), key: header }));

    //     let selectedHeaders = visibleColumns?.map(column => ({ label: column?.name, key: column?.id }))

    //     const newData = data?.map((item) => selectedHeaders?.reduce((acc, { key }) => {
    //         if (item?.hasOwnProperty(key)) {
    //             acc[key] = item[key];
    //         }
    //         return acc;
    //     }, {}))




    //     return (
    //         <IconButton>
    //             {data ?
    //                 <CSVLink data={newData} filename="Restaurant Transactions Report" headers={selectedHeaders} separator=";">
    //                     <BsFiletypeCsv className="text-mainGreen" />
    //                 </CSVLink>

    //                 : <BsFiletypeCsv className="text-mainGreen" />
    //             }
    //         </IconButton>

    //     )
    // }


    // for generating reports menu

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleFilterOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterClose = () => {
        setAnchorEl(null);
    };


    // console.log(transactions?.contractorTrans?.data);

    return (
        <>
            <Box dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                {/* <PDFViewer className="w-full min-h-[400px]">
                    <PDFDocument />
                </PDFViewer> */}
                <h2 className="text-xl capitalize font-semibold text-dark">{t('couriers.reports.title')}</h2>
                <div className={`flex justify-between items-center mt-2 ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>

                    <div className={`report-courier-filter-wrapper mt-4 flex justify-between ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center gap-x-3`}>
                        <div className="filter-wrapper flex items-center gap-x-2">

                            <div className="report-filter-wrapper flex justify-evenly [&>div]:mr-5">
                                <div className="px-1 py-1 bg-[#ffffff] rounded-sm">
                                    {btns?.map((item, index) => {
                                        return (
                                            <button disabled={courierMetrics?.loading} key={index} className={` disabled:pointer-events-auto disabled:bg-opacity-25 disabled:cursor-not-allowed capitalize font-bold text-sm text-[#999999] px-4 py-2 lg:px-3 xl:px-6 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-[#F4F6FC] text-black' : null}`}
                                                onClick={() => filterClickHandler(item, index)}
                                            >
                                                {localStorage?.getItem('lang') === 'ar' ? item.labelAr : item.label}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>

                            {/* <section className='date-wrapper relative'>
                            <Button className='bg-white text-[#333] font-semibold rounded-md py-[10px]' onClick={handleButton}>
                                <button className='flex items-center text-[#333] font-semibold font-playfair capitalize text-sm'> {date[0]?.startDate && date[0]?.endDate ? formatDateToUserTimezone(dayjs(date[0]?.startDate)) + " - " + formatDateToUserTimezone(dayjs(date[0]?.endDate)) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></button>
                            </Button>

                            {dateOverlay ?

                                <div className={`absolute top-full z-[9999] bg-white ${localStorage?.getItem('lang') === 'ar' ? 'left-full' : ''}`} dir='ltr'>
                                    <DateRangePicker
                                        onChange={handleDateRangeChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={date}
                                        direction="vertical"
                                        editableDateInputs={true}
                                    />
                                </div> : null}

                        </section> */}
                        </div>

                    </div>

                    <div className="flex items-center gap-2">
                        <div className="search-container">
                            {searchInput()}
                        </div>
                        <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={openColumn} close={handleClose} />
                    </div>
                </div>


                <section className="datatable-wrapper mt-5 bg-white relative p-2">

                    <div className={`gen-reports-actions flex justify-end items-center gap-x-1 ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        <div>
                            <Button
                                id="filtr-button"
                                aria-controls={open ? 'filter-button' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleFilterOpen}
                                className=" disabled:pointer-events-auto disabled:bg-opacity-30 disabled:cursor-not-allowed flex items-center gap-x-1 text-blue-100 bg-opacity-80 bg-blue-900 capitalize"
                                disabled={!data || data?.length === 0}
                            >
                                <TbReport className="text-xl" /> {t('common.generateReport')}
                            </Button>
                            <Menu
                                id="filter-menu"
                                aria-labelledby="filter-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleFilterClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}

                                sx={
                                    { '.MuiMenuItem-root': { padding: 0 } }
                                }
                            >
                                <MenuItem onClick={handleClose} className="mb-2">
                                    {generatePdfReport()}
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    {generateXlsxReport()}
                                </MenuItem>
                            </Menu>

                        </div>

                        {/* <Button onClick={test}>Test</Button> */}
                    </div>

                    {data?.length === 0 ?
                        <div className='p-8'><NoDataEmoji /></div>
                        :

                        <>
                            <DataTable
                                direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                                columns={visibleColumns}
                                data={data}
                                pagination
                                paginationPerPage={size}
                                paginationRowsPerPageOptions={[10, 20, 50, courierMetrics?.data?.meta?.total]}
                                paginationServer
                                paginationTotalRows={courierMetrics?.data?.meta?.total}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleRowChange}
                                customStyles={TableStyles}
                                highlightOnHover
                                sortServer
                                sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                                onSort={handleRemoteSort}
                                paginationDefaultPage={page}
                                // onRowClicked={(data, event) => {

                                //     handleModalRowClick(data, event)
                                // }}
                                keyField="contractors-transactions-id"
                                paginationResetDefaultPage={true}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: t('common.paginationRowText'),
                                        rangeSeparatorText: t('common.of')
                                    }
                                }
                            />
                        </>
                    }

                    {courierMetrics?.loading ?
                        <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                            <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                        </div> : null}
                </section>

            </Box>
        </>
    );
}

export default DeliveryMetrics;