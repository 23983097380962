import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRemoteSort } from "../../Components/Common/sortHook";
import { useDispatch, useSelector } from "react-redux";
import ColumnSelection from "../../Components/Common/columnSelection";
import NoDataEmoji from "../../Components/Common/nodataEmoje";
import DataTable from "react-data-table-component";
import { TableStyles } from "../../Components/Common/tableStyle";
import { BsDot } from "react-icons/bs";
import { getPayments } from "../../Components/Redux/Slice/Payments/payment";
import { formatDateToUserTimezone } from "../../Components/Common/dateFormate";
import { FaAngleDown, FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { useSearchBox } from "../../Components/Common/searchHook";
import { Button } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import dayjs from "dayjs";
import { openHandler } from "../../Components/Redux/Slice/common/overlaySlice";
import { resetPage, resetSearch, resetSort } from "../../Components/Redux/Slice/ResetPagination/resetPagination";

const ExpandedComponent = ({ data }) => {
    const { t } = useTranslation();

    const paymentResultKeys = data?.paymentResult && Object.keys(data?.paymentResult)

    return (
        <div className="mb-2">
            <div className="bg-[#ddd] p-2 ">
                <div className="grid grid-cols-3 gap-2 mb-2 text-white bg-mainColor rounded-sm p-2 capitalize">
                    <p>{t("payments.referenceId")}</p>
                    <p>{t("payments.registrationId")}</p>
                    <p>{t("payments.merchantTransactionId")}</p>
                </div>
                <div className="grid grid-cols-3 gap-2 p-2 bg-[#f6f6f6] text-[#404040] rounded-sm">
                    <p className="break-words w-full">{data?.referenceId}</p>
                    <p className="break-words w-full">{data?.registrationId}</p>
                    <p className="break-words w-full">{data?.merchantTransactionId}</p>

                </div>

                <div className="paymnt-result mt-2 pt-1 border-t-2 ">
                    <h2 className="font-semibold mb-2 text-mainColor text-lg">{t('payments.paymentResult')}:</h2>

                    <div className="grid grid-cols-3 gap-2 mb-2 text-white bg-mainColor rounded-sm p-2 capitalize">
                        {paymentResultKeys && paymentResultKeys?.map((col) => (
                            <p className="break-words w-full" key={col}>{col?.replace(/([A-Z])/g, ' $1')}</p>
                        ))}
                    </div>

                    <div className="grid grid-cols-3 gap-2 p-2 bg-[#f6f6f6] text-[#404040] rounded-sm">
                        {data?.paymentResult && paymentResultKeys ? paymentResultKeys?.map((key, index) => (
                            <p className="break-words w-full" key={index}>{data?.paymentResult[key]}</p>
                        )) : "No Data Found"}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Payment = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)
    const [selectedDate, setSelectedDate] = useState(null);


    const { currentPage, toggle, sort } = useSelector((state) => state?.resetPagination);

    const payments = useSelector(state => state.payments)

    const handlePageChange = page => {
        setpage(page);
        // console.log(page);
    };

    const handleRowSizeChange = rows => {
        setsize(rows);
    };

    // useEffect(() => {
    //     dispatch(getPayments({ page, size }))
    // }, [dispatch])

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    const columns = [
        {
            id: 'id',
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            width: 'auto',
            maxWidth: "80px"
        },
        {
            id: 'orderId',
            name: t('payments.orderId'),
            selector: row => row.orderId,
            sortable: true,
            // width: 'auto',
            // maxWidth: "100px"
        },
        {
            id: 'amount',
            name: t('payments.amount'),
            selector: row => row.amount,
            sortable: true,
        },
        {
            id: 'paymentType',
            name: t('payments.paymentType'),
            selector: row => row.paymentType,
            sortable: true,
        },
        {
            id: 'paymentBrand',
            name: t('payments.paymentBrand'),
            selector: row => row.paymentBrand,
            sortable: true,
        },
        {
            id: 'status',
            name: t('payments.status'),
            cell: row => <span className={`min-w-[120px] text-center font-[500] bg-opacity-25 p-2 rounded-md capitalize ${row.status === 'SUCCESSFUL' ? 'text-mainGreen bg-mainGreen' : row.status === 'FAILED' ? 'text-mainRed bg-mainRed' : row.status === 'PENDING' ? 'text-mainYellow bg-mainYellow' : ''}`}>{row.status?.toLowerCase()}</span>,
            sortable: true,
        },
        {
            id: 'createdAt',
            name: t('payments.createdAt'),
            selector: row => formatDateToUserTimezone(row.createdAt),
            sortable: true,
        },

        // {
        //     id: 'actions',
        //     name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
        //     allowOverflow: true,
        //     button: true,
        //     cell: row => (
        //         <>
        //             {<ActionCell data={row} />}
        //         </>
        //     )
        // },
    ];

    const data = payments?.payments?.data

    const placeHolder = t('placeholder.searchPaymentsPlaceholder')
    const { searchValue, searchInput } = useSearchBox(getPayments, placeHolder)


    const { handleRemoteSort, icon } = useRemoteSort(getPayments, dispatch, page, size, searchValue, null, selectedDate)


    const [selectedColumns, setSelectedColumns] = useState(['id', 'orderId', 'amount', 'paymentType', 'paymentBrand', 'createdAt', 'status', 'createdAt']);

    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = () => {
        setopen(true)
    }
    const handleClose = () => {
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['id', 'orderId', 'amount', 'paymentType', 'paymentBrand', 'createdAt', 'status', 'createdAt']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);



    // date range

    const [date, setdate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);


    const handleDateRangeChange = async (e) => {
        let newPage = 1
        setpage(1)
        setsize(10)
        setdate([e.selection])
        let dateRange = e?.selection
        let fromDate = dayjs(dateRange?.startDate).utc().format('YYYY-MM-DDTHH:mm:ss')
        let toDate = dayjs(dateRange?.endDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
        let selectedDate = { fromDate: fromDate, toDate: toDate }

        setSelectedDate(selectedDate)
        await dispatch(getPayments({ page: newPage, size, selectedDate })).then(() => {
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
            dispatch(resetPage())
        })
        // dispatch(getRatingSummary(selectedDate))
    }

    const { dateOverlay } = useSelector(state => state.overlay)

    const handleButton = () => {
        dispatch(openHandler({ dateShow: !dateOverlay }))
    }
    return (
        <div className="payment-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('payments.title')}</h1>
            </section>

            <section className={`payemnts-actions-wrapper flex ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center gap-x-3 justify-between`}>
                <div className='date-wrapper relative'>
                    <Button className='bg-white text-[#333] font-semibold rounded-md py-[10px]' onClick={handleButton}>
                        <button className='flex items-center text-[#333] font-semibold font-playfair capitalize text-sm'> {date[0]?.startDate && date[0]?.endDate ? formatDateToUserTimezone(dayjs(date[0]?.startDate)) + " - " + formatDateToUserTimezone(dayjs(date[0]?.endDate)) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></button>
                    </Button>

                    {dateOverlay ?

                        <div className={`absolute top-full z-[9999] bg-white ${localStorage?.getItem('lang') === 'ar' ? 'left-full' : ''}`} dir='ltr'>
                            <DateRangePicker
                                onChange={handleDateRangeChange}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={date}
                                direction="vertical"
                                editableDateInputs={true}
                            />
                        </div> : null}

                </div>


                <div className="flex items-center gap-x-2">
                    {searchInput()}

                    <div>
                        <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                    </div>
                </div>

            </section>

            <section className="datatable-wrapper mt-5 bg-white relative">
                {data?.length === 0 ?
                    <div className='p-8'><NoDataEmoji /></div>
                    :
                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 50, 100, payments?.payments?.meta?.total]}
                            paginationServer
                            paginationTotalRows={payments?.payments?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowSizeChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            sortServer
                            sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                            onSort={handleRemoteSort}
                            paginationDefaultPage={page}
                            keyField="order-table-id"
                            paginationResetDefaultPage={true}
                            // onRowClicked={(data) => navigate('orderdetails', { state: { id: data?.id } })}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: t('common.paginationRowText'),
                                    rangeSeparatorText: t('common.of')
                                }
                            }
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            expandableIcon={{
                                collapsed: localStorage.getItem('lang') === "en" ? <FaAngleRight /> : <FaAngleLeft />,
                                expanded: <FaAngleDown />,
                            }}
                        />
                    </>
                }

                {payments?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </section>

        </div>
    );
}

export default Payment;