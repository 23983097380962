import { Box, Button, FormControl, ListItemText, MenuItem, Modal, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRestaurantRequestById, getSingleRestaurants, toggleRestaurant } from "../../../Components/Redux/Slice/Restaurants/restaurantsSlice";
import BreadCrumb from "../../../Components/BreadCrumb/breadCrumb";
import pdfIcon from '../../../Assets/imgs/pdfIcon.png'
import { MdAlternateEmail, MdOutlineAttachFile } from "react-icons/md";
import { PiWarningCircleFill } from "react-icons/pi";
import { TbReceiptTax } from "react-icons/tb";
import { HiOutlineUser } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { saveAs } from 'file-saver';
import Astrisks from "../../../Components/Common/astrisk";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getFileType } from "./editRestaurant";

const RestaurantDetails = () => {
    const { t, i18n } = useTranslation()
    const { singleRestaurant } = useSelector(state => state?.restaurants)
    const { singleRestaurantRequest } = useSelector(state => state?.restaurants)
    const dispatch = useDispatch()
    const location = useLocation()
    // const [img, setimg] = useState(null);
    // const [preview, setPreview] = useState(singleRestaurant?.data?.bannerImage);
    // const [profilePreview, setprofilePreview] = useState(singleRestaurant?.data?.logoUrl)
    const [takeInShareType, settakeInShareType] = useState(singleRestaurantRequest?.data?.takeinSharePerOrderType || []);

    // console.log(singleRestaurant);
    // console.log(singleRestaurantRequest?.data?.bannerImage);
    // console.log(location.state?.data);
    const navigate = useNavigate()

    const form = useForm()
    const { register, control, handleSubmit, formState, setValue, getValues, watch, trigger } = form
    const { errors } = formState

    useEffect(() => {
        if (location.state.type === 'request' || location.state.type === 'rejected') {
            dispatch(getRestaurantRequestById(location?.state?.id))
        } else {
            dispatch(getSingleRestaurants(location?.state?.id))
        }

    }, [dispatch, location.pathname, location?.state?.id, singleRestaurant?.data?.bannerImage, singleRestaurant?.data?.logoUrl])

    // useEffect(() => {
    //     // setValue('takeinSharePerOrder', singleRestaurantRequest?.data?.takeinSharePerOrder || null, { shouldValidate: true });
    //     // setValue('takeinSharePerOrderType', singleRestaurantRequest?.data?.takeinSharePerOrderType,{shouldValidate:true});
    // }, [setValue, singleRestaurantRequest])

    // console.log(location?.state?.data);
    // console.log(singleRestaurantRequest);

    // const { restaurants } = useSelector(state => state)

    const id = location?.state?.id

    // console.log(restaurants?.singleRestaurant?.data);

    // const submitHandler = async (values) => {

    // }

    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '50%',
        height: '70%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        p: 2,
        borderRadius: 5,
    };

    const [modalImg, setmodalImg] = useState('');
    const [open, setOpen] = useState(false);

    const handleOpen = (e) => {
        setOpen(true)
        setmodalImg(e?.target.src)

    };
    const handleClose = () => setOpen(false);

    const paths = [
        { path: '/dashboard/restaurant', name: 'restaurants', nameAr: "المطاعم" },
        { path: '', name: 'restaurant details', nameAr: "تفاصيل المطعم" },
    ]
    let values = getValues()
    // console.log(values?.takeinSharePerOrderType);
    const toggleRestaurantHandler = async (status) => {
        // console.log(status);
        if (status === 'REJECTED') {

            dispatch(toggleRestaurant({ status, id, values })).then((e) => {
                if (e?.payload?.message === 'SUCCESS') {
                    navigate('/dashboard/restaurant')
                }
            })

        } else if (status === 'APPROVED') {
            if (values?.takeinSharePerOrderType === undefined) {
                setValue('takeinSharePerOrderType', singleRestaurantRequest?.data?.takeinSharePerOrderType, { shouldValidate: true });

            } if (!values?.takeinSharePerOrderType) {
                setValue('takeinSharePerOrder', singleRestaurantRequest?.data?.takeinSharePerOrder, { shouldValidate: true });
            } else {
                if (Object.keys(errors).length === 0 && errors.constructor === Object) {
                    dispatch(toggleRestaurant({ status, id, values })).then((e) => {
                        if (e?.payload?.message === 'SUCCESS') {
                            navigate('/dashboard/restaurant')
                        }
                    })
                }
            }
        }

        // console.log(status);
    }
    // console.log(!errors);
    // useEffect(() => {
    //     if(values?.takeinSharePerOrder === '' || values?.takeinSharePerOrderType === ''){
    //         trigger('takeinSharePerOrder')
    //         trigger('takeinSharePerOrderType')
    //     }
    // }, [trigger, values?.takeinSharePerOrder, values?.takeinSharePerOrderType, watch])


    const downloadImage = (url) => {
        saveAs(url); // Replace 'image_url' with your image URL
    };

    const auth = useSelector(state => state.auth)

    // console.log(location?.state?.data);

    const handleOfferTypeSelectChange = (event) => {
        const { target: { value }, } = event;
        settakeInShareType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setValue('takeinSharePerOrderType', value, { shouldValidate: true })
    };

    // console.log(location?.state?.data?.contract);

    return (

        // <article className="profile-wrapper mt-6">
        //     <div className=" relative flex flex-wrap md:flex-nowrap  justify-between gap-4 items-start [&>section]:basis-full [&>section]:rounded-md mb-32">
        //         <section className="profile-left-section bg-white p-5">
        //             <div className="profile-title text-[#333333] font-semibold capitalize text-xl">
        //                 <h2>Restaurant Details</h2>
        //             </div>

        //             <div><BreadCrumb paths={paths} seperator='/' /></div>

        //             <div className="profile-image-wrapper mt-5">

        //                 <div className="restaurant-preview-wrappe w-full h-[250px] relative">

        //                     <div className="restaurant-profile-image-preview  w-[500px] h-[280px] bg-[rgba(0,0,0,.3)] left-6 rounded-lg m-auto">
        //                         <div className="preview-wrapper shadow-md relative rounded-lg z-30 w-full h-full flex justify-center">
        //                             {/* {location.state.type === 'request' ?
        //                                 location?.state?.data?.logoUrl && <img src={location?.state?.data?.logoUrl} onClick={(e)=>handleOpen(e)} alt="restaurant Profile" className="w-full h-full rounded-lg object-cover" />
        //                                 :
        //                                 singleRestaurant?.data?.logoUrl && <img src={singleRestaurant?.data?.logoUrl} onClick={(e)=>handleOpen(e)} alt="restaurant Profile" className="w-full h-full rounded-lg object-cover" />
        //                             } */}
        //                             {location?.state?.data?.logoUrl && <img src={location?.state?.data?.logoUrl} onClick={(e)=>handleOpen(e)} alt="restaurant Profile" className="w-full h-full rounded-lg object-cover cursor-pointer" />}
        //                         </div>
        //                     </div>

        //                 </div>

        //                 <div className="form-input-wrapper mt-24 grid grid-cols-1 md:grid-cols-2 gap-8 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
        //                     <div className="col-span-2 pi-title">
        //                         <h2 className="font-semibold text-xl text-mainColor border-b pb-2">Personal Information</h2>
        //                     </div>
        //                     {/* Personal Information Fields */}

        //                     <div>
        //                         <label>first name</label>
        //                         <input type="text" id="fName"
        //                             value={location?.state?.data?.firstName}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label>last name</label>
        //                         <input type="text" id="lName"
        //                             value={location?.state?.data?.lastName}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label>marketing phone number</label>
        //                         <input type="text" id="phoneNumber"
        //                             value={location?.state?.data?.marketingPhoneNumber}
        //                             readOnly
        //                         />

        //                     </div>

        //                     <div>
        //                         <label>marketing email</label>
        //                         <input type="email" id="email"
        //                             value={location?.state?.data?.marketingEmail}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div className="col-span-2">
        //                         <label>National ID</label>
        //                         <input type="text" id="nID"
        //                             value={location?.state?.data?.nationalId}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div className="col-span-2 ri-title mt-4">
        //                         <h2 className="font-semibold text-xl text-mainColor border-b pb-2">Restaurant Information</h2>
        //                     </div>

        //                     {/* <div>
        //                         <label>Company name</label>
        //                         <input type="text" id="coName"
        //                             value={location?.state?.data?.companyName}
        //                             readOnly
        //                         />
        //                     </div> */}

        //                     <div>
        //                         <label>restaurant name</label>
        //                         <input type="text" id="name"
        //                             value={location?.state?.data?.restaurantName}
        //                             readOnly
        //                         />
        //                     </div>

        //                     {/* <div>
        //                         <label>Hotline</label>
        //                         <input type="text" id="hotline"
        //                             value={location?.state?.data?.hotline}
        //                         />
        //                     </div> */}

        //                     <div>
        //                         <label>Management Phone Number</label>
        //                         <input type="text" id="restaurantPhoneNumber"
        //                             value={location?.state?.data?.restaurantPhoneNumber}
        //                             readOnly
        //                         />

        //                     </div>

        //                     <div>
        //                         <label>Management Email</label>
        //                         <input type="email" id="restaurantEmail"
        //                             value={location?.state?.data?.restaurantEmail}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label htmlFor="commercialRegisterationNumber">Commercial Registration No.</label>
        //                         <input type="text" id="commercialRegisterationNumber"
        //                             value={location?.state?.data?.commercialRegisterationNumber}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label htmlFor="bankName">Bank Name</label>
        //                         <input 
        //                             type="text" 
        //                             id="bankName" 
        //                             value={location?.state?.data?.bankName}
        //                             readOnly
        //                         />
        //                     </div>

        //                     <div>
        //                         <label htmlFor="bankAccountNumber">IBAN Number</label>
        //                         <input 
        //                             type="text" 
        //                             id="bankAccountNumber" 
        //                             value={location?.state?.data?.bankAccountNumber}
        //                             readOnly
        //                         />
        //                     </div>

        //                     {/* <div className="col-span-2">
        //                         <label htmlFor="address">Address</label>
        //                         <input 
        //                             type="text" 
        //                             id="address" 
        //                             value={location?.state?.data?.mainBranchAddress}
        //                         />
        //                     </div> */}

        //                 </div>

        //                 {auth?.role === 'SUPER_ADMIN' ? 
        //                     <div className="restaurant-submit-btn-wrapper mt-10 flex gap-x-8 justify-end [&>button]:px-5 [&>button]:py-2">
        //                         {location?.state?.type === 'request' ?
        //                             <>
        //                                 <Button onClick={()=>toggleRestaurantHandler('REJECTED')} disabled={singleRestaurant?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainRed text-white text-sm font-semibold rounded-md">Decline</Button>
        //                                 <Button onClick={()=>toggleRestaurantHandler('APPROVED')} disabled={singleRestaurant?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainGreen text-white text-sm font-semibold rounded-md">Approve</Button>
        //                             </>
        //                         : location?.state?.type === 'rejected' || location?.state?.type === 'view' ? 
        //                             null

        //                         :null }
        //                     </div>

        //                     : null }

        //             </div>
        //         </section>
        //     </div>

        //     <Modal
        //         open={open}
        //         onClose={handleClose}
        //         aria-labelledby="modal-modal-title"
        //         aria-describedby="modal-modal-description"
        //     >
        //     <Box sx={style}>
        //         <img src={modalImg} alt={`imgae-${modalImg}`} className="w-full h-full" />
        //     </Box>
        // </Modal>

        // </article>

        <article className="restaurant-request-v2 py-3">
            <section className="restaurant-header">
                <div className="profile-title text-[#333333] font-bold capitalize text-3xl mb-3 font-playfair">
                    <h2 className="font-playfair">{t('restaurants.restaurant_details.title')}</h2>
                </div>

                <div><BreadCrumb paths={paths} seperator='/' /></div>
            </section>

            <section className="restaurant-request-details-wrapper flex justify-between items-start gap-3">
                <div className="basis-full md:basis-[49%]">
                    <section className="info-wrapper flex flex-col gap-4">

                        <div className="restaurant-info-wrapper p-4 rounded-md bg-white">
                            <h2 className="mb-4 capitalize font-semibold text-[#333]">{t('restaurants.restaurant_details.restaurant_info')}</h2>

                            <div className="res-details-wrapper flex gap-x-4 items-start">
                                <div className="restaurant-logo basis-full md:basis-[25%] w-[145px] h-[145px] rounded-[50%] overflow-hidden">
                                    {location?.state?.data?.logoUrl && <img src={location?.state?.data?.logoUrl} onClick={(e) => handleOpen(e)} alt="restaurant Profile" className="w-full h-full object-cover cursor-pointer rounded-[50%]" />}
                                </div>

                                <div className="restaurant-info flex flex-col gap-2 grow">

                                    <div className="flex gap-x-2 items-center mb-2">
                                        <div className="">
                                            <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><HiOutlineUser className="m-auto text-white text-lg" /></span>
                                        </div>
                                        <div className="">
                                            <p className="text-[#B5B5C3] text-[12px] capitalize">{t('restaurants.form.name_en')}</p>
                                            <p className="text-[#464E5F] text-sm font-semibold">{location?.state?.data?.restaurantName ? location?.state?.data?.restaurantName : 'Restaurant English Name Not Found'}</p>
                                        </div>
                                    </div>

                                    <div className="flex gap-x-2 items-center mb-2">
                                        <div className="">
                                            <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><HiOutlineUser className="m-auto text-white text-lg" /></span>
                                        </div>
                                        <div className="">
                                            <p className="text-[#B5B5C3] text-[12px] capitalize">{t('restaurants.form.name_ar')}</p>
                                            <p className="text-[#464E5F] text-sm font-semibold">{location?.state?.data?.nameAr ? location?.state?.data?.nameAr : 'Restaurant Arabic Name Not Found'}</p>
                                        </div>
                                    </div>

                                    <div className="flex gap-x-2 items-center mb-2">
                                        <div className="">
                                            <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><MdAlternateEmail className="m-auto text-white text-lg" /></span>
                                        </div>
                                        <div className="">
                                            <p className="text-[#B5B5C3] text-[12px] capitalize">{t('restaurants.form.manage_email')}</p>
                                            <p className="text-[#464E5F] text-sm font-semibold">{location?.state?.data?.restaurantEmail ? location?.state?.data?.restaurantEmail : 'Management Email Name Not Found'}</p>
                                        </div>
                                    </div>

                                    <div className="flex gap-x-2 items-center mb-2">
                                        <div className="">
                                            <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto text-white text-lg" /></span>
                                        </div>
                                        <div className="">
                                            <p className="text-[#B5B5C3] text-[12px] capitalize">{t('restaurants.form.manage_phone')}</p>
                                            <p className="text-[#464E5F] text-sm font-semibold">{location?.state?.data?.restaurantPhoneNumber ? location?.state?.data?.restaurantPhoneNumber : 'Management Phone Name Not Found'}</p>
                                        </div>
                                    </div>

                                    <div className="flex gap-x-2 items-center mb-2">
                                        <div className="">
                                            <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><MdAlternateEmail className="m-auto text-white text-lg" /></span>
                                        </div>
                                        <div className="">
                                            <p className="text-[#B5B5C3] text-[12px] capitalize">{t('restaurants.form.markerting_email')}</p>
                                            <p className="text-[#464E5F] text-sm font-semibold">{location?.state?.data?.marketingEmail ? location?.state?.data?.marketingEmail : 'Marketing Email Name Not Found'}</p>
                                        </div>
                                    </div>

                                    <div className="flex gap-x-2 items-center mb-2">
                                        <div className="">
                                            <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto text-white text-lg" /></span>
                                        </div>
                                        <div className="">
                                            <p className="text-[#B5B5C3] text-[12px] capitalize">{t('restaurants.form.markerting_phone')}</p>
                                            <p className="text-[#464E5F] text-sm font-semibold">{location?.state?.data?.marketingPhoneNumber ? location?.state?.data?.marketingPhoneNumber : 'Marketing Phone Name Not Found'}</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="general-info-wrapper p-4 rounded-md bg-white">
                            <h2 className="mb-4 capitalize font-semibold text-[#333]">{t('restaurants.restaurant_details.g_info')}</h2>

                            <div className="flex flex-col gap-2">

                                <div className="flex gap-x-2 items-center mb-2">
                                    <div className="">
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><PiWarningCircleFill className="m-auto text-white text-lg" /></span>
                                    </div>
                                    <div className="">
                                        <p className="text-[#B5B5C3] text-[12px] capitalize">{t('restaurants.form.commerical_no')}.</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{location?.state?.data?.commercialRegisterationNumber ? location?.state?.data?.commercialRegisterationNumber : 'Restaurant English Name Not Found'}</p>
                                    </div>
                                </div>

                                <div className="flex gap-x-2 items-center mb-2">
                                    <div className="">
                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><PiWarningCircleFill className="m-auto text-white text-lg" /></span>
                                    </div>
                                    <div className="">
                                        <p className="text-[#B5B5C3] text-[12px] capitalize">{t('restaurants.form.iban_no')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{location?.state?.data?.bankAccountNumber ? location?.state?.data?.bankAccountNumber : 'Restaurant English Name Not Found'}</p>
                                        {/* <p className="text-[#B5B5C3] text-[12px] capitalize">IBAN Number</p> */}
                                        {/* <p className="text-[#464E5F] text-sm font-semibold">{location?.state?.data?.bankAccountNumber ? location?.state?.data?.bankAccountNumber : 'Bank Account Number Not Found'}</p> */}
                                    </div>
                                </div>

                                <div className="flex gap-x-2 items-center mb-2">
                                    <div className="">

                                        <span className="w-8 h-8 rounded-[50%] bg-mainYellow text-center flex items-center"><TbReceiptTax className="m-auto text-white text-lg" /></span>
                                    </div>
                                    <div className="">
                                        <p className="text-[#B5B5C3] text-[12px] capitalize">{t('restaurants.form.vat')}</p>
                                        <p className="text-[#464E5F] text-sm font-semibold">{location.state?.data?.vatNumber ? location.state?.data?.vatNumber : 'Vat Number Not Found'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>

                <div className="attachments-wrapper basis-full md:basis-[49%]">
                    <div className="attachmenets-images bg-white p-4 rounded-md flex flex-wrap items-center justify-between gap-5 [&>div]:basis-[47%] [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:text-sm [&>div>label]:font-semibold [&>div>label]:capitalize">
                        <h2 className="mb-1 capitalize font-semibold text-[#333] basis-full">{t('restaurants.restaurant_details.attachments')}</h2>

                        <div className="commerical-attach">
                            <label>{t('restaurants.form.commerical_letter')}.</label>
                            <div className="rounded-md overflow-hidden [&>div>img]:w-full [&>div>img]:h-[150px] [&>div>img]:object-cover relative">
                                <div className="relative">
                                    {(getFileType(location?.state?.data?.commercialRegisterationLetter) !== 'pdf') ?
                                        <img src={location?.state?.data?.commercialRegisterationLetter} alt="Commerical Registeration Letter" />
                                        :
                                        <img src={pdfIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" />
                                    }
                                    <div onClick={() => {
                                        if (location?.state?.data?.commercialRegisterationLetter) {
                                            downloadImage(location?.state?.data?.commercialRegisterationLetter)
                                        }
                                    }} className="absolute w-full h-full cursor-pointer bg-[rgba(0,0,0,.4)] top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                        <span className="bg-[#BDAAE9] rotate-45 p-2 rounded-[50%] w-fit block"><MdOutlineAttachFile className="text-mainColor w-6 h-6" /></span>
                                    </div>
                                </div>
                                <Button disabled={!location?.state?.data?.commercialRegisterationLetter} onClick={() => downloadImage(location?.state?.data?.commercialRegisterationLetter)} className=" disabled:pointer-events-auto disabled:bg-[#808080] disabled:bg-opacity-40 disabled:cursor-not-allowed py-1 px-2 rounded-none rounded-bl-md rounded-br-md text-sm font-semibold cursor-pointer bg-white border-solid border border-[#ddd] flex justify-between items-center w-full"><span className="text-black capitalize">{location?.state?.data?.commercialRegisterationLetter ? 'Commerical letter. PDF' : t('common.notFound')}</span> <span><IoCloudDownloadOutline className="w-5 h-5 text-mainColor" /></span></Button>
                            </div>
                        </div>

                        <div className="iban-attach">
                            <label>{t('restaurants.form.iban_letter')}.</label>
                            <div className="rounded-md overflow-hidden [&>div>img]:w-full [&>div>img]:h-[150px] [&>div>img]:object-cover relative">
                                <div className="relative">
                                    {(getFileType(location?.state?.data?.ibanLetter) !== 'pdf') ?
                                        <img src={location?.state?.data?.ibanLetter} alt="IBAN Registeration Letter" />
                                        :
                                        <img src={pdfIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" />
                                    }
                                    <div onClick={() => {
                                        if (location?.state?.data?.ibanLetter) {
                                            downloadImage(location?.state?.data?.ibanLetter)
                                        }
                                    }} className="absolute w-full h-full cursor-pointer bg-[rgba(0,0,0,.4)] top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                        <span className="bg-[#BDAAE9] rotate-45 p-2 rounded-[50%] w-fit block"><MdOutlineAttachFile className="text-mainColor w-6 h-6" /></span>
                                    </div>
                                </div>
                                <Button disabled={!location?.state?.data?.ibanLetter} onClick={() => downloadImage(location?.state?.data?.ibanLetter)} className="disabled:pointer-events-auto disabled:bg-[#808080] disabled:bg-opacity-40 disabled:cursor-not-allowed py-1 px-2 rounded-none rounded-bl-md rounded-br-md text-sm font-semibold cursor-pointer bg-white border-solid border border-[#ddd] flex justify-between items-center w-full"><span className="text-black">{location?.state?.data?.ibanLetter ? 'IBAN. PDF' : t('common.notFound')}</span> <span><IoCloudDownloadOutline className="w-5 h-5 text-mainColor" /></span></Button>
                            </div>
                        </div>

                        <div className="vat-attach">
                            <label>{t('restaurants.form.vat_letter')}.</label>
                            <div className="rounded-md overflow-hidden [&>div>img]:w-full [&>div>img]:h-[150px] [&>div>img]:object-cover relative">
                                <div className="relative">
                                    {(getFileType(location?.state?.data?.vatCertificate) !== 'pdf') ?
                                        <img src={location?.state?.data?.vatCertificate} alt="Commerical Registeration Letter" />
                                        :
                                        <img src={pdfIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" />
                                    }
                                    <div onClick={() => {
                                        if (location?.state?.data?.vatCertificate) {
                                            downloadImage(location?.state?.data?.vatCertificate)
                                        }
                                    }} className="absolute w-full h-full cursor-pointer bg-[rgba(0,0,0,.4)] top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                        <span className="bg-[#BDAAE9] rotate-45 p-2 rounded-[50%] w-fit block"><MdOutlineAttachFile className="text-mainColor w-6 h-6" /></span>
                                    </div>
                                </div>
                                <Button disabled={!location?.state?.data?.vatCertificate} onClick={() => downloadImage(location?.state?.data?.vatCertificate)} className="disabled:pointer-events-auto disabled:bg-[#808080] disabled:bg-opacity-40 disabled:cursor-not-allowed py-1 px-2 rounded-none rounded-bl-md rounded-br-md text-sm font-semibold cursor-pointer bg-white border-solid border border-[#ddd] flex justify-between items-center w-full"><span className="text-black">{location?.state?.data?.vatCertificate ? 'VAT. PDF' : t('common.notFound')}</span> <span><IoCloudDownloadOutline className="w-5 h-5 text-mainColor" /></span></Button>

                            </div>
                        </div>

                        <div className="contract-attach">
                            <label>{t('common.contract')}.</label>
                            <div className="rounded-md overflow-hidden [&>div>img]:w-full [&>div>img]:h-[150px] [&>div>img]:object-cover relative">
                                <div className="relative">
                                    {(getFileType(location?.state?.data?.contract) !== 'pdf') ?
                                        <img src={location?.state?.data?.contract} alt="Commerical Registeration Letter" />
                                        :
                                        <img src={pdfIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" />
                                    }
                                    <div onClick={() => {
                                        if (location?.state?.data?.contract) {
                                            downloadImage(location?.state?.data?.contract)
                                        }
                                    }} className="absolute w-full h-full cursor-pointer bg-[rgba(0,0,0,.4)] top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                        <span className="bg-[#BDAAE9] rotate-45 p-2 rounded-[50%] w-fit block"><MdOutlineAttachFile className="text-mainColor w-6 h-6" /></span>
                                    </div>
                                </div>
                                <Button disabled={!location?.state?.data?.contract} onClick={() => downloadImage(location?.state?.data?.contract)} className="disabled:pointer-events-auto disabled:bg-[#808080] disabled:bg-opacity-40 disabled:cursor-not-allowed py-1 px-2 rounded-none rounded-bl-md rounded-br-md text-sm font-semibold cursor-pointer bg-white border-solid border border-[#ddd] flex justify-between items-center w-full"><span className="text-black">{location?.state?.data?.contract ? 'Contract. PDF' : t('common.notFound')}</span> <span><IoCloudDownloadOutline className="w-5 h-5 text-mainColor" /></span></Button>

                            </div>
                        </div>
                    </div>

                    <div className="takein-share-wrapper bg-white p-4 rounded-md mt-3">
                        <h2 className="mb-1 capitalize font-semibold text-[#333] basis-full">{localStorage.getItem('lang') === 'ar' ? 'Takein حصة' : 'Takein Share'}</h2>

                        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            <div>
                                <label>{t('restaurants.form.takein_share')} <Astrisks /></label>
                                <input type="text" id="takeinSharePerOrder"
                                    disabled={location?.state?.type !== 'request' || auth?.role !== 'SUPER_ADMIN'}
                                    {...register('takeinSharePerOrder', {
                                        required: {
                                            value: true,
                                            message: t('common.required')
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                        },
                                        // valueAsNumber: true
                                    })}

                                    onChange={(e) => {
                                        if (e.target.value || !e.target.value) {
                                            setValue('takeinSharePerOrder', e.target.value, { shouldValidate: true });
                                        }
                                    }}
                                />

                                {errors?.takeinSharePerOrder?.message ? <p className="capitalize text-sm text-danger">{errors?.takeinSharePerOrder?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.takein_share_helper')}</p>}
                            </div>

                            <div className="[&>div]:bg-[#f3f6f9] [&>div]:rounded-md">
                                <label> {t('restaurants.form.takein_type')} <Astrisks /></label>
                                <FormControl sx={{ width: '100%' }}>
                                    <Controller
                                        control={control}
                                        name="takeinSharePerOrderType"
                                        value={takeInShareType}
                                        rules={{ required: t('common.required') }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <Select {...field}
                                                    disabled={location?.state?.type !== 'request'}
                                                    displayEmpty
                                                    inputRef={{ ...register('takeinSharePerOrderType') }}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="takeinSharePerOrderType"
                                                    value={takeInShareType}
                                                    onChange={handleOfferTypeSelectChange}
                                                    // renderValue={(selected) => selected.join(', ')}
                                                    readOnly={auth?.role !== 'SUPER_ADMIN'}
                                                    sx={{ '& .MuiOutlinedInput-input': { padding: '11px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' }, '& .css-tzsjye': { backgroundColor: '#f3f6f9' } }}
                                                >

                                                    <MenuItem value={'AMOUNT'}>
                                                        <ListItemText primary={'Amount'} />
                                                    </MenuItem>

                                                    <MenuItem value={'PERCENTAGE'}>
                                                        <ListItemText primary={'Percentage'} />
                                                    </MenuItem>
                                                </Select>
                                            </>
                                        )}

                                    />

                                </FormControl>

                                {location?.state?.type === 'request' ? errors?.takeinSharePerOrderType?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.takeinSharePerOrderType?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">{t('restaurants.form.takein_type_helper')}</p> : null}
                            </div>
                        </div>
                    </div>
                </div>


            </section>

            <section>
                {auth?.role === 'SUPER_ADMIN' ?
                    <div className={`restaurant-submit-btn-wrapper mt-6 flex gap-x-8 justify-end [&>button]:px-8 [&>button]:py-2 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        {location?.state?.type === 'request' ?
                            <>
                                <Button onClick={() => toggleRestaurantHandler('REJECTED')} disabled={singleRestaurant?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-transparent text-mainRed border-[1px] border-solid border-mainRed text-sm capitalize font-semibold rounded-sm transition-all duration-300 hover:bg-mainRed hover:text-white">{localStorage.getItem('lang') === 'ar' ? 'رفض' : 'Decline'}</Button>
                                <Button onClick={() => toggleRestaurantHandler('APPROVED')} disabled={singleRestaurant?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-[#5CAC7D] text-white text-sm capitalize font-semibold rounded-sm">{localStorage.getItem('lang') === 'ar' ? 'قبول' : 'Approve'}</Button>
                            </>
                            : location?.state?.type === 'rejected' || location?.state?.type === 'view' ?
                                null

                                : null}
                    </div>

                    : null}
            </section>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={modalImg} alt={`imgae-${modalImg}`} className="w-full h-full object-fill" />
                </Box>
            </Modal>
        </article>
    );
}

export default RestaurantDetails;