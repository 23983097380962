import { BsCurrencyDollar } from "react-icons/bs";
import { MdOutlineFoodBank } from "react-icons/md";
import { FaUserClock } from "react-icons/fa";
import { FaTruck } from "react-icons/fa";
import { useEffect, useState } from "react";
import ReportLeft from "./reports-components/Report-left/reportLeft";
// import ReportSummary from "./reports-components/Report-left/reportSummary";
import ReportRevenue from "./reports-components/Report-right/reportRightRevenue";
// import WeeklyStats from "../Components/Dashboard-Components/dashWekklyStats";
import ReportWeeklyStats from "./reports-components/Report-right/reportRightSales";
import { useDispatch, useSelector } from "react-redux";
import { getIncome, getRevenue, getStatistics } from "../../Components/Redux/Slice/Report/report";
import Overlay from "../../Components/Common/overlay";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Reports = () => {
    const { t, i18n } = useTranslation()
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch()

    const { report } = useSelector(state => state)

    const revenues = useSelector(state => state?.report)

    useEffect(() => {
        let dateRange = 'last_7_days'
        const reportCall = async () => {
            await dispatch(getStatistics({ dateRange })).then((e) => {
                // if(e?.payload?.message === 'SUCCESS'){
                //     settotalDelivery(e?.payload?.data?.totalDeliveryOrders)
                //     settotalCurpside(e?.payload?.data?.totalCurbsidePickupOrders)
                // }
            })
        }
        reportCall()
    }, [dispatch])

    useEffect(() => {
        let dateRange = 'daily'
        let incomeRange = "LAST_WEEK"
        dispatch(getRevenue({ dateRange }))
        dispatch(getIncome({ incomeRange }))
    }, [dispatch])

    const btns = [
        { label: 'last week', labelAr: "اخر أسبوع", param: 'last_7_days' },
        { label: 'last month', labelAr: "اخر شهر", param: 'last_30_days' },
        { label: 'last 5 months', labelAr: "اخر 5 أشهر", param: 'last_5_months' },
    ]

    const filterClickHandler = (item, index) => {
        setActiveTab(index)
        let dateRange = item?.param
        dispatch(getStatistics({ dateRange }))

        if (item?.param === 'last_7_days') {
            let dateRange = 'daily'
            let incomeRange = 'LAST_WEEK'
            dispatch(getRevenue({ dateRange }))
            dispatch(getIncome({ incomeRange }))
        } else if (item?.param === 'last_30_days') {
            let dateRange = 'weekly'
            let incomeRange = 'LAST_MONTH'
            dispatch(getRevenue({ dateRange }))
            dispatch(getIncome({ incomeRange }))
        } else if (item?.param === 'last_5_months') {
            let dateRange = 'monthly'
            let incomeRange = 'LAST_FIVE_MONTHS'
            dispatch(getRevenue({ dateRange }))
            dispatch(getIncome({ incomeRange }))
        }
        // console.log(index);
    }

    // console.log(revenues?.revenues?.data);
    function filterize(values) {
        return values?.filter(value => !isNaN(value));
    }

    let totalValues = [...(revenues?.revenues?.data?.data?.map((item) => parseInt(item?.restaurantShare)) || []), ...(revenues?.revenues?.data?.data?.map((item) => parseInt(item?.takeinShare)) || [])]
    let filteredTotalValues = filterize(totalValues);

    // chart
    const options = {
        chart: {
            type: 'bar',
            height: '100%',
            //   dropShadow: {
            //     enabled: true,
            //     color: '#000',
            //     top: 18,
            //     left: 7,
            //     blur: 10,
            //     opacity: 0.2
            //   },
            // this is used for display zoom buttons
            toolbar: {
                show: true
            }
        },
        colors: ['#5CAC7D', '#D9AE56'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 4,
            curve: 'smooth'
        },
        title: {
            text: '',
            show: false, // Hide the chart title
            floating: true,
            align: 'center',
            margin: 35,
            offsetY: 20,
            style: {
                fontSize: '25px'
            }
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.4
            },
        },
        markers: {
            size: 0 // make dots appears on the line to specify where the data is
        },
        xaxis: {
            categories: revenues?.revenues?.data?.data?.map((item) => item?.date) || [],
            // title: {
            // text: 'Month'
            // }
            labels: {
                // trim : true
                style: {
                    fontSize: '11px',
                    color: "#ddd",
                },
            },
            // axisBorder: {
            //     show: true,
            //     color: '#78909c',
            //     height: 1,
            //     width: '100%',
            //     offsetX: 0,
            //     offsetY: 0
            //   },
        },
        yaxis: {
            labels: {
                align: 'center'
            },
            opposite: localStorage.getItem('lang') === 'ar' ? true : false,
            min: Math?.min(...filteredTotalValues),
            max: Math?.max(...filteredTotalValues),
            tickAmount: 5, // define the number of rows on the y axis
            tickPlacment: 'between'
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            floating: true,
            offsetY: -20,
            offsetX: -25,
            itemMargin: {
                horizontal: 30, // Set the horizontal margin between legend items to 10 pixels
                // vertical: 0, // Set the vertical margin between legend items to 0 pixels
            },
            style: {
                display: 'flex'
            },
            markers: {
                radius: 0, // Set the radius of the legend markers to 0
                height: 15,
                width: 15,
                offsetX: localStorage?.getItem('lang') === 'ar' ? -5 : 0,
                offsetY: 0,
                shape: 'square', // Set the shape of the legend markers to "square"
            }
        }
    };

    const series = [
        {
            name: t("dashboard.revenue.title"),
            data: revenues?.revenues?.data?.data?.map((item) => item?.restaurantShare) || []
        },
        {
            name: t("dashboard.revenue.chart.takeinShare"),
            data: revenues?.revenues?.data?.data?.map((item) => item?.takeinShare) || []
        }
    ]

    // console.log(revenues?.revenues?.data?.data);
    // const handleStaticDataChart = (item,index)=>{
    //     console.log(item);
    //     setActiveTab(index)
    //     if(item?.label === 'today'){
    //         setdata1([10,20,50,70,80,100,0]) 
    //         setdata2([0,50,10,170,80,100,35])
    //     } else if(item?.label === 'weekly'){
    //         setdata1([28, 45, 70, 36, 2, 90, 33]) 
    //         setdata2([12, 19, 11, 31, 80, 13, 0])
    //     } else if(item?.label === 'monthly'){
    //         setdata1([0, 50, 10, 30, 200, 90, 33]) 
    //         setdata2([1, 50, 11, 83, 80, 13, 0])
    //     }
    // }

    // useEffect(() => {

    // }, [data1,data2])



    const statusAnalytics = [
        { id: 1, name: 'on delivery', count: report?.statistics?.data?.outForDeliveryOrders, color: '#5CAC7D' },
        { id: 2, name: 'delivered', count: report?.statistics?.data?.deliveredOrders, color: '#442B7E' },
        // {id:2,name:'reservation', count: activeTab === 0 ? '80' : activeTab === 1 ? '10' : '63', color:'#D9AE56'},
        { id: 3, name: 'canceled', count: report?.statistics?.data?.cancelledOrders, color: '#C6345C' },
    ]

    let takeinShare = revenues?.revenues?.data?.data?.reduce((acc, obj) => {
        return obj?.takeinShare ? parseInt(acc) + parseInt(obj?.takeinShare) : acc;
    }, 0);


    let restaurantShare = revenues?.revenues?.data?.data?.reduce((acc, obj) => {
        return obj?.restaurantShare ? parseInt(acc) + parseInt(obj?.restaurantShare ? obj?.restaurantShare : 0) : acc
    }, 0)


    // custom reports

    const customReportsList = [
        {
            id: 'delivery_reports', name: t('reports.customReports.deliveryReport'), subTitle: t('reports.customReports.subTextDelivery'), href: "/dashboard/reports/deliveryMetrics", icon: <FaTruck className="text-[#ffc635] " />
        },
        {
            id: 'orders_reports', name: t('reports.customReports.orderReport'), subTitle: t('reports.customReports.subTextOrder'), href: "#", icon: <MdOutlineFoodBank className="text-[#302b63]" />
        },
        {
            id: 'customer_experince_reports', name: t('reports.customReports.customerExperience'), subTitle: t('reports.customReports.subTextCustomerExperience'), href: "#", icon: <FaUserClock className="text-[#579DF3]" />
        },
        {
            id: 'financial_reports', name: t('reports.customReports.financialReport'), subTitle: t('reports.customReports.subTextFinancial'), href: "#", icon: <BsCurrencyDollar className="text-[#1f4037]" />
        },
    ]

    return (
        <article className="reports-wrapper py-3">
            <section className="report-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('reports.title')}</h1>
            </section>

            <section className="report-control-wrapper flex flex-wrap items-center mt-5 w-full">

                <div className="report-filter-wrapper flex justify-evenly [&>div]:mr-5">
                    <div className="px-1 py-1 bg-[#ffffff] rounded-sm">
                        {btns?.map((item, index) => {
                            return (
                                <button key={index} className={`capitalize font-bold text-sm text-[#999999] px-4 py-2 lg:px-3 xl:px-6 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-[#F4F6FC] text-black' : null}`}
                                    onClick={() => filterClickHandler(item, index)}
                                >
                                    {localStorage?.getItem('lang') === 'ar' ? item.labelAr : item.label}
                                </button>
                            )
                        })}
                    </div>
                </div>
            </section>

            <div className="reports-statistics-wrapper mt-6">
                <div className="flex flex-wrap lg:flex-nowrap gap-5 justify-between items-start">

                    <section className="left-report-section basis-full lg:basis-3/4 ">

                        <div className="report-left-chart bg-white p-3 rounded-md min-h-[680px]">
                            <ReportLeft t={t} series={series} options={options} totalSales={revenues?.revenues?.data?.totalSales} takeinShare={takeinShare} restaurantShare={restaurantShare} activeTab={activeTab} />
                        </div>

                        {/* <div className="report-left-status mt-5 bg-white rounded-md p-3">
                            <ReportSummary status={statusAnalytics} report={report} />
                        </div> */}

                    </section>

                    <section className="right-report-section basis-full lg:basis-1/4">

                        <div className="report-right-total-revenue w-full">
                            <ReportRevenue t={t} />
                        </div>

                        <div className="report-right-sales-stats mt-5 bg-white p-3 py-4 rounded-lg">
                            <ReportWeeklyStats t={t} />
                        </div>
                    </section>

                </div>
            </div>

            <section className="metrics-wrapper mt-6 bg-white p-3 rounded-md">
                <h2 className="font-bold text-xl text-[#464E5F] capitalize">{t('reports.customReports.title')}</h2>

                <div className={`mt-6 w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 [&>div]:w-full [&>div]:h-full [&>div>a]:min-h-[220px] [&>div>a]:w-full [&>div>a]:h-full [&>div>a]:rounded-lg [&>div>a]:p-3 [&>div>a]:relative [&>div>a]:block [&>div>a>h2]:text-[25px] [&>div>a>h2]:leading-7 [&>div>a>h2]:capitalize [&>div>a>h2]:font-bold [&>div>a>h2]:mt-3 [&>div>a>h2]:max-w-full [&>div>a>h2]:md:max-w-[85%] [&>div>a>span]:text-[11px] [&>div>a>span]:text-[#f0f0f0] [&>div>a>span]:capitalize [&>div>a>aside]:absolute [&>div>a>aside]:bottom-0 ${localStorage.getItem('lang') === 'ar' ? '[&>div>a>aside]:left-1' : '[&>div>a>aside]:right-1'} [&>div>a>aside>svg]:w-[100px] [&>div>a>aside>svg]:h-[100px]`}>
                    {
                        customReportsList?.map(item => (
                            <div key={item?.id} >
                                <Link to={item?.href}
                                    className={`${item?.id}-reports-wrapper bg-gradient-to-r text-white
                                    ${item?.id === 'delivery_reports' ? `${localStorage.getItem('lang') === 'ar' ? 'from-[#ffe270] to-[#ffc940]' : 'from-[#ffc940] to-[#ffe270]'}` :
                                            item?.id === 'orders_reports' ? `${localStorage.getItem('lang') === 'ar' ? 'from-[#5b53b2] to-[#3d3d6b]' : 'from-[#3d3d6b] to-[#5b53b2]'}` :
                                                item?.id === 'financial_reports' ? `${localStorage.getItem('lang') === 'ar' ? 'from-[#99f2c8] to-[#1f4037]' : 'from-[#1f4037] to-[#99f2c8]'}` :
                                                    item?.id === 'customer_experince_reports' ? `${localStorage.getItem('lang') === 'ar' ? 'from-[#84BBF3] to-[#579DF3]' : 'from-[#579DF3] to-[#84BBF3]'}` :
                                                        ''} 
                                    `}
                                >
                                    <span>{item?.subTitle}</span>
                                    <h2>{item?.name}</h2>
                                    <aside>
                                        {item?.icon}
                                    </aside>
                                </Link>
                            </div>
                        ))
                    }

                </div>
            </section>

            {report?.loading ? <Overlay /> : null}
        </article>
    );
}

export default Reports;