import { FormControl, IconButton, InputAdornment, OutlinedInput } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { resetPage } from "../Redux/Slice/ResetPagination/resetPagination"
import { CiSearch } from "react-icons/ci"

export const useSearchBox = (func, placeholder) => {
    const page = 1,
        size = 10;

    const dispatch = useDispatch()
    const [debounceValue, setdebounceValue] = useState()
    const [searchValue, setsearchValue] = useState()
    const { search } = useSelector((state) => state?.resetPagination);

    useEffect(() => {
        const delayHandler =
            setTimeout(() => {
                setsearchValue(debounceValue)
            }, 400)

        return () => clearTimeout(delayHandler)
    }, [debounceValue])

    useEffect(() => {
        setsearchValue('')
        setdebounceValue('')
    }, [search])

    const handleReset = () => {
        // dispatch(func({ page, size })).then(() => dispatch(resetPage()))
        setsearchValue('')
        setdebounceValue('')
    }

    const handleSearch = () => {
        dispatch(func({ searchValue })).then(() => dispatch(resetPage()))
    }


    const searchInput = () => (
        <FormControl
            className="search-form [&>div>input]:py-[.6rem]"
            sx={{ width: '35ch', backgroundColor: 'white', borderRadius: '6px', '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': { padding: '10px' }, '& fieldset': { borderColor: 'primary.main', border: 'none' } }} variant="outlined">
            <OutlinedInput
                type="search"
                id="outlined-adornment-search"
                placeholder={placeholder}
                value={debounceValue}
                onChange={(e) => {
                    setdebounceValue(e.target.value)
                    dispatch(resetPage())
                    if (!e.target.value) {
                        handleReset();
                        dispatch(resetPage())
                    }
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <IconButton
                            aria-label="search"
                            onClick={handleSearch}
                            edge="start"
                        >
                            <CiSearch />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )

    return { searchInput, searchValue }
}