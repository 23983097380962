import { Button } from "@mui/material"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getVariables, sendVariables } from "../../Components/Redux/Slice/AdminSetting/adminSettingSlice"
import Overlay from "../../Components/Common/overlay"

export default function AdminSettings() {
    const { t } = useTranslation()
    const { register, setValue, getValues, formState: { errors }, handleSubmit } = useForm()

    const dispatch = useDispatch()
    const { variables: { data }, loading } = useSelector(state => state?.adminSetting)


    useEffect(() => {
        dispatch(getVariables())

        if (data && Array.isArray(data)) {
            data.forEach(item => {
                const variableName = item.variableName;
                const variableValue = item.variableValue;
                setValue(variableName, variableValue);
            });
        }
    }, [dispatch, setValue])



    const handleFormSubmit = (values) => {

        let val = {
            variableName: "CUSTOMER_GEOFENCING_RADIUS",
            variableValue: values?.CUSTOMER_GEOFENCING_RADIUS
        }

        dispatch(sendVariables(val))

    }

    // console.log(getValues());

    return (
        <main className="admin-settings-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('common.admin_settings')}</h1>
            </section>

            <section className="admin-setting-form-wrapper">
                <form onSubmit={handleSubmit(handleFormSubmit)}
                    className="admin-setting-form grid grid-cols-2 md:grid-cols-3 gap-4 [&>div>label]:block [&>div>label]:capitalize [&>div>label]:font-semibold [&>div>label]:mb-2 [&>div>label]:text-[#444] [&>div>label]:text-lg [&>div>input]:w-full [&>div>input]:md:w-[80%] [&>div>input]:bg-[#f3f6f9] [&>div>input]:text-mainColor [&>div>input]:p-2 [&>div>input]:rounded-md [&>div>input]:focus-within:outline-none [&>div>input]:transition-all [&>div>input]:duration-300 [&>div>input]:focus-within:shadow-sm [&>div>input]:focus-within:shadow-[#b8daf0b2] [&>div>p]:text-xs [&>div>p]:mt-1 [&>div>p]:text-mainRed [&>div>p]:capitalize"
                >
                    {data?.map((variable, index) => (
                        <div key={variable?.variableName}>
                            <label>{variable?.variableName?.replace(/_/g, " ").toLowerCase()}</label>
                            <input type="text"
                                id={variable?.variableName}
                                {...register(variable?.variableName, {
                                    required: {
                                        value: true,
                                        message: t('common.required')
                                    }
                                })}
                                defaultValue={variable?.variableValue}
                                className="placeholder:capitalize"
                                placeholder={variable?.variableName?.replace(/_/g, " ").toLowerCase()}
                            />

                            <p>{errors && errors?.[variable?.variableName] && errors?.[variable?.variableName]?.message}</p>
                        </div>
                    ))}

                    <div className="submit-btn col-span-full text-center">
                        <Button type="submit" className="min-w-[200px] capitalize text-white p-3 bg-mainColor text-lg">{t('common.submit')}</Button>
                    </div>
                </form>
            </section>

            {loading ? <Overlay /> : null}
        </main>
    )
} 