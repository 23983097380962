import { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import '../../Assets/style/style.scss'
import { ListItemButton } from "@mui/material";
// import OrderNotification from "./OrdersNotifications/orderNotify";
import { useDispatch, useSelector } from "react-redux";
// import { openHandler } from "../Redux/Slice/common/overlaySlice";
import { getRidersStatistics } from "../Redux/Slice/Report/report";
import { Link } from "react-router-dom";
import { tabHandler } from "../Redux/Slice/common/tabSlice";
import NumberCounter from "../Common/counterEffect";
const RidersSummary = ({ t }) => {

    const [activeTab, setActiveTab] = useState(0);

    const btns = [
        { label: 'last week', labelAr: "اخر أسبوع", param: 'last_7_days' },
        { label: 'last month', labelAr: "اخر شهر", param: 'last_30_days' },
        { label: 'last 5 months', labelAr: "اخر 5 أشهر", param: 'last_5_months' },
    ]

    // const [showOrders,setshowOrders] = useState(false)

    // const {orderNotify} = useSelector(state=>state.overlay)

    const dispatch = useDispatch()
    // const showOrdersHandler = ()=>{
    //     // setshowOrders(!showOrders)
    //     dispatch(openHandler({orderNotification:true}))
    // }

    useEffect(() => {
        let dateRange = 'last_7_days'
        dispatch(getRidersStatistics({ dateRange }))
    }, [dispatch])


    const { report } = useSelector(state => state)

    const filterClickHandler = (index, item) => {
        setActiveTab(index)
        let dateRange = item?.param
        dispatch(getRidersStatistics({ dateRange }))
    }

    const statusAnalytics = [
        { id: 1, name: t("common.request"), count: report?.drivers?.data?.totalOnHoldDrivers, color: '#442B7E' },
        { id: 2, name: t("common.active"), count: report?.drivers?.data?.totalActiveDrivers, color: '#5CAC7D' },
        { id: 3, name: t("common.cancel"), count: report?.drivers?.data?.totalRejectedDrivers, color: '#C6345C' },
    ]

    const changeTabHandler = () => {
        // setshowOrders(!showOrders)
        dispatch(tabHandler({ tabs: 'REQUESTS', index: 1 }))
    }
    // console.log(report?.drivers);

    return (
        <article className="order-summary bg-white p-3 py-5 rounded-md shadow-sm h-full">
            <section className="order-summary-title flex justify-between items-center">
                <div>
                    <h2 className='font-bold text-sm lg:text-lg text-[#464E5F] capitalize'>{t("dashboard.riders.title")}</h2>
                    {/* <p className="font-medium text-[12px] text-[#B5B5C3]">890,344 Sales</p> */}
                </div>

                <div className="px-1 py-1 bg-[#F4F6FC] rounded-sm">
                    {btns?.map((item, index) => {
                        return (
                            <button key={index} className={`capitalize font-bold text-sm text-[#999999] px-4 py-2 lg:px-2 xl:px-4 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-white text-black' : null}`}
                                onClick={() => filterClickHandler(index, item)}
                            >
                                {localStorage?.getItem('lang') === 'ar' ? item.labelAr : item.label}
                            </button>
                        )
                    })}
                </div>
            </section>

            <section className="manage-order mt-7 w-full">
                <div className={`flex justify-between items-center w-full p-2 py-3 bg-[#e3f1e9] rounded-md ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <div className={`left-section flex capitalize items-center ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        <div className="counter">
                            <span className="px-6 py-2 bg-mainGreen rounded-md text-white font-bold mr-3">
                                <NumberCounter startNumber={0} endNumber={report?.drivers?.data?.totalOnHoldDrivers} duration={3000} />
                            </span>
                        </div>
                        <div className="left-sec-title capitalize text-[#3F4254] font-semibold text-sm">
                            {t("dashboard.restaurant_requests.new_requests")}
                        </div>
                    </div>

                    <div className="right-section text-[#442B7E] font-semibold text-sm">
                        {/* <ListItemButton className="flex items-center capitalize" onClick={showOrdersHandler}>manage riders <FaAngleRight /> </ListItemButton> */}
                        <Link to='/dashboard/couriers'><ListItemButton className="flex items-center capitalize" onClick={changeTabHandler}>{t("dashboard.riders.manage_riders")} {localStorage.getItem('lang') === 'ar' ? <FaAngleLeft /> : <FaAngleRight />} </ListItemButton></Link>

                        {/* {orderNotify ? <div className="orders-notify absolute top-[30%] left-[70%] -translate-x-1/2 z-[9999] bg-white min-w-[350px] max-h-[400px] h-[400px] overflow-hidden overflow-y-scroll p-3 shadow-md rounded-md">
                            {[...Array(10)]?.map(()=> <OrderNotification /> )}
                        </div> : null} */}
                    </div>
                </div>
            </section>

            <section className="order-status-analytics-wrapper mt-8 mb-[3px]">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    {statusAnalytics?.map(item => (
                        <div className="bg-[#F3F6F9] p-4 rounded-md" key={item.id}>
                            <p style={{ color: item.color }} className="text-2xl mb-3 font-semibold">
                                {/* {item.count} */}
                                <NumberCounter startNumber={0} endNumber={item.count} duration={3000} />
                            </p>
                            <p className="text-[#999999] text-[13px] capitalize">{item.name}</p>
                        </div>
                    ))}
                </div>
            </section>

        </article>
    );
}
export default RidersSummary;