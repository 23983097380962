import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorToast } from "../Toast-Messages/toastMessage";
import axios from "axios";
import i18next from "i18next";

export const getCourierStatistics = createAsyncThunk(
  "coruier-statistics-func",
  async ({ id }, { rejectWithValue, dispatch }) => {
    const url = `/statistics/driver-statistics${id ? `/${id}` : ""}`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCourierMetrics = createAsyncThunk(
  "coruier-metrics-func",
  async ({ dateRange, col, dir }, { rejectWithValue, dispatch }) => {
    let colCheck;

    if (col) {
      // colCheck = col === 'Created at' ? 'created_at' : col === 'Delieverd at' ? 'delivered_at' : col === 'Updated at' ? 'updated_at' : col === 'Restaurant Name' ? 'restaurant_name' : col === 'Courier Name' ? 'courier_name' : col === 'Courier Rating' ? 'courier_rating' : col === 'Courier Number' ? 'courier_mobile_number' : col === 'Customer Name' ? 'customer_name' : col === 'Customer Number' ? 'customer_mobile_number' : col === 'Customer Address' ? 'customer_address' : col === 'Order Rating' ? 'order_rating' : col === 'Branch Name' ? 'branch_name' : col === 'Branch Number' ? 'branch_number' : col === 'Branch Address' ? 'branch_address' : col === 'Min Charge' ? 'minimum_charge' : col === 'Avg Delivery Time' ? 'average_delivery_time' : col === 'Avg Rating' ? 'average_rating' : col === 'Total Orders' ? 'total_orders' : col === 'Delivery Charges' ? 'delivery_charges' : col === 'Pickup Schedule' ? 'pickup_schedule' : col === 'Vehicle Plate' ? 'vehicle_plate' : col === 'Original Price' ? 'original_price' : col === 'Final Price' ? 'final_price' : col.replace(/\s+/g, '');
      colCheck =
        col === i18next.t("couriers.reports.driverId")
          ? "driver_id"
          : col === i18next.t("couriers.reports.courierName")
          ? "courier_name"
          : col === i18next.t("couriers.reports.totalOrders")
          ? "total_orders"
          : col === i18next.t("couriers.reports.avgDriverRating")
          ? "avg_driver_rating"
          : col === i18next.t("couriers.reports.avgDeliveryTime")
          ? "avg_delivery_time"
          : col === i18next.t("couriers.reports.avgCompletionTime")
          ? "avg_completion_time"
          : col === i18next.t("couriers.reports.avgAcceptanceRate")
          ? "avg_acceptance_rate"
          : "";

      //   : (col === 'Avg Rating' || col === 'متوسط التقييم') ? 'average_rating'
      //   : (col === 'Total Orders' || col === 'اجمالى الطلبات') ? 'total_orders'
      //   : (col === 'Open Time' || col === 'وقت الفتح') ? 'opening_time'
      //   : (col === 'Close Time' || col === 'وقت الإغلاق') ? 'closing_time'
      //   : (col === 'Order Deadline Time' || col === 'وقت اخر طلب') ? 'order_deadline'
      //   : (col === 'Restaurant Phone' || col === 'هاتف المطعم') ? 'phone_number'
    }

    const url = !col
      ? `/statistics/drivers-metrics${
          dateRange ? `?dateRange=${dateRange}` : "?dateRange=last_7_days"
        }`
      : `/statistics/drivers-metrics${
          dateRange ? `?dateRange=${dateRange}` : "?dateRange=last_7_days"
        }&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  courierStatistics: [],
  courierMetrics: [],
};

export const metricsSLice = createSlice({
  name: "Metrics Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourierStatistics.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCourierStatistics.fulfilled, (state, action) => {
        state.loading = false;
        state.courierStatistics = action?.payload?.data;
        state.error = "";
      })
      .addCase(getCourierStatistics.rejected, (state, action) => {
        state.loading = false;
        state.courierStatistics = [];
        state.error = action?.payload;
      })
      .addCase(getCourierMetrics.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCourierMetrics.fulfilled, (state, action) => {
        state.loading = false;
        state.courierMetrics = action?.payload;
        state.error = "";
      })
      .addCase(getCourierMetrics.rejected, (state, action) => {
        state.loading = false;
        state.courierMetrics = [];
        state.error = action?.payload;
      });
  },
});

export default metricsSLice.reducer;
