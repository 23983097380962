import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { courierTransactions } from "../../../Components/Redux/Slice/Couriers/couriersSlice";
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import DataTable from "react-data-table-component";
import ColumnSelection from "../../../Components/Common/columnSelection";
import { TableStyles } from "../../../Components/Common/tableStyle";
import { dateOnlyFormate, UTCDateFormat } from "../../../Components/Common/dateFormate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPage, resetSearch, resetSort } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";
import { CiSearch } from "react-icons/ci";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import { DateRangePicker } from "react-date-range";
import { openHandler } from "../../../Components/Redux/Slice/common/overlaySlice";
import dayjs from "dayjs";
import { FaAngleDown } from "react-icons/fa6";
import { BsDot } from "react-icons/bs";
import { useSearchBox } from "../../../Components/Common/searchHook";


const CourierTransactions = () => {
    const [searchParams] = useSearchParams();
    let resId = searchParams.get("id");

    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const couriers = useSelector(state => state?.couriers)
    // const [searchValue, setsearchValue] = useState('')
    const [orderType, setorderType] = useState();

    const { currentPage, toggle, search, sort } = useSelector((state) => state?.resetPagination);

    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])

    // useEffect(() => {
    //     setsearchValue('')
    // }, [search])

    // const handleSearch = () => {
    //     dispatch(courierTransactions({ resId, searchValue })).then(() => dispatch(resetPage()))
    // }

    // const handleReset = () => {
    //     dispatch(courierTransactions({ resId, page, size })).then(() => dispatch(resetPage()))
    //     setsearchValue('')
    // }

    function handleModalRowClick(data, event) {
        event.stopPropagation()
        navigate('/dashboard/orders/orderdetails', { state: { id: data?.orderId } })

    }

    useEffect(() => {
        dispatch(courierTransactions({ resId }))
    }, [resId, dispatch])


    const data = couriers?.courierTransactions?.data

    const columns = [
        {
            id: 'orderId',
            name: t('couriers.transactions.orderId'),
            cell: row => <span>{row?.orderId}</span>,
            sortable: true,
        },
        {
            id: 'amount',
            name: t('couriers.transactions.amount'),
            cell: row => <span className={`${row?.outward === false ? 'text-mainGreen' : 'text-mainRed'}`}>{row?.outward === false ? row?.transactionAmount : "-" + row?.transactionAmount}</span>,
            sortable: true,
        },

        {
            id: 'createdAt',
            name: t('couriers.transactions.date'),
            cell: row => UTCDateFormat(row?.createdAt),
            sortable: true,
        },
        {
            id: 'paymentMethod',
            name: t('common.paymentMethod'),
            cell: row => <span>{row?.orderPaymentMethod}</span>,
            sortable: true,
        },
        {
            id: 'status',
            name: t('couriers.transactions.status'),
            cell: row => <span className={`bg-opacity-25 capitalize text-center font-semibold min-w-[120px] p-1 rounded-md
                ${row?.transactionStatus === 'PENDING' ? 'text-amber-400 bg-amber-400' : row?.transactionStatus === 'COMPLETED' ? 'text-mainGreen bg-mainGreen' : row?.transactionStatus === 'CANCELLED' ? 'text-mainRed bg-mainRed' : row?.transactionStatus === 'ON_HOLD' ? 'text-mainYellow bg-mainYellow' : 'text-dark bg-dark'}`}>
                {localStorage.getItem('lang') === 'ar' ? row?.transactionStatusAr : row?.transactionStatus?.toLowerCase()}
            </span>,
            sortable: true,
        },
        {
            id: 'notes',
            name: t('couriers.transactions.notes'),
            cell: row => <span >{row?.notes ? row?.notes : <span className="text-dark capitalize">{t('couriers.transactions.none')}</span>}</span>,
            sortable: false,
        },
        {
            id: 'reason',
            name: t('couriers.transactions.reason'),
            cell: row => <span className="capitalize" >{row?.transactionReason ? row?.transactionReason?.replace(/_/g, ' ').toLowerCase() : t('common.none')}</span>,
            sortable: true,
        },

        // {
        //     id: 'actions',
        //     name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
        //     allowOverflow: true,
        //     button: true,
        //     cell: row => (
        //         <>
        //             {<ActionCell data={row} t={t} />}
        //         </>
        //     )
        // },
    ];


    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['orderId', 'amount', 'createdAt', 'status', 'notes']);

    const [openColumn, setopenColumn] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = () => {
        setopenColumn(true)
    }
    const handleClose = () => {
        setopenColumn(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['orderId', 'amount', 'createdAt', 'status', 'notes']);
    }, []);

    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);




    // filter change

    const handleTypeChange = (event) => {
        let courierType = event.target.value
        // console.log(event.target);
        // settype({id:val});
        setorderType(courierType);
        dispatch(resetSearch());
        dispatch(resetSort({ sort: true }))
        dispatch(resetPage())

        // dispatch(getCouriers({page,size,courierType,searchValue}))
    };


    // date range filter
    const [selectedDate, setSelectedDate] = useState(null); // for storing the selected date from date picker
    const [date, setdate] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const handleDateRangeChange = async (e) => {
        let newPage = 1
        setpage(1)
        setsize(10)

        setdate([e.selection])
        let dateRange = e?.selection
        let fromDate = dayjs(dateRange?.startDate).utc().format('YYYY-MM-DDTHH:mm:ss')
        let toDate = dayjs(dateRange?.endDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss')
        let selectedDate = { fromDate: fromDate, toDate: toDate }

        setSelectedDate(selectedDate)
        await dispatch(courierTransactions({ page: newPage, size, resId, selectedDate })).then(() => {
            dispatch(resetSearch());
            dispatch(resetSort({ sort: true }))
            dispatch(resetPage())
        })
        // dispatch(getRatingSummary(selectedDate))
    }

    const { dateOverlay } = useSelector(state => state.overlay)

    const handleButton = () => {
        dispatch(openHandler({ dateShow: !dateOverlay }))
    }

    const placeHolder = t('placeholder.searchTransactionsPlaceholder')
    const { searchValue, searchInput } = useSearchBox(courierTransactions, placeHolder)
    const { handleRemoteSort, icon } = useRemoteSort(courierTransactions, dispatch, page, size, searchValue, orderType, selectedDate, resId)


    return (
        <>
            <Box dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                <h2 className="text-xl capitalize font-semibold text-dark">{t('couriers.courier_transactions') + " #" + resId}</h2>
                <div className={`flex justify-between mt-1 ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <p className={`text-lg capitalize font-semibold ${(parseFloat(couriers?.courierTransactions?.balance) >= 0) ? 'text-mainGreen' : 'text-mainRed'}`}>{t('common.balance')}: {couriers?.courierTransactions?.balance} {t('common.sar')}</p>

                    <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={openColumn} close={handleClose} />
                </div>

                <div className={`couriurs-filter-wrapper mt-4 flex justify-between ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center gap-x-3`}>
                    <div className="status-type-container flex items-center gap-x-2">
                        <section className="search-section-wrapper">
                            <FormControl className="capitalize" sx={{ minWidth: 175, border: 0, m: 0, borderRadius: '6px', backgroundColor: 'white', '& fieldset': { borderColor: 'primary.main', border: 'none' } }} size="small">
                                {orderType ? null : <InputLabel shrink={false} id="select-courier-type" className='text-[#333] font-semibold font-playfair capitalize text-sm'>{t('couriers.courierTransactions')}</InputLabel>}
                                <Select
                                    labelId="select-courier-type"
                                    id="demo-order-select-type"
                                    value={orderType}
                                    // label="courier type"
                                    onChange={handleTypeChange}
                                    displayEmpty
                                >
                                    <MenuItem value="all" className='text-[#333] font-semibold font-playfair' placeholder='All'>{t('common.all')}</MenuItem>
                                    <MenuItem value="COMPLETED" className='text-[#333] font-semibold font-playfair' placeholder='Completed'>{t('common.completed')}</MenuItem>
                                    <MenuItem value="PENDING" className='text-[#333] font-semibold font-playfair' placeholder='Pending'>{t('common.pending')}</MenuItem>
                                    <MenuItem value="CANCELLED" className='text-[#333] font-semibold font-playfair' placeholder='Cancelled'>{t('common.cancelled')}</MenuItem>
                                    <MenuItem value="ON_HOLD" className='text-[#333] font-semibold font-playfair' placeholder='On Hold'>{t('common.onHold')}</MenuItem>
                                </Select>
                            </FormControl>
                        </section>

                        <section className='date-wrapper relative'>
                            <Button className='bg-white text-[#333] font-semibold rounded-md py-[10px]' onClick={handleButton}>
                                {/* <button className='flex items-center text-[#333] font-semibold font-playfair capitalize text-sm'> {date[0]?.startDate && date[0]?.endDate ? new Date(fromDate.getTime() + (fromDate?.getTimezoneOffset() * -60000)).toISOString().slice(0, 10) + " - " + new Date(toDate.getTime() + (toDate?.getTimezoneOffset() * -60000)).toISOString().slice(0, 10) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></button> */}
                                <button className='flex items-center text-[#333] font-semibold font-playfair capitalize text-sm'> {date[0]?.startDate && date[0]?.endDate ? dateOnlyFormate(dayjs(date[0]?.startDate)) + " - " + dateOnlyFormate(dayjs(date[0]?.endDate)) : t('orders.date_title')} <span className='ml-1'><FaAngleDown /></span></button>
                            </Button>

                            {dateOverlay ?

                                <div className={`absolute top-full z-[9999] bg-white ${localStorage?.getItem('lang') === 'ar' ? 'left-full' : ''}`} dir='ltr'>
                                    <DateRangePicker
                                        onChange={handleDateRangeChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={date}
                                        direction="vertical"
                                        editableDateInputs={true}
                                    />
                                </div> : null}

                        </section>
                    </div>

                    <div className="search-container">
                        {searchInput()}
                    </div>

                </div>
                <section className="datatable-wrapper mt-5 bg-white relative">
                    {data?.length === 0 ?
                        <div className='p-8'><NoDataEmoji /></div>
                        :

                        <>
                            <DataTable
                                key={resId}
                                direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                                columns={visibleColumns}
                                data={data}
                                pagination
                                paginationPerPage={size}
                                paginationRowsPerPageOptions={[5, 20, 50]}
                                paginationServer
                                paginationTotalRows={couriers?.courierTransactions?.meta?.total}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleRowChange}
                                customStyles={TableStyles}
                                highlightOnHover
                                sortServer
                                sortIcon={sort ? <BsDot className="text-[1px] opacity-0" /> : icon}
                                onSort={handleRemoteSort}
                                paginationDefaultPage={page}
                                onRowClicked={(data, event) => {

                                    handleModalRowClick(data, event)
                                }}
                                keyField="courier-transactions-id"
                                paginationResetDefaultPage={true}
                                paginationComponentOptions={
                                    {
                                        rowsPerPageText: t('common.paginationRowText'),
                                        rangeSeparatorText: t('common.of')
                                    }
                                }
                            />
                        </>
                    }

                    {couriers?.loading ?
                        <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                            <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                        </div> : null}
                </section>

            </Box>
        </>
    );
}

export default CourierTransactions;